.password-back-box {
	background-color: #f5f8f9;
	position: fixed;
	width: 100%;
	height: 100vh;
	overflow: auto;
	top: 0;
	bottom: 0;
	z-index: 500;
	max-width: 1920px;
}

.password-back-box .all-content {
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 15px;
}

.password-back-box .all-content .box-form-banner {
	flex: 1;
	background: transparent
		linear-gradient(
			150deg,
			var(--unnamed-color-1dbbbe) 0%,
			var(--unnamed-color-02466a) 100%
		)
		0% 0% no-repeat padding-box;
	background: transparent linear-gradient(150deg, #1dbbbe 0%, #02466a 100%) 0%
		0% no-repeat padding-box;
	opacity: 0.95;
	height: 100%;
	/* padding-left: 40px !important; */
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	position: relative;
	z-index: 22;
	gap: 60px;
	width: 100%;
	max-width: 952px;
}

.password-back-box .all-content .box-form-banner .over-info {
	width: 100%;
	text-align: left;
	padding-left: 118px;
	margin-top: 180px;
}

.password-back-box .all-content .box-form-banner .info-svg {
	width: 100%;
	max-width: 470px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column-reverse;
	gap: 52px;
	margin-bottom: 100px;
}
.password-back-box .all-content .box-form-banner .info-svg span {
	width: 54px;
	height: 54px;
	display: flex;
	background-color: red;
	background-color: #fff;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	margin-right: 30px;
}

.password-back-box .all-content .box-form-banner .info-svg span svg {
	max-width: 100%;
}

.password-back-box .all-content .box-form-banner .info-svg h4 {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
	color: #f7fcff;
	font-size: 54px;
	font-weight: 400;
	white-space: normal !important;
}

/* ================================================== */

.password-back-box .all-content .box-form-banner h5 span svg {
	max-width: 100%;
}

.password-back-box .all-content .box-container-form {
	flex: 1;
	width: 100%;
	height: 100%;
	padding-top: 55px;
}

.password-back-box .all-content .box-container-form .all {
	/* padding-top: 50px; */
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
	width: 100%;
	width: 100%;
	max-width: 674px;
}

.password-back-box .all-content .box-container-form .all h2 {
	margin: 0;
	color: #1dbbbe;
	font-size: 28px;
	font-weight: 600;
	white-space: normal !important;
}
.password-back-box .all-content .box-container-form .all .box {
	margin: 70px 0 30px 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 91px;
	width: 100%;
	flex-direction: column;
	gap: 10px;
}
.password-back-box .all-content .box-container-form .all .box h5 {
	width: 100%;
	margin: 0;
	color: #011723;
	font-size: 18px;
	font-weight: 400;
}

.password-back-box
	.all-content
	.box-container-form
	.all
	.box
	.phone-number-wrapper {
	width: 100%;
	height: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	font-size: 16px;
	border: 1px solid #e4e4e4;
	background-color: #fff;
	color: #011723;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}
.password-back-box
	.all-content
	.box-container-form
	.all
	.box
	.phone-number-wrapper
	input {
	border: none;
	outline: none;
	box-shadow: none;
	background-color: transparent;
	width: 100%;
	font-size: 16px;
	color: #011723;
}

.password-back-box .all-content .box-container-form .all button {
	width: 100%;
	max-width: 663px;
	height: 56px;
	font-size: 20px;
	font-weight: 400;
	background-color: #1dbbbe;
	color: #f7fcff;
	margin: 0 auto;
}

.password-back-box .all-content .box-container-form .all button:disabled,
.password-back-box .all-content .box-container-form .all button:disabled:hover {
	background-color: #ddd;
	cursor: not-allowed;
}

.password-back-box .all-content .box-container-form .all button:hover {
	background-color: var(--primary-500);
}

@media (max-width: 992px) {
	.password-back-box .all-content {
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column-reverse;
		gap: 15px;
		height: 1300px;
	}
	.password-back-box .all-content .box-container-form .all {
		max-width: 100%;
	}
	.password-back-box .all-content .box-form-banner {
		height: 600px;
		flex: none;
		padding: 20px;
		padding-top: 40px;
		max-width: 100%;
	}

	.password-back-box .all-content .box-container-form {
		padding: 10px;
	}
	.password-back-box .all-content .box-form-banner .over-info {
		padding-left: 20px;
		margin-top: 60px !important;
	}
	.password-back-box .all-content .box-form-banner .info-svg span {
		margin-left: auto;
	}
}
@media (max-width: 768px) {
	.password-back-box .all-content {
		height: auto;
		padding-bottom: 46px;
	}
	.logo-header {
		height: auto;
		padding-top: 0px;
	}

	.password-back-box .all-content .box-container-form .all .box {
		margin: 0px 0 10px 0px;
		gap: 8px;
	}
	.password-back-box .all-content .box-form-banner {
		height: 400px;
		padding: 12px;
		padding-top: 18px;
	}
	.password-back-box .all-content .box-form-banner .over-info {
		margin-top: 30px !important;
	}
}

@media (min-width: 992px) {
	.password-back-box .all-content .box-form-banner,
	.password-back-box .all-content .box-container-form {
		padding: 0 40px;
	}
}

@media (min-width: 1200px) {
	.password-back-box .all-content .box-form-banner,
	.password-back-box .all-content .box-container-form {
		padding: 0 60px;
	}
}

@media (min-width: 1800px) {
	.password-back-box .all-content .box-form-banner,
	.password-back-box .all-content .box-container-form {
		padding: 0 140px;
	}
}
