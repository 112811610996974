.verificationBox-box {
	background-color: #f5f8f9;
	position: fixed;
	width: 100%;
	height: 100vh;
	overflow: auto;
	top: 0;
	bottom: 0;
	z-index: 500;
	max-width: 1920px;
}

.verificationBox-box .all-content {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 15px;
}

.verificationBox-box .all-content .box-form-banner {
	flex: 1;
	background: transparent
		linear-gradient(
			150deg,
			var(--unnamed-color-1dbbbe) 0%,
			var(--unnamed-color-02466a) 100%
		)
		0% 0% no-repeat padding-box;
	background: transparent linear-gradient(150deg, #1dbbbe 0%, #02466a 100%) 0%
		0% no-repeat padding-box;
	opacity: 0.95;
	height: inherit;
	/* padding: 65px; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 22;
	gap: 60px;
	width: 100%;
	max-width: 952px;
}

.verificationBox-box .all-content .box-form-banner .info-svg {
	width: 100%;
	max-width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column-reverse;
	gap: 37px;
	margin-bottom: 100px;
}

.verificationBox-box .all-content .box-form-banner .info-svg span {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.verificationBox-box .all-content .box-form-banner .info-svg span svg {
	width: 60px;
}

.verificationBox-box .all-content .box-form-banner .info-svg h4 {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
	color: #f7fcff;
	font-size: 54px;
	font-weight: 400;
}

/* ================================================== */

.verificationBox-box .all-content .box-form-banner h5 span svg {
	max-width: 100%;
}

.verificationBox-box .all-content .box-container-form {
	flex: 1;
	width: 100%;
	height: 100%;
	padding-top: 55px;
}

.verificationBox-box .all-content .box-container-form .all {
	padding-top: 50px;
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
	width: 100%;
	max-width: 444px;
	margin: 0 auto;
}

.verificationBox-box .all-content .box-container-form .all h2 {
	margin: 0;
	color: #1dbbbe;
	font-size: 28px;
	font-weight: 600;
}

.verificationBox-box .all-content .box-container-form .all .box {
	margin: 90px 0 37px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 27px;
	width: 100%;
}

.verificationBox-box .all-content .box-container-form .all .box div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 10px;
	direction: ltr;
}

.verificationBox-box .all-content .box-container-form .all .box input {
	width: 64px !important;
	font-size: 18px;
	font-weight: 600;
	height: 70px;
	outline: none;
	border: none;
	border-radius: 4px;
	background-color: #ffffff;
}

.verificationBox-box .all-content .box-container-form .all button {
	width: 100%;
	height: 56px;
	font-size: 20px;
	font-weight: 500;
	background-color: #1dbbbe;
	color: #f7fcff;
	margin-bottom: 20px;
}
.verificationBox-box .all-content .box-container-form .all button:disabled,
.verificationBox-box
	.all-content
	.box-container-form
	.all
	button:disabled:hover {
	background-color: #ddd;
	cursor: not-allowed;
}

.verificationBox-box .all-content .box-container-form .all h4 {
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	gap: 11px;
	margin: 0;
	color: #011723;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
}

.verificationBox-box .all-content .box-container-form .all h4 span svg {
	width: 24px;
	height: 24px;
}

@media (max-width: 992px) {
	.verificationBox-box .all-content {
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column-reverse;
		gap: 15px;
		height: 1300px;
	}

	.verificationBox-box .all-content .box-form-banner {
		height: 600px;
		flex: none;
		padding: 20px;
		padding-top: 40px;
		max-width: 100%;
	}

	.verificationBox-box .all-content .box-container-form {
		/* flex: 1;
    width: 100%;
    height: 100%; */
		padding: 10px;
	}

	.verificationBox-box .all-content .box-container-form .all .box {
		gap: 15px;
	}

	.verificationBox-box .all-content .box-container-form .all .box input {
		width: 54px !important;
		height: 60px;
	}
}

@media (min-width: 992px) {
	.verificationBox-box .all-content .box-form-banner,
	.verificationBox-box .all-content .box-container-form {
		padding: 0 40px;
	}
}

@media (min-width: 1200px) {
	.verificationBox-box .all-content .box-form-banner,
	.verificationBox-box .all-content .box-container-form {
		padding: 0 60px;
	}
}

@media (min-width: 1800px) {
	.verificationBox-box .all-content .box-form-banner,
	.verificationBox-box .all-content .box-container-form {
		padding: 0 140px;
	}
}
@media (max-width: 768px) {
	.verificationBox-box {
		height: auto;
		padding-bottom: 46px;
	}

	.verificationBox-box .all-content {
		height: auto;
	}
	.verificationBox-box .all-content .box-form-banner {
		height: 400px;

		padding-top: 0;
	}
	.verificationBox-box .all-content .box-container-form .all h2 {
		font-size: 24px;
		line-height: 26px;
		font-weight: 500;
		white-space: normal !important;
	}

	.verificationBox-box .all-content .box-container-form .all {
		padding-top: 0;
	}
	.verificationBox-box .all-content .box-container-form .all .box {
		margin: 30px 0 30px;
	}
}

@media (max-width: 390px) {
	.verificationBox-box .all-content .box-form-banner {
		height: 600px;
	}

	.verificationBox-box .all-content .box-container-form .all .box {
		gap: 10px;
	}

	.verificationBox-box .all-content .box-container-form .all .box input {
		width: 45px !important;
		height: 45px;
	}

	/* ======================= */
}
