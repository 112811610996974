.backdrop {
	background-color: #242424;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	z-index: 100;
	opacity: 0.7;
}
.alert_body {
	position: fixed;
	left: 50%;
	top: 50%;
	height: 331px;
	width: 572px;
	max-width: 90%;
	background-color: white;
	padding: 40px;
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	border-radius: 0.5rem;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
	z-index: 101;
}
.fcc {
	display: flex;
	justify-content: center;
	align-items: center;
}

.alert_title {
	letter-spacing: 0px;
	font-size: 38px;
	font-weight: 400;
	color: #ff3838;
}

.confirm_title {
	font-size: 20px;
	margin-top: 24px;
	margin-bottom: 10px;
	font-weight: bold;
	color: #011723;
}

.notificationTitle {
	font-size: 20px;
	font-weight: 400;
	text-align: center;
	color: #011723;
	white-space: pre-wrap;
}
.Warning_icon {
	width: 30px;
}

.btn_box {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-top: 64px;
}

.notifi_btn {
	width: 156px;
	height: 56px;
	padding: 0 1rem;
	font-size: 20px;
	border-radius: 0.5rem;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
}

/* media query */
@media (max-width: 768px) {
	.alert_body {
		height: 250px;
	}
	.alert_title {
		font-size: 22px;
	}
	.confirm_title {
		font-size: 18px;
		margin-top: 20px;
	}
	.Warning_icon {
		width: 22px;
	}
	.btn_box {
		margin-top: 1rem;
	}
	.notifi_btn {
		width: 135px;
		height: 45px;
	}
}
