.sign-in-box .all-content .box-container-form .all .box {
	gap: 30px !important;
	margin: 70px 0 8px 44px !important;
}

.password-field .show-password-icon g path,
ellipse {
	fill: #c7bdbd !important;
}

.sign-in-box .all-content .box-container-form .all .box h5 {
	font-size: 16px !important;
	font-weight: 400 !important;
}

.sign-in-box .all-content .box-container-form .all .top .check {
	gap: 2px !important;
}
.sign-in-box .all-content .box-container-form .all .top {
	margin-bottom: 30px !important;
}
.sign-in-box .all-content .box-container-form .all .top .check h6 {
	font-size: 16px !important;
	font-weight: 400 !important;
}

.sign-in-box .all-content .box-container-form .all .top .form-check input {
	height: 18px !important;
	border: 1px solid #e4e4e4 !important;
}
.form-check-input:checked {
	background-color: #1dbbbe;
}

.sign-in-box {
	background-color: #f5f8f9;
	position: fixed;
	width: 100%;
	height: 100vh;
	overflow: auto;
	top: 0;
	bottom: 0;
	z-index: 500;
	max-width: 1920px;
}

.sign-in-box .all-content {
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 15px;
}

.sign-in-box .all-content .box-form-banner {
	flex: 1;
	background: transparent
		linear-gradient(
			150deg,
			var(--unnamed-color-1dbbbe) 0%,
			var(--unnamed-color-02466a) 100%
		)
		0% 0% no-repeat padding-box;
	background: transparent linear-gradient(150deg, #1dbbbe 0%, #02466a 100%) 0%
		0% no-repeat padding-box;
	opacity: 0.95;
	height: 100%;
	padding-top: 65px !important;
	padding-left: 40px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;

	z-index: 22;
	gap: 60px;
	width: 100%;
	max-width: 952px;
}

.sign-in-box .all-content .box-form-banner .over-info {
	width: 100%;
	text-align: left;
	padding-left: 118px;
}

.sign-in-box .all-content .box-form-banner .info-svg {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column-reverse;
	gap: 40px;
	margin-bottom: 100px;
}

.sign-in-box .all-content .box-form-banner .info-svg h1 {
	margin: 0;
	color: #f7fcff;
	font-size: 94px;
	line-height: 94px;
	font-weight: 600;
	white-space: normal !important;
}
.sign-in-box .all-content .box-form-banner .info-svg h4 {
	margin: 0;
	color: #f7fcff;
	font-size: 54px;
	line-height: 54px;
	font-weight: 400;
	white-space: normal !important;
}
/* ================================================== */

.sign-in-box .all-content .box-form-banner h5 span svg {
	max-width: 100%;
}

.sign-in-box .all-content .box-container-form {
	flex: 1;
	width: 100%;
	height: 100%;
	padding-top: 55px;
	max-width: 960px;
}

.sign-in-box .all-content .box-container-form .all {
	padding-top: 50px;
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
	width: 100%;
	width: 100%;
}

.sign-in-box .all-content .box-container-form .all h2 {
	margin: 0;
	color: var(--second-500);
	font-size: 28px;
	white-space: normal !important;
}
.sign-in-box .all-content .box-container-form .all .box {
	margin: 70px 0 44px;
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
	gap: 30px;
	margin-bottom: 5px;
}
.sign-in-box .all-content .box-container-form .all .box div {
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
	gap: 10px;
}

.password-field .show-password-icon {
	cursor: pointer;
	left: 20px;
	position: absolute;
	top: 60px;
	z-index: 1;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.password-field .show-password-icon g path,
ellipse {
	fill: #c7bdbd;
}
.sign-in-box .all-content .box-container-form .all .box h5 {
	width: 100%;
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	color: var(--black-900);
}
.sign-in-box .all-content .box-container-form .all .box input {
	width: 100%;
	height: 56px;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 0 20px;
	font-size: 16px;
	border: 1px solid #e4e4e4 !important;
	color: var(--black-900);
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}
.sign-in-box .all-content .box-container-form .all .top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	gap: 10px;
	margin-bottom: 40px;
}
.sign-in-box .all-content .box-container-form .all .top .check {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
}
.sign-in-box .all-content .box-container-form .all .top .form-check {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sign-in-box .all-content .box-container-form .all .top .form-check input {
	width: 18px;
	height: 18px !important;
	outline: none;
	box-shadow: none;
	border: 1px solid #e4e4e4 !important;
	cursor: pointer;
}
.form-check-input:checked {
	background-color: var(--second-500);
}
.sign-in-box .all-content .box-container-form .all .top .check h6 {
	margin: 0;
	color: var(--black-900);
	font-size: 16px;
	font-weight: 400;
}
.sign-in-box .all-content .box-container-form .all .top h5 {
	margin: 0;
	color: var(--second-500);
	font-size: 18px;
	font-weight: 400;
	cursor: pointer;
}

.sign-in-box .all-content .box-container-form .all button {
	width: 100%;
	height: 56px;
	font-size: 20px;
	font-weight: 500;
	color: #f7fcff;
	margin: 0 auto;
	background-color: var(--second-500);
	margin: 20px 0;
	border-radius: 32px;
	-webkit-border-radius: 32px;
	-moz-border-radius: 32px;
	-ms-border-radius: 32px;
	-o-border-radius: 32px;
}

.sign-in-box .all-content .box-container-form .all button:hover {
	background-color: var(--primary-500);
}
.sign-in-box .all-content .box-container-form .all button:disabled,
.sign-in-box .all-content .box-container-form .all button:disabled:hover {
	background-color: #ddd;
	cursor: not-allowed;
}
.sign-in-box .all-content .box-container-form .all ul {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 11px;
}
.sign-in-box .all-content .box-container-form .all ul li {
	margin: 0;
	color: var(--black-900);
	font-size: 18px;
	font-weight: 400;
}
.sign-in-box .all-content .box-container-form .all ul li:nth-child(2) {
	color: var(--second-500);
	cursor: pointer;
}
.sign-in-box .all-content .wrong-text {
	color: #ff3b3b;
	font-size: 14px;
}

@media (max-width: 992px) {
	.sign-in-box .all-content {
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column-reverse;
		gap: 15px;
		height: 1450px;
	}

	.sign-in-box .all-content .box-form-banner {
		height: 600px;
		flex: none;
		padding: 20px;
		padding-top: 40px;
		max-width: 100%;
	}

	.sign-in-box .all-content .box-container-form {
		padding: 10px;
	}
	.sign-in-box .all-content .box-form-banner .over-info {
		padding-left: 20px;
	}
}

@media (min-width: 992px) {
	.sign-in-box .all-content .box-form-banner,
	.sign-in-box .all-content .box-container-form {
		padding: 0 40px;
	}
}

@media (min-width: 1200px) {
	.sign-in-box .all-content .box-form-banner,
	.sign-in-box .all-content .box-container-form {
		padding: 0 60px;
	}
}
@media (min-width: 1800px) {
	.sign-in-box .all-content .box-form-banner,
	.sign-in-box .all-content .box-container-form {
		padding: 0 140px;
	}
}
@media (max-width: 768px) {
	.password-field .show-password-icon {
		top: 52px;
	}
	.sign-in-box .all-content .box-form-banner {
		height: 400px;
		padding-top: 20px;
	}
	.sign-in-box .all-content .box-form-banner .info-svg h1 {
		font-size: 50px;
		line-height: 50px;
		font-weight: 600;
	}
	.sign-in-box .all-content .box-form-banner .info-svg h4 {
		font-size: 40px;
		line-height: 44px;
		font-weight: 400;
	}
	.sign-in-box .all-content .box-container-form .all h2 {
		font-size: 26px;
		font-weight: 500 !important;
	}
}
@media (max-width: 390px) {
	.sign-in-box .all-content .box-form-banner {
		padding: 10px !important;
	}
	.sign-in-box .all-content .box-container-form .all .box input {
		font-size: 15px;
	}
	/* ======================= */
}
