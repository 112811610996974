.backdrop {
	background-color: #ffffff;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	z-index: 9999;
	opacity: 0.8;
}
.background {
	position: fixed;
	left: 0;
	right: 0;
	top: 50px;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.6rem;
	z-index: 9999;
}

.background p{
	font-size: 1.3rem;
	font-weight: 500;
	margin: 0;
}

/* media query */
@media (max-width: 768px) {

}
