@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;300;400;500;600;700;800;900&family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

/*--------------- Global config ------------------*/
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	position: relative;
	font-family: "Tajawal", sans-serif !important;
	white-space: nowrap;
}

html,
body,
#root,
.app,
.content {
	font-family: "Tajawal", sans-serif;
	scroll-behavior: smooth;
}

.Toastify__toast-body {
	overflow: hidden;
}

.Toastify__toast-body > div:last-child {
	white-space: normal;
	overflow: hidden;
}

.wrign-dimensions span {
	white-space: pre-line;
}

.content > .row {
	width: 100%;
	overflow: hidden;
	margin: 0;
}

body {
	background-color: var(--white-500) !important;
	overflow-x: hidden !important;
	overflow-y: auto !important;
}

body:has(.MuiPopover-root),
body:has(.MuiBackdrop-root) {
	overflow: auto !important;
	padding-right: 0 !important;
}

.MuiList-root {
	padding-right: 0 !important;
	width: 100% !important;
}

svg:not(:root) {
	overflow: visible !important;
}

.loading-text {
	font-size: 18px;
	font-weight: 500;
	color: #108699;
}

/* Custom scroll style */
::-webkit-scrollbar {
	width: 10px;
}

/* ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px #d2f1f2;
} */

::-webkit-scrollbar-thumb {
	background-color: #d2f1f2;
	background-clip: content-box;
	border: 1px solid transparent;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #e2eeee;
}

.MuiModal-root {
	overflow: auto !important;
}

.MuiToolbar-root {
	padding-right: 16px !important;
}

.MuiTableCell-head,
.MuiTableCell-body {
	padding: 12px 16px !important;
}

.MuiMenu-paper {
	max-height: 350px !important;
}

/* ------------------------------------------------------------------------------------ */
/* Theme colors */

:root {
	/* // primary colors */
	--primary-100: #ccdae1;
	--primary-200: #9ab5c3;
	--primary-300: #6790a6;
	--primary-400: #356b88;
	--primary-500: #02466a;
	--primary-600: #023855;
	--primary-700: #012a40;
	--primary-800: #011c2a;
	--primary-900: #000e15;

	/* // second colors */
	--second-100: #d2f1f2;
	--second-200: #a5e4e5;
	--second-300: #77d6d8;
	--second-400: #4ac9cb;
	--second-500: #1dbbbe;
	--second-600: #179698;
	--second-700: #117072;
	--second-800: #0c4b4c;
	--second-900: #062526;

	/* // yellow colors */
	--yellow-100: #f0f2d6;
	--yellow-200: #e2e5ae;
	--yellow-300: #d3d885;
	--yellow-400: #c5cb5d;
	--yellow-500: #b6be34;
	--yellow-600: #92982a;
	--yellow-700: #6d721f;
	--yellow-800: #494c15;
	--yellow-900: #24260a;

	/* // blue colors */
	--blue-100: #cce4ff;
	--blue-200: #99c9ff;
	--blue-300: #66adff;
	--blue-400: #3392ff;
	--blue-500: #0077ff;
	--blue-600: #005fcc;
	--blue-700: #004799;
	--blue-800: #003066;
	--blue-900: #001833;

	/* // green colors */
	--green-100: #d8f9e3;
	--green-200: #b0f4c7;
	--green-300: #89eeac;
	--green-400: #61e990;
	--green-500: #3ae374;
	--green-600: #2eb65d;
	--green-700: #238846;
	--green-800: #175b2e;
	--green-900: #0c2d17;

	/* // orange colors */
	--orange-100: #ffecd1;
	--orange-200: #ffd9a3;
	--orange-300: #ffc576;
	--orange-400: #ffb248;
	--orange-500: #ff9f1a;
	--orange-600: #cc7f15;
	--orange-700: #995f10;
	--orange-800: #66400a;
	--orange-900: #332005;

	/* // red colors */
	--red-100: #ffd7d7;
	--red-200: #ffafaf;
	--red-300: #ff8888;
	--red-400: #ff6060;
	--red-500: #ff3838;
	--red-600: #cc2d2d;
	--red-700: #992222;
	--red-800: #661616;
	--red-900: #330b0b;

	/* indigo colors */
	--indigo-100: #dcdcdc;
	--indigo-200: #b9b9b9;
	--indigo-300: #969696;
	--indigo-400: #737373;
	--indigo-500: #505050;
	--indigo-600: #404040;
	--indigo-700: #303030;
	--indigo-800: #202020;
	--indigo-900: #101010;

	/* // gray colors */
	--gray-100: #ededed;
	--gray-200: #dcdcdc;
	--gray-300: #cacaca;
	--gray-400: #b9b9b9;
	--gray-500: #a7a7a7;
	--gray-600: #868686;
	--gray-700: #646464;
	--gray-800: #434343;
	--gray-900: #212121;

	/* // white colors */
	--white-100: #fffffff7;
	--white-200: #fefefeef;
	--white-300: #fdfdfde6;
	--white-400: #fdfdfdde;
	--white-500: #fcfcfcd6;
	--white-600: #bbbbbbde;
	--white-700: #838383e6;
	--white-800: #525252ef;
	--white-900: #272727f7;

	/* // black colors */
	--black-100: #ccd1d3;
	--black-200: #99a2a7;
	--black-300: #67747b;
	--black-400: #34454f;
	--black-500: #011723;
	--black-600: #01121c;
	--black-700: #010e15;
	--black-800: #00090e;
	--black-900: #000507;

	/*-- box shadow --*/
	--bx-shadow: 0 0 20px 0px var(--gray-100);
}

/*-------------------------------------------------------------- */

/* Reset some styles  */
.important-hint {
	font-size: 14px;
	color: #ff3838;
}

.img_icons {
	min-width: 40px;
	max-width: 40px;
	height: 40px;
	object-fit: scale-down;
	border: 1px solid #ddd;
	padding: 5px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	mix-blend-mode: multiply;
}

ul {
	list-style: none;
	padding: 0;
}

a {
	text-decoration: none !important;
}

a:active,
a:focus,
a:hover {
	text-decoration: none !important;
}

p {
	margin-bottom: 0;
}

.rounded-circle {
	border-radius: 50% !important;
	width: 40px !important;
	height: 40px !important;
}

.form-check-input.checkbox {
	width: 1.2em !important;
	height: 1.2em !important;
	border-radius: 0 !important;
	border: 2px solid var(--primary-400) !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	-o-border-radius: 0 !important;
}

.MuiFormLabel-root.Mui-focused {
	display: none !important;
}

.form-check-input.checkbox:checked[type="checkbox"] {
	background-color: var(--primary-400) !important;
}

.form-check-input.switch {
	width: 2em !important;
	background-color: var(--gray-100) !important;
}

.form-check-input.switch:checked[type="checkbox"] {
	background-color: var(--green-400) !important;
}

button svg {
	font-size: 24px;
}

select,
input,
textarea,
.form-control:is(:focus, :hover) {
	outline: none !important;
	box-shadow: none !important;
	border: none !important;
}

select:disabled,
input:disabled,
textarea:disabled,
.form-control:disabled {
	background-color: var(--gray-400);
	color: var(--black-200);
}

/* Main styles of tables */

/* Table */
.MuiToolbar-root {
	display: flex !important;
	justify-content: flex-end !important;
	align-items: center !important;
}

.MuiToolbar-root .delete-all,
.MuiToolbar-root .switch-all {
	font-size: 18px;
	padding: 5px 10px;
	font-weight: 500;
	margin-right: 50px;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
}

.MuiToolbar-root .delete-all {
	background-color: #faf2f2;
	color: red;
}

.MuiToolbar-root .delete-all svg {
	width: 30px;
	height: 25px;
}

.MuiToolbar-root .switch-all {
	background-color: #faf6f1;
	color: #ff9f1a;
	padding: 2px 10px;

	margin-right: 20px;
}

.MuiToolbar-root .switch-all .Mui-checked + .MuiSwitch-track {
	background-color: #ff9f1a !important;
}

/* Typography */
.h1 {
	font-size: 54px;
	font-weight: 900;
	letter-spacing: 4px;
}

.h2 {
	font-size: 48px;
	font-weight: 500;
}

.h3 {
	font-size: 38px;
	font-weight: 500;
}

.h4 {
	font-size: 28px;
	font-weight: 500;
}

.h5 {
	font-size: 20px;
	font-weight: 500;
}

.h6 {
	font-size: 18px;
	font-weight: 400;
}

.paragraph {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
}

.text-overflow {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.direction-ltr {
	direction: ltr;
}

textarea {
	white-space: normal !important;
}

/* --------------------------------------------------------------------------------------------- */

/* TOP BAR STYLE */
.app .top-bar {
	position: sticky;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
}

.app .top-bar.zIndex {
	z-index: 999;
}

.avatar-box {
	width: 300px;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
}

.dropdown-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.avatar-dropdown {
	cursor: pointer;
}

/* Custom scroll style */
.navbar-nav .dropdown-menu::-webkit-scrollbar {
	width: 7px;
}

.navbar-nav .dropdown-menu.user-info-dropdown {
	--bs-dropdown-bg: none !important;
	--bs-dropdown-border-width: none !important;
	position: absolute !important;
	padding: 10px;
	margin-top: 20px;
}

.dropdown-item:focus,
.dropdown-item:hover {
	background-color: transparent;
}

.navbar-nav .dropdown-menu.user-info-dropdown li {
	padding: 12px;
	border-radius: 4px;
	background-color: #1dbbbe;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navbar-nav .dropdown-menu.user-info-dropdown li:first-of-type {
	margin-bottom: 1px;
}

.navbar-nav .dropdown-menu.user-info-dropdown li:hover {
	background-color: #0bf1d1;
}

.navbar-nav .dropdown-menu.user-info-dropdown span {
	font-weight: 500;
	color: #fff;
}

.navbar-nav .dropdown-menu.user-info-dropdown svg path {
	fill: #fff;
}

.navbar-nav .dropdown-menu.user-info-dropdown li:hover span {
	color: #67747b;
}

.navbar-nav .dropdown-menu.user-info-dropdown li:hover svg path {
	fill: #67747b;
}

.avatar-dropdown .nav-link:hover {
	color: var(--white-100) !important;
}

.avatar-dropdown .nav-link span {
	font-size: 18px;
}

/* notification dropdown style */
.notification {
	cursor: pointer;
}

.notification-header {
	position: sticky;
	background: #fff;
	z-index: 1;
	top: -10px;
	left: 0;
	padding: 6px 0;
	border-bottom: 1px solid #faf9f9;
}

.navbar-nav .notification-dropdown {
	--bs-dropdown-border-width: none !important;
	border: 1px solid var(--gray-100);
	top: 130% !important;
	left: -330px;
	padding: 10px 0 0;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.navbar-nav .notification-dropdown .notifications-title {
	font-size: 18px;
	letter-spacing: 0px;
	color: #67747b;
	cursor: auto;
}

.navbar-nav .notification-dropdown .delete-notifications {
	font-size: 16px;
	letter-spacing: 0px;
	color: #67747b;
}

.navbar-nav .notification-dropdown .delete-notifications:hover {
	color: var(--second-500);
}

.navbar-nav .notification-dropdown .notification-wrapper {
	max-width: 100%;
	width: 376px;
	height: 300px;
	overflow-y: auto;
}

.navbar-nav .notification-dropdown li {
	background: #fff;
	padding: 10px 0px;
}

.navbar-nav .notification-dropdown li:hover {
	background: #e7f3f9;
}

.notification-dropdown li.un-read {
	background: #fafafa;
}

.navbar-nav .notification-dropdown li .user-name {
	display: block;
	text-align: right;
	font-size: 18px;
	letter-spacing: 0px;
	color: #011723;
	font-weight: 500;
}

.navbar-nav .notification-dropdown li .user-name.un-read {
	font-weight: 600;
}

.navbar-nav .notification-dropdown li .notification-data {
	font-size: 16px;
	letter-spacing: 0px;
	color: #67747b;
}

.navbar-nav .notification-dropdown li .notification-data.un-read {
	font-weight: 500;
	color: #011723;
}

.navbar-nav .notification-dropdown li .notification_img_style {
	width: 38px;
	height: 38px;
	padding: 3px;
	object-fit: scale-down;
	border: 1px solid #ddd;

	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.dropdown-title {
	align-self: flex-end;
}

.search-box {
	width: 500px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.search-box input {
	position: relative;
	background-color: #356b8885;
	padding: 0 70px 0 16px;
	text-align: right;
	caret-color: var(--white-100);
	color: var(--white-100);
	direction: rtl;
	width: 100%;
	height: 52px;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
}

.search-box input::placeholder {
	color: var(--white-600) !important;
	font-weight: 400;
	font-size: 1.3rem;
}

.search-box svg.search_icon {
	position: relative;
	background-color: var(--white-100);
	color: var(--primary-800);
	width: 55px;
	height: 100%;
	right: 50px;
	padding: 14px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 0px 50px 50px 50px;
	-webkit-border-radius: 0px 50px 50px 50px;
	-moz-border-radius: 0px 50px 50px 50px;
	-ms-border-radius: 0px 50px 50px 50px;
	-o-border-radius: 0px 50px 50px 50px;
}

/* ----------------------------------------------------------------------------------------- */
/* content */
.mouse-pointer {
	cursor: pointer;
}
.store_is_verified .verify_box {
	background-color: #f9c02e;
	color: #271c01;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 0.8rem;
	font-weight: 500;
	width: max-content;
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
}

.store_is_verified .verify_icon {
	margin-top: 3px;
}
/* SIDE BAR STYLE */
.sidebar-col {
	width: 260px;
}

.sidebar {
	padding: 0px;
	border-right: 0 !important;
	position: fixed !important;
	right: 1px;
	top: 82px;
	background: #f3fcfd !important;
	color: var(--primary-700);
	z-index: 99;
}

.sidebar .ps-menu-root > ul {
	margin-bottom: 120px;
}

/* reset some styles */
.ps-sidebar-container {
	background-color: transparent !important;
	width: 100%;
}

.ps-menuitem-root {
	display: flex !important;
	flex-direction: column;
}

.ps-menu-button {
	color: var(--primary-500) !important;
	width: 100% !important;
	height: 65px !important;
	padding-left: 14px !important;
	padding-right: 14px !important;
	margin-bottom: 10px;
	font-size: 1.1rem;
	font-weight: 500;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.ps-menu-button:hover {
	background-color: var(--second-500) !important;
	color: var(--white-100) !important;
}

.menu-link.active .ps-menu-button {
	background-color: var(--second-500) !important;
	color: var(--white-100) !important;
}

.ps-menu-button::after {
	position: absolute;
	content: "";
	right: 0;
	top: 0;
	width: 8px;
	height: 100%;
	border-radius: 0 10px 10px 0;
	-webkit-border-radius: 0 10px 10px 0;
	-moz-border-radius: 0 10px 10px 0;
	-ms-border-radius: 0 10px 10px 0;
	-o-border-radius: 0 10px 10px 0;
}

.menu-link.active .ps-menu-button::after,
.ps-menu-button:hover::after {
	background-color: var(--primary-500);
}

.ps-submenu-expand-icon {
	margin-left: 16px;
}

.ps-submenu-expand-icon span {
	width: 10px !important;
	height: 10px !important;
}

.ps-menu-button svg,
.ps-menu-button svg#evaluation path {
	fill: var(--primary-500);
}

/* Sub menu */
.ps-sidebar-container .sub-menu .menu-icon {
	margin-right: -10px !important;
}

.ps-sidebar-container .sub-menu .ps-menu-label {
	margin-right: -5px;
}

.ps-submenu-content {
	background-color: #cefcf6 !important;
}

.sub-menu-link .ps-menu-button {
	width: 100% !important;
	height: 65px !important;
	color: inherit;
	padding-left: 14px !important;
	padding-right: 14px !important;
	font-size: 16px;
	font-weight: 500;
}

.ps-submenu-content .ps-menu-button::after {
	display: none;
}

.sub-menu-link .ps-menu-button svg path {
	fill: var(--primary-500);
	color: var(--primary-500);
}

.sub-menu-link.active .ps-menu-button,
.sub-menu-link .ps-menu-button:hover {
	background-color: #03787a !important;
	color: var(--white-100) !important;
}

.sub-menu-link.active .ps-menu-button svg path {
	fill: #ffffff;
}

/* disabled menu link */
.sub-menu-link.disabled-menu-link .ps-menuitem-root,
.sub-menu-link.disabled-menu-link .ps-menu-button:hover,
.sub-menu-link.disabled-menu-link.active .ps-menu-button {
	background-color: var(--gray-100) !important;
	color: var(--primary-500) !important;
}

.disabled-menu-link .ps-menuitem-root .ps-menu-button {
	opacity: 0.5;
}

/* log out tab */
.logout-tab {
	border-top: 2px solid var(--primary-300);
	padding-top: 20px;
	margin-top: 120px;
}

/* ------------------------------------------------------------ */
.content .pages-content {
	width: calc(100% - 260px);
}

/* Main Content */
.main-content {
	height: 100%;
	position: relative;
	overflow-y: auto;
	overflow-x: auto;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

/* Top Section */
.page-title svg {
	color: var(--primary-500);
}

.page-title h5 {
	color: var(--black-500);
}

/* Date picker global styles */
.top-section .date-picker .rs-picker {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: end;
}

.date-picker .rs-picker-default .rs-picker-toggle.rs-btn,
.date-picker .rs-input-group.rs-input-group-inside {
	background-color: #eff9ff !important;
	color: var(--gray-100);
	border-color: var(--second-200);
	width: 450px;
	max-width: 100%;
	height: 55px;
	padding: 0 !important;
}
.date-picker .rs-input-group.rs-input-group-inside {
	flex-direction: row-reverse;
}
.date-picker .rs-input-group.rs-input-group-inside .rs-input {
	padding-right: 14px !important;
	background-color: #eff9ff !important;
}
.date-picker .rs-input-group.rs-input-group-inside .rs-input-group-addon {
	align-items: center;
	background-color: #02466a;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	width: 90px;
	fill: #fff;
	border-radius: 0px 6px 6px 0px;
}
.date-picker svg.rs-picker-caret-icon.rs-icon.rs-icon {
	fill: #fff !important;
	width: 20px !important;
	height: 20px !important;
}
.date-picker .rs-picker-default .rs-picker-toggle.rs-btn .rs-stack {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.date-picker .rs-stack-item:last-child {
	width: 90px;
	height: 100%;
	background-color: #02466a;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.date-picker .rs-stack-item:last-child svg {
	position: unset;
	font-size: 20px;
	color: #eff9ff;
	visibility: visible;
	display: flex;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean svg {
	font-size: 14px;
	fill: #02466a;
	position: absolute;
	top: 20px;
	left: -115px;
}

.date-picker .rs-picker-toggle-textbox {
	display: none;
}

.date-picker .rs-stack-item:first-child {
	text-align: initial;
	padding-right: 14px;
}

.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
	color: var(--black-500);
	font-size: 18px;
}

.date-picker .rs-picker-toggle .rs-picker-toggle-placeholder {
	color: #adb5b9;
	color: var(--gray-500);
	font-size: 18px;
}

.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
	top: 50%;
	right: 320px !important;
	transform: translateY(-50%);
}

.orders-date-picker .date-picker .rs-stack-item:last-child {
	background-color: #00ccf2;
}

.css-169iwlq-MuiCalendarPicker-root {
	width: 330px !important;
}

.rs-picker-daterange-header {
	text-align: right !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
	.rs-calendar-header-backward {
	float: right !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
	.rs-calendar-header-forward {
	float: left !important;
}

.css-1ynch81-MuiInputBase-root-MuiOutlinedInput-root {
	width: 420px !important;
	border: none !important;
	height: 60px !important;
	color: var(--black-400) !important;
	border-radius: 12px !important;
	-webkit-border-radius: 12px !important;
	-moz-border-radius: 12px !important;
	-ms-border-radius: 12px !important;
	-o-border-radius: 12px !important;
}

.css-xwp2gb-MuiFormLabel-root-MuiInputLabel-root {
	color: var(--black-200) !important;
	font-weight: 400 !important;
	right: 127px !important;
}

.css-1ynch81-MuiInputBase-root-MuiOutlinedInput-root {
	background: #eff9ff !important;
	padding: 0 100px 0 0 !important;
}

.css-1ynch81-MuiInputBase-root-MuiOutlinedInput-root:is(:hover, :focus) {
	border-color: #cfedfd !important;
}

/*-------------------------------------------------------------------------------- */

/* Details Section */

.data-box {
	background: var(--white-100);
	box-shadow: var(--bx-shadow);
	height: 130px;
	max-width: 100%;
	padding: 14px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: auto;
	white-space: nowrap;
}

.data-box > .row .col-5 {
	width: 33.666667% !important;
}

.data-box > .row .col-7 {
	width: 65.333333% !important;
}

.data-box .icon {
	width: 60px;
	height: 60px;
	padding: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.data-box .icon svg {
	fill: var(--white-100);
	width: 35px;
	height: 35px;
}

.data-box .data {
	width: 180px;
	max-width: 100%;
}

.data-box .data h4 {
	font-size: 26px;
	font-weight: 500;
	color: var(--blue-900);
}

.data-box .data p {
	font-size: 20px;
	font-weight: 400;
	color: var(--gray-700);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 99%;
}

/*-------------------------------------------------------------------------------- */

/* charts */

.line-charts,
.pie-charts,
.new-orders-table,
.new-products-table {
	background: var(--white-100);
	box-shadow: var(--bx-shadow);
	border-radius: 12px;
	padding: 20px;
	height: 400px;
}

.line-charts,
.pie-charts {
	height: 450px;
}

.charts .comp-title h4 {
	color: var(--black-600);
	font-size: 26px;
	font-weight: 500;
}

.line-charts-tabs {
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
}

.line-charts-tabs .nav-item {
	width: 33.33%;
}

.line-charts-tabs .nav-item:nth-child(2) {
	border-left: 1px solid var(--second-200);
	border-right: 1px solid var(--second-200);
}

.nav-pills .nav-link {
	background-color: #e6fefa;
	color: var(--gray-700);
	font-size: 19px;
	font-weight: 400;
	width: 100%;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.right-radius {
	border-radius: 0 8px 8px 0 !important;
	-webkit-border-radius: 0 8px 8px 0 !important;
	-moz-border-radius: 0 8px 8px 0 !important;
	-ms-border-radius: 0 8px 8px 0 !important;
	-o-border-radius: 0 8px 8px 0 !important;
}

.radius-left {
	border-radius: 8px 0 0 8px !important;
	-webkit-border-radius: 8px 0 0 8px !important;
	-moz-border-radius: 8px 0 0 8px !important;
	-ms-border-radius: 8px 0 0 8px !important;
	-o-border-radius: 8px 0 0 8px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #cefcf6;
	color: var(--gray-800);
	font-weight: 500;
}

.line-chart,
.line-chart-tab-content {
	width: 100%;
	height: 100px;
}

.line-chart-tab-pane {
	height: 100%;
	bottom: -240px;
}

/*-------------------------------------------------------------------------------- */
/* Pie-charts */
.analyse-box {
	white-space: nowrap;
}

.analyse-box h5 {
	color: var(--gray-500);
	font-size: 18px;
	font-weight: 400;
	height: 48px;
	white-space: normal !important;
}

.analyse-box p {
	font-weight: 500;
	font-size: 24px;
}

.analyse-box p .currency {
	font-size: 18px;
}

.analyse-box svg {
	font-size: 26px;
	stroke-width: 0.3;
	color: var(--green-500);
}

.analyse-box svg.red {
	color: var(--red-500);
}

/*-------------------------------------------------------------------------------- */
/* Tables  SECTION */
/*-------------------------------------------------------------------------------- */
.new-products-table,
.new-orders-table {
	background-color: var(--white-100);
	padding: 20px 20px 0px 20px;
	height: 100%;
}

.arrow-style {
	stroke-width: 0.3;
	color: var(--blue-600);
	font-size: 26px;
	cursor: pointer;
}

thead {
	text-align: center;
}

table,
.table > thead,
.table > tbody {
	vertical-align: middle !important;
	font-size: 17px;
	color: var(--gray-800);
}

.pr-rad-right {
	border-radius: 0 10px 0 0;
	-webkit-border-radius: 0 10px 0 0;
	-moz-border-radius: 0 10px 0 0;
	-ms-border-radius: 0 10px 0 0;
	-o-border-radius: 0 10px 0 0;
}

.pr-rad-left {
	border-radius: 10px 0 0 0;
	-webkit-border-radius: 10px 0 0 0;
	-moz-border-radius: 10px 0 0 0;
	-ms-border-radius: 10px 0 0 0;
	-o-border-radius: 10px 0 0 0;
}

.table > tbody {
	font-weight: 500;
}

.orders-table thead,
.products-table thead {
	background-color: #d2f1f270;
	height: 60px;
}

.table > tbody .status {
	width: 210px;
	max-width: 210px;
	padding: 2px 0;
	font-size: 15px;
	font-weight: 500;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
	border-radius: 16px;
}

.action-icon svg {
	cursor: pointer;
	font-size: 20px;
}

.new-orders-dropdown-menu {
	--bs-dropdown-min-width: none !important;
	border: none;
	padding: 0;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.new-orders-dropdown-menu li,
.new-orders-dropdown-menu li .dropdown-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.new-orders-dropdown-menu li {
	background-color: var(--second-100);
	padding: 10px;
}

.new-orders-dropdown-menu li.delete:hover {
	background-color: var(--red-100);
}

.new-orders-dropdown-menu li:hover {
	background-color: var(--gray-100);
}

.new-orders-dropdown-menu li .report-icon path {
	fill: var(--black-300);
}

/* */

/*-------------------------------------------------------------------------------- */
/* category */
.category,
.products,
.orders-pages,
.offers-page,
.carts-page,
.seo-page,
.marketing-page,
.rating-page,
.pages-page,
.academy-page,
.social-page,
.customer-page,
.main-info-page,
.management-page,
.reports-page,
.coupon-page,
.create-offers-page,
.PlatformServices-page,
.delegate-page {
	width: 95%;
}

.head-category .breadcrumb {
	font-size: 19px;
	font-weight: 400;
}

.breadcrumb-item a {
	color: var(--primary-600);
}

.breadcrumb-item + .breadcrumb-item::before {
	float: right;
	padding-left: var(--bs-breadcrumb-item-padding-x);
	color: var(--bs-breadcrumb-divider-color);
	content: var(--bs-breadcrumb-divider, "/");
}

.delete-category-alert {
	font-size: 22px;
	white-space: normal !important;
	padding: 10px 10px 20px 10px;
}

/* add category form */
.add-category {
	background: #ededed38;
	padding: 28px 16px;
	width: 99%;
	margin: 0 auto;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.input-group {
	align-items: flex-end !important;
	justify-content: space-between !important;
}

.input-box {
	width: 38.33%;
}

.input-box input {
	position: relative;
	font-size: 20px;
	padding: 0 35px;
}

.input-box svg {
	position: absolute;
	right: 10px;
	top: 20px;
	color: var(--gray-300);
	z-index: 1;
}

.input-box input,
select {
	width: 100%;
	height: 60px;
	border-radius: 6px;
	background: #ededed;
	border: 1px solid var(--gray-300) !important;
}

.input-box input {
	border: none !important;
}

.input-box input::placeholder {
	color: var(--gray-300);
}

.input-box select {
	background-color: #ededed38 !important;
	cursor: pointer;
}

.input-box select option {
	cursor: pointer;
}

.form-select {
	padding: 0.375rem 1.25rem 0.375rem 0.75rem !important;
	background-position: left 0.75rem center !important;
	background-size: 20px 18px !important;
	font-size: 20px !important;
	cursor: pointer;
}

.add-category-bt-box {
	width: 22%;
}

.add-category-bt-box button {
	background-color: var(--primary-500);
	color: var(--white-100);
	width: 100%;
	font-size: 20px;
	max-width: 100%;
	height: 60px;
	border: 0;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.add-category-bt-box button svg {
	color: var(--white-100);
	width: 22px;
	height: 22px;
}

.category .filters-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	margin-bottom: 1rem;
	background-color: #ededed38;
	padding: 1rem;
}

.category .filters-btn .btn {
	min-width: 200px;
	height: 48px;
	background-color: var(--second-500);
	font-size: 1rem;
	font-weight: 500;
	border-radius: 6px;
	border: none;
	outline: none;
	color: var(--white-100);
	transition: all 0.3s ease-in-out;
}

.category .filters-btn .btn.active {
	background-color: var(--primary-500);
}

/* Category table */

.check-all .form-check {
	padding-left: 48px !important;
	margin-bottom: 20px;
}

.form-check label {
	font-size: 20px;
	font-weight: 500;
	color: var(--primary-500);
}

.table-head {
	width: 180px;
	max-width: 100%;
}

.category-table thead tr {
	background: var(--gray-100);
	height: 60px;
	color: var(--black-400);
	font-size: 18px;
}

.category-table table {
	box-shadow: 0px 0px 3px 0px #41464b42;
	/* outline: 1px solid var(--gray-300); */
}

.category-table thead tr th {
	color: var(--gray-700);
	font-weight: 500;
	font-size: 20px;
}

.category-table tbody,
.category-table tr {
	border: 1px solid var(--gray-200);
}

.form-switch {
	padding-left: 0 !important;
	width: min-content !important;
}

.sub-categories div {
	min-width: 100px;
	max-width: 160px;
	background-color: var(--gray-200);
	padding: 5px 8px;
	border-radius: 20px;
	font-size: 16px;
	font-weight: 500;
	color: var(--black-500);
	margin: 0 2px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.support-table .sub-categories span {
	background-color: var(--gray-200);
	padding: 5px 10px;
	width: 135px;
	font-size: 16px;
	font-weight: 500;
	color: var(--black-500);
	margin: 0 10px;
	display: inline-flex;
	justify-content: center;
	border-radius: 20px;
}

.support-table .support-title {
	max-width: 250px;
}

.actions img {
	cursor: pointer;
}

/* Page navigation  */
nav.navigation {
	width: max-content;
	margin: 0 auto;
}

.page-item .page-link {
	border: none !important;
	background-color: transparent;
	color: var(--black-400);
	font-size: 18px;
}

.page-item .page-link.active {
	background-color: #237eae;
	color: #fff;
	border-radius: 12px;
	height: 37px;
}

.page-item .page-link:is(:focus, :hover) {
	box-shadow: none !important;
}

/* select-table-row-wrapper */
.select-table-row-wrapper {
	border: 2px solid #237eae !important;
	padding: 0px 16px;
	gap: 8px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.select-table-row-wrapper label {
	color: #237eae;
	font-size: 22px;
	font-weight: 400;
}

.select-table-row-wrapper .table-row-select {
	width: 45px;
}

.select-table-row-wrapper .table-row-select .MuiOutlinedInput-notchedOutline {
	border: none !important;
}

.select-table-row-wrapper .table-row-select .MuiSelect-icon {
	background-color: #c6e1f0;
	color: var(--blue-500) !important;
	position: absolute;
	width: 45px;
	height: 45px;
	padding: 10px;
	right: 60% !important;
	top: calc(30% - 0.5em);
	pointer-events: none;
	border-radius: 6px;
	transform: translate(50%, 0%) !important;
	-webkit-transform: translate(50%, 0%) !important;
	-moz-transform: translate(50%, 0%) !important;
	-ms-transform: translate(50%, 0%) !important;
	-o-transform: translate(50%, 0%) !important;
}

.select-table-row-wrapper .table-row-select .MuiSelect-icon:hover {
	background-color: #0099fb;
	color: #fff !important;
}

/* ------------------------------------------------------------------------------------ */
/* products page */
.products .input-group .search-input,
.export-upload-btn-group {
	width: 30%;
}

.products .input-group .select-input .MuiSelect-icon {
	right: 94% !important;
}

.products .input-group .select-input,
.drop-files {
	width: 41%;
	margin-left: 30px !important;
}

.products .mange-file {
	width: 99%;
	margin: 0 auto;
	padding: 25px 16px;
}

.products .mange-file button,
.upload-files label {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	height: 60px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.products .export-files {
	width: 50%;
	margin-left: 26px;
}

.products .mange-file .export-btn {
	background-color: #03787a;
	width: 100%;
	color: var(--white-300);
}

.products .filters-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
	margin-bottom: 1rem;
	background-color: #ededed38;
	padding: 1rem;
}

.products .filters-btn .btn {
	min-width: 200px;
	height: 48px;
	background-color: var(--second-500);
	font-size: 1rem;
	font-weight: 500;
	border-radius: 6px;
	border: none;
	outline: none;
	color: var(--white-100);
	transition: all 0.3s ease-in-out;
}

.products .filters-btn .btn.active {
	background-color: var(--primary-500);
}

.upload-files {
	width: 50%;
	margin-left: 6px;
}

.upload-files .upload-files-input {
	display: none;
}

.upload-files label {
	background-color: #d3d3d3;
	width: 100%;
	cursor: pointer;
}

.drop-files {
	height: 55px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--gray-600) !important;
	border: 2px dashed var(--black-300) !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	cursor: pointer;
}

.add-new-product {
	width: 22%;
}

.add-new-product .add-new-product-btn {
	background-color: #1dbbbe;
	color: var(--white-300);
}

.drop-files p {
	color: var(--black-200);
	font-weight: 500;
	margin-top: 10px;
}

/* Product option */
.product-options .close-icon-video-modal {
	left: 14px;
}

.option-info-label,
.product-options .option-info-label,
.change-categories-modal-content .option-info-label {
	background-color: rgba(81, 150, 243, 0.1);
	padding: 10px 15px;
	border: 1px solid rgba(81, 150, 243, 0.2);
	border-radius: 8px;
	color: #0b4aa0;
}

.dns_info_label {
	text-align: left;
	background-color: transparent;
}

.dns_info_label__info {
	color: var(--bs-blue);
	font-size: 15px;
	font-weight: 500;
	white-space: normal !important;
}

.option-info-label span,
.product-options .option-info-label span,
.change-categories-modal-content .option-info-label span {
	white-space: normal !important;
}

.option-info-label span,
.product-options .option-info-label span {
	font-size: 14px;
	font-weight: 500;
}

.option-info-label svg,
.product-options .option-info-label svg {
	font-size: 18px;
}

.product-options .active-options-switch .Mui-checked + .MuiSwitch-track {
	background-color: #76e8cd !important;
	border-color: #76e8cd !important;
}

.product-options .active-options-switch .switch-label {
	height: 18px;
	font-size: 18px;
	font-weight: 500;
	margin-right: 0px;
}

.product-options .options-section {
	position: relative;
	background: #fafafa;
	border: 1px solid #f2f5f7;
	margin-bottom: 20px;
	padding: 25px 20px 20px;
	border-radius: 4px;
}

.product-options .option-name-input {
	width: 100%;
	height: 48px;
	padding: 10px 12px 10px 0;
	background-color: #fff;
	border: 1px solid #eee;
	border-radius: 4px;
	overflow: hidden;
}

.product-options .option-select-input {
	width: 100%;
	height: 48px;
	padding: 10px 12px 10px 0;
	background-color: #fff;
	border: 1px solid #eee;
	border-radius: 4px;
	overflow: hidden;
}

.option-color-input {
	position: relative;
	width: 100%;
	height: 48px;
	padding: 10px 12px 10px 0;
	background-color: #fff;
	border: 1px solid #eee;
	border-radius: 4px;
}

.product-options .option-name-input input,
.product-options .option-color-input input {
	all: unset;
	width: 100%;
	height: 100%;
	background-color: transparent;
	color: #333;
	font-size: 16px;
}

.product-options .add-product-image {
	color: #3c3f43;
	font-size: 16px;
	background-color: transparent;
	padding: 0 10px;
}

.product-options-img-wrapper {
	border: 1px solid #f4f4f4;
	margin-left: 3px;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}

.product-options-img-wrapper .item-previewImage {
	height: 38px;
	padding: 2px 10px;
	display: inline-flex;
}

.prev-img-icon {
	color: #333;
	cursor: pointer;
	font-size: 16px !important;
}

.product-options .add-product-image svg,
.product-options .input-icon svg {
	color: #cdcdcd;
}

.product-options .option-name-input input::placeholder,
.product-options .option-color-input input::placeholder {
	color: #4d4d4d;
}

.imageprev-modal {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: #01172342;
}

.option-color-input .MuiToggleButton-root {
	padding: 2px;
	border-radius: 50%;
}

.option-color-input .MuiToggleButton-root.Mui-selected {
	color: #ffffff;
	background-color: var(--second-500);
}

.option-color-input .MuiToggleButton-root.Mui-selected:hover {
	background-color: #03acaf;
}

.option-color-input .MuiToggleButton-root svg {
	width: 0.5em;
	height: 0.5em;
}

/* ProductImageModal-content */
.ProductImageModal-content {
	position: absolute;
	z-index: 9999;
	background-color: #fff;
	box-shadow: 1px 1px 1px 1px #ddd;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	padding: 10px;
	left: 22%;
	top: 170px;
}

.ProductImageModal-content .close_video_icon {
	cursor: pointer;
	font-size: 18px;
}

.ProductImageModal-content .product-option-img-wrap img {
	max-width: 200px;
	height: auto;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.product-options .option-name-input .input-type,
.product-options .option-color-input .input-type {
	border-right: 1px solid #ddd;
	background-color: rgba(0, 0, 0, 0.06);
	color: #333;
	font-size: 14px;
	height: 45px;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ser-img-title {
	font-size: 16px;
	font-weight: 400;
	color: #1dbbbe;
}

.product-options .options-section .add-new-value-btn {
	background: transparent;
	border: 1px dashed #1dbbbe;
	color: #1dbbbe;
	display: block;
	margin: 0 auto;
	height: 48px;
	padding: 10px 12px;
	font-size: 18px;
	font-weight: 400;
	border-radius: 4px;
}

.product-options .delete-icon {
	cursor: pointer;
}

.product-options .delete-option-section-icon {
	background-color: red;
	border-radius: 4px;
	height: 42px;
	padding: 8px;
	display: flex;
	place-items: center;
}

.product-options .delete-option-section-icon svg path {
	fill: #fff;
}

.product-options label.MuiFormControlLabel-root {
	margin: 0 !important;
}

.product-options span.MuiCheckbox-root:hover {
	background: none;
}

.product-options span.MuiTypography-root {
	font-size: 18px;
	font-weight: 500;
	margin-right: 0px;
}

.product-options .total-quantity {
	font-size: 18px;
	font-weight: 500;
}

.product-options .add-new-option-section-btn {
	background: #fafafa;

	border: 1px dashed #023855;
	color: #023855;
	display: block;
	margin: 0 auto;
	height: 48px;
	padding: 10px 12px;
	font-size: 18px;
	font-weight: 400;
	border-radius: 4px;
}

.product-options button .add-icon {
	font-size: 16px;
	margin-left: 8px;
}

.product-options .border {
	width: 97.5%;
	margin: 0 auto;
	border: 1px solid #ddd;
}

/* product table and change categories modal  */
.MuiToolbar-root .edit-all-categories-btn {
	background-color: #faf6f1;
	color: #ff9f1a;
	padding: 2px 16px;
	margin-right: 20px;
	border-radius: 20px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
}

.change-categories-modal-content {
	background-color: #fff;
	border-radius: 16px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
}

.change-categories-modal-content label {
	color: var(--primary-500);
}

.change-categories-modal-content .save-change-btn {
	background-color: #1dbbbe;
	color: #fff;

	height: 56px;
	padding: 10px 12px;
	font-size: 20px;
	font-weight: 400;
	border-radius: 4px;
}

.change-categories-modal-content .close_video_icon {
	cursor: pointer;
}

/* ------------------------------------------------------------------------------------ */
/* Orders page */

.filter-wrapper {
	margin-bottom: 0 !important;
	width: 100%;
	margin: auto;
}

.title-col {
	width: 50%;
}

.filter-btn-col {
	width: 20%;
	margin-right: 20px;
	display: flex;
	justify-content: flex-end;
}

.filter-wrapper .filter-select {
	background: #00ccf2 !important;
	color: var(--white-100);
	height: 55px;
	text-align: center;
	cursor: pointer;
	border: none !important;
	border-radius: 24px !important;
	-webkit-border-radius: 24px !important;
	-moz-border-radius: 24px !important;
	-ms-border-radius: 24px !important;
	-o-border-radius: 24px !important;
}

.filter-wrapper .filter-select option {
	background-color: #ffffff;
	color: var(--black-500);
	margin: 10px;
}

/* ------------------------------------------------------------------------------------ */
/* Coupon page */
.coupon-form .add-category,
.pages-page .add-category {
	background-color: #d9f2f9;
}

.coupon-form .add-category .search-input {
	width: 50%;
}

.coupon-form .add-category .select-input {
	width: 25%;
}

.coupon-form .add-category .input-box input {
	background-color: #f3fcfd;
}

.coupon-form .add-category .input-box input:is(:hover, :focus) {
	border-color: #f3fcfd !important;
}

.coupon-form .add-category select {
	border-color: var(--primary-300) !important;
}

.coupon-form .add-category label {
	display: none;
}

/* ------------------------------------------------------------------------------------ */

/* Offers pAGE */

.add-offer-btn {
	background-color: var(--primary-500);
	color: var(--white-100);
	border: 0;
	height: 60px;
	width: 200px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.add-offer-btn svg {
	margin-left: 13px;
	font-size: 20px;
	stroke-width: 27;
}

.offers-table {
	width: 100%;
}

.offers-table table thead {
	height: 75px;
	background-color: var(--gray-100);
}

.offers-table table thead tr th {
	border-radius: 10px 10px 0 0;
	-webkit-border-radius: 10px 10px 0 0;
	-moz-border-radius: 10px 10px 0 0;
	-ms-border-radius: 10px 10px 0 0;
	-o-border-radius: 10px 10px 0 0;
	padding: 0 20px 0 0;
}

.padding {
	height: 20px;
	background-color: var(--white-300);
}

.offers-table tbody tr,
.offers-table thead th {
	--bx-shadow: 0 0 14px 0px var(--gray-100);
	border: 1px solid var(--gray-100);
}

.tb-offer-title svg {
	width: 25px;
	height: 26px;
	color: var(--primary-600);
}

.tb-offer-title h4 {
	font-size: 24px;
	font-weight: 500;
	color: var(--black-700);
}

.tb-offer-title p {
	font-size: 18px;
	font-weight: 500;
	color: var(--black-300);
	margin-right: -5px;
}

.offer-content {
	padding: 28px 20px 28px 0;
	gap: 1.5rem;
}

.offer-heading {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 14px;
	color: var(--second-500);
}

.offer-info {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 8px;
	color: var(--gray-600);
}

.offer-calender {
	font-weight: 400;

	display: flex;
	align-items: center;
}

.offer-calender span {
	font-size: 18px;
	margin-right: 10px;
	color: var(--gray-400);
}

.offer-calender svg {
	color: var(--gray-500);
	width: 24px;
	height: 24px;
	margin-right: -4px;
}

.toggle-offer-switch .css-jsexje-MuiSwitch-thumb {
	background-color: var(--white-100) !important;
}

.toggle-offer-switch
	.css-8jgyx3-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
	color: var(--white-100) !important;
	opacity: 1 !important;
}

.Mui-checked + .MuiSwitch-track {
	background: #3ae374 !important;
	opacity: 1 !important;
}

/* ------------------------------------------------------------------------------------------------- */
/* Carts Page */

.carts-page .css-1mxz8qt-MuiPaper-root {
	background-color: var(--white-500) !important;
}

.carts-page .MuiFormControl-root {
	width: 490px !important;
	height: 60px;
	background: var(--gray-100) !important;
	border: 1px solid var(--white--400) !important;
	vertical-align: baseline !important;
	width: 550px !important;
	background: var(--gray-100) !important;
	border: 1px solid var(--white--400) !important;
	vertical-align: baseline !important;
	border-radius: 6px !important;
	-webkit-border-radius: 6px !important;
	-moz-border-radius: 6px !important;
	-ms-border-radius: 6px !important;
	-o-border-radius: 6px !important;
}

.carts-page .MuiToolbar-root,
.carts-page .MuiToolbar-root {
	padding: 20px 10px 20px 10px !important;
}

.carts-page .search-input-box {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 16px;
	flex: 0.5;
	margin: 0 auto 0 0;
	gap: 8px;
	width: 100%;
	height: 56px;
	border: 1px solid var(--bs-white--100);
	background-color: #f2f2f2;
	border-radius: 5px;
}

.carts-page .search-input-box input {
	width: 100%;
	height: 100%;
	background-color: transparent;
	font-size: 18px;
}

.carts-page .search-input-box svg {
	color: var(--black-100);
}

/* -------------------------------------------------------------------------------------------------- */
/* Seo Page */

.seo-weight-edit-box {
	padding: 0;
	border-radius: 10px 10px 0 0;
	box-shadow: var(--bx-shadow);
}

.seo-weight-edit-box .input-bx {
	width: 60%;
	margin: 0 auto;
}

/* Seo key words  input style  */
.rti--container {
	height: 200px !important;
	align-items: flex-start !important;
	padding-top: 10px !important;
	padding-right: 42px !important;
	--rti-bg: var(--white-100) !important;
	--rti-border: var(--gray-100) !important;
	--rti-radius: "4px" !important;
	font-size: 20px !important;
	overflow-y: auto;
}

.rti--container input::placeholder {
	color: var(--gray-500);
}

.rti--tag {
	background: var(--white-500) !important;
}

.key-word-icons {
	position: absolute;
	top: 10px;
	right: 8px;
}

.seo-weight-edit-box .title {
	display: flex;
	flex-direction: row;
	gap: 10px;
	flex-wrap: wrap;
	background-color: var(--gray-100);
	padding: 20px 30px 20px 0;
	border-radius: inherit;
}

.seo-weight-edit-box .title h4 {
	white-space: nowrap;
}

.seo-weight-edit-box .title .sub_title {
	font-size: 16px;
	color: #67747b;
	font-weight: 400;
	line-height: 34px;
	white-space: break-spaces;
}

.seo-weight-edit-box .MuiFormControl-root {
	background: var(--white-100) !important;
	width: 100% !important;
}

.seo-weight-edit-box .input-bx.banners-box {
	width: 650px;
	max-width: 100%;
	margin: 0px 100px;
}

.seo-weight-edit-box .input-bx.banners-box.first-one {
	margin: 0px 90px;
}

.seo-weight-edit-box .input-bx.logo-box {
	width: 500px;
	max-width: 100%;
	margin: 0px auto;
}

.seo-weight-edit-box .input-bx #outlined-textarea {
	text-align: left;
	direction: ltr !important;
}

.seo-weight-edit-box .input-bx #outlined-textarea::placeholder {
	text-align: right;
}

.seo-weight-edit-box .MuiFormControl-root {
	width: 100% !important;
}

.seo-weight-edit-box .MuiOutlinedInput-notchedOutline {
	border: 1px solid var(--gray-100) !important;
}

.seo-weight-edit-box .MuiInputBase-multiline {
	height: 200px !important;
	align-items: flex-start !important;
	padding-right: 0 !important;
}

.seo-weight-edit-box .MuiInputBase-multiline:focus {
	outline: 0;
	padding-right: 16px !important;
}

.seo-weight-edit-box .MuiInputAdornment-root {
	height: 1.2em !important;
}

.seo-weight-edit-box .MuiInputAdornment-root svg path,
.key-word-icons {
	fill: var(--gray-500) !important;
}

.seo-weight-edit-box .css-a5ci81-MuiFormLabel-root {
	color: var(--black-700) !important;
	font-size: 24px !important;
	font-weight: 500 !important;
	margin-bottom: 10px !important;
}

.seo-weight-edit-box .MuiFormControlLabel-root {
	margin-right: 0 !important;
}

.seo-weight-edit-box .seo-form-check-label {
	font-weight: 500;
	font-size: 20px;
}

.seo-weight-edit-box .seo-form-check-label .link {
	font-size: 18px;
	font-weight: 400;
	color: var(--black-200);
}

.seo-weight-edit-box .MuiButtonBase-root.MuiRadio-root.Mui-checked {
	color: var(--second-400);
}

.seo-weight-edit-box .css-qvhpmv-MuiSvgIcon-root {
	width: 22px !important;
	height: 22px !important;
}

.seo-weight-edit-box .css-hqq6b3-MuiSvgIcon-root {
	width: 22px !important;
	height: 22px !important;
}

.seo-weight-edit-box .btn-bx {
	width: 200px;
	margin: 0 auto;
}

.seo-weight-edit-box button.MuiButtonBase-root {
	background-color: var(--second-500) !important;
	font-size: 1.4rem !important;
	min-width: 190px !important;
	padding: 12px 10px !important;
}

/* Save button */
.seo-weight-edit-box button.MuiButtonBase-root {
	background-color: var(--second-500) !important;
	font-size: 1.4rem !important;
	min-width: 190px !important;
	padding: 12px 10px !important;
}

/* ------------------------------------------------------------------------------------------- */
/* marketing page and Video Modal   */
.video-modal {
	width: 100%;
	height: 100%;
	cursor: pointer;
	border-radius: inherit;
}

.close-icon-video-modal {
	position: absolute;
	left: 6px;
	z-index: 1;
}

.modal-video-btn {
	background: var(--second-600) !important;
	left: 50%;
	top: 0;
	transform: translate(50%, 50px) !important;
	-webkit-transform: translate(50%, 50px) !important;
	-moz-transform: translate(50%, 50px) !important;
	-o-transform: translate(50%, 50px) !important;
	-ms-transform: translate(50%, 50px) !important;
	font-weight: 300 !important;
	font-size: 1.3rem !important;
	box-shadow: none !important;
}

/* ----------------------------------------------------------------------------------------------- */
/* style of filter-rating */

.rating-filter-box .drop-down {
	width: 200px;
	display: flex;
	justify-content: center;
}

.rating-filter-box .drop-down > div {
	width: 100%;
	display: flex;
	justify-content: center;
}

.rating-filter-box .drop-down > div > button {
	width: 100%;
	font-size: 20px;
	font-weight: 400;
	color: var(--black-500);
}

.rating-filter-box .drop-down > div > button svg {
	width: 22px;
	height: 22px;
}

.rating-filter-box
	.css-j204z7-MuiFormControlLabel-root
	.MuiFormControlLabel-label {
	font-weight: 500;
}

.rating-page .rating-widget {
	height: 180px;
}

.rating-page .rating-widget,
.academy-widget {
	width: 1100px;
	max-width: 100%;
	background: #ffffff;
	border: 1px solid #e7e7e7;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.rating-page .user-info {
	background-color: #237eaecc;
	padding: 20px;
	max-width: 100%;
	width: 249px;
	height: 180px;
	border-radius: 0px 8px 8px 0px;
	-webkit-border-radius: 0px 8px 8px 0px;
	-moz-border-radius: 0px 8px 8px 0px;
	-ms-border-radius: 0px 8px 8px 0px;
	-o-border-radius: 0px 8px 8px 0px;
}

.rating-page .user-info .user-name {
	font-size: 18px;
	font-weight: 400;
	color: #eff9ff;
}

.rating-page .user-info .text {
	font-size: 16px;
	font-weight: 400;
	width: 100px;
}

.rating-page .user-info svg {
	color: var(--white-100);
	width: 18px;
	height: 18px;
}

.rating-page .user-info svg.store path {
	fill: var(--white-100);
}

.rating-page .user-info .user-name,
.rating-page .user-info .text {
	color: var(--white-100);
}

.ratin-bx {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.start svg path {
	fill: var(--bs-yellow);
}

.rating-page .product-name span {
	font-size: 16px;
}

.rating-page .product-name-title {
	font-size: 18px;
	font-weight: 500;
}

.ratin-bx h6 {
	font-size: 18px;
	font-weight: 500;
}

.rating-page .pagination-box {
	width: 1100px;
	max-width: 100%;
}

/* Publish btn */
.rate-act-btn .publish-btn {
	width: 100%;
}

.rate-act-btn .publish-btn,
.rate-act-btn .publish-btn:hover {
	background-color: var(--white--100) !important;
	border: 1.5px solid var(--green-500);
	color: var(--green-500);
}

.rate-act-btn .publish-btn svg {
	font-size: 20px;
	stroke-width: 2;
}

.rate-act-btn .publish-btn svg polygon {
	fill: var(--green-500);
}

/* Delete btn */
.rate-act-btn .delete-btn,
.rate-act-btn .delete-btn:hover {
	background-color: var(--white--100) !important;
	border: 1.5px solid var(--red-400);
	color: var(--red-400);
}

/* Replay btn */
.rate-act-btn .margin-right-lg {
	margin-right: -14px;
}

.rate-act-btn .replay-btn {
	border: 1.5px solid var(--black-500);
	color: var(--black-500);
}

.rate-act-btn .replay-btn:hover {
	background-color: var(--white--100) !important;
}

/* Order number */
.order-rate-number {
	padding: 30px;
	color: var(--black-200);
	font-weight: 400;
	font-size: 18px;
}

/* Pages styles */
.add-page-btn {
	width: 100%;
}

.add-page-btn button {
	background-color: #237eae !important;
	color: var(--white-100);
	width: 100%;
	font: 500 20px/28px "Tajawal";
	max-width: 100%;
	height: 56px;
	border: 0;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.pages-search-bx {
	background-color: aliceblue;
	width: 100%;
	height: 56px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	padding: 0 10px;
}

.pages-search-bx input {
	all: unset;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

.pages-search-bx input::placeholder {
	color: var(--primary-300);
}

.pages-search-bx svg,
.pages-filters-bx .MuiFormControl-root svg {
	color: var(--primary-400);
}

.pages-filters-bx .MuiFormControl-root svg.filter-icon {
	position: absolute;
	top: 20px;
	right: 10px;
	z-index: 1;
}

/* ----------------------------------------------------------------------------------- */

/* Academy page style */

.academy-page .btns-group-container button {
	background: #eaf5f6;
	color: var(--second-600);
	font-size: 26px;
	font-weight: 500;
	width: 280px;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.academy-page .btns-group-container button.active {
	background: #b4edee;
	color: var(--second-700);
}

.video-preview {
	width: 215px;
	max-width: 100%;
	height: 100%;
	max-height: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	border-radius: 0 8px 8px 0px;
	-webkit-border-radius: 0 8px 8px 0px;
	-moz-border-radius: 0 8px 8px 0px;
	-ms-border-radius: 0 8px 8px 0px;
	-o-border-radius: 0 8px 8px 0px;
}

.video-preview .img-wrapper {
	width: 215px;
	max-width: 215px;
	height: 100%;
	max-height: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.video-preview img {
	max-height: 160px;
	object-fit: contain;
	object-position: center;
}

.video-preview .play-video-icon {
	background-color: rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
}

.video-preview .play-video-icon svg {
	color: var(--white-100);
	font-size: 35px;
	stroke-width: 0;
	cursor: pointer;
}

.academy-widget {
	display: flex;
	flex-direction: column;
	height: auto;
	min-height: 160px;
	max-height: 160px;
	overflow: hidden;
}

.academy-widget .course-bx {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
}

.academy-widget .course-details h5 {
	color: var(--primary-500);
	font-size: 20px;
	font-weight: 500;
}

.academy-widget .course-info {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
}

.academy-widget .course-info .text {
	font-size: 16px;
	font-weight: 500;
	width: 100px;
	color: var(--primary-500);
}

.academy-widget .course-info svg,
.course-stars svg path {
	color: var(--second-400);
	fill: var(--second-400);
}

.academy-widget .watch-course-btn {
	padding: 0 0 22px 10px;
}

.academy-widget .watch-course-btn button,
.academy-widget .watch-course-btn button:hover {
	background-color: var(--white--100) !important;
	border: 1.5px solid var(--second-500);
	color: var(--second-500);
}

.academy-widgets-wrapper .pagination-box {
	width: 1100px;
	max-width: 100%;
}

/* Explain page */
.explain-boxes {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.8rem;
	flex-wrap: wrap;
}

.explain-boxes .box {
	width: 340px;
	height: auto;
}

.explain-boxes .box .course-figure {
	background-color: var(--white-100);
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 8px 8px 0 0;
	-webkit-border-radius: 8px 8px 0 0;
	-moz-border-radius: 8px 8px 0 0;
	-ms-border-radius: 8px 8px 0 0;
	-o-border-radius: 8px 8px 0 0;
	box-shadow: 0px 3px 6px #0000000f;
	overflow: hidden;
}

.explain-boxes .box .course-figure .figure-caption {
	flex: 1;
	font-size: 22px;
	text-align: center;
	color: var(--black-500);
	padding: 16px 16px 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.course-figure .course-prev-image {
	flex: 9;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: inherit;
	-webkit-border-radius: inherit;
	-moz-border-radius: inherit;
	-ms-border-radius: inherit;
	-o-border-radius: inherit;
}

.course-figure .course-prev-image:after {
	background-color: rgba(0, 0, 0, 0.1);
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.375rem;
	-webkit-border-radius: 0.375rem;
	-moz-border-radius: 0.375rem;
	-ms-border-radius: 0.375rem;
	-o-border-radius: 0.375rem;
}

.course-figure .play-video-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	z-index: 10;
}

.course-figure .play-video-icon svg {
	color: var(--white-100);
	z-index: 1;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* template pages styles */

.template-edit-box h4 span {
	font-size: 16px;
	color: #67747b;
	font-weight: 400;
}

/* Upload files inputs style for edit template */

/* Update Logo */
.template-edit-box .logo-preview-container {
	height: 200px;
	border: 2px dashed var(--gray-300);
	margin-bottom: 10px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

.template-edit-box .logo-preview-container .logo-preview {
	width: 200px;
	max-width: 100%;
	padding: 30px;
}

.template-edit-box .logo-preview-container .logo-preview img {
	width: 100%;
	max-width: 100%;
	max-height: 100%;
}

.template-edit-box .upload-files-input {
	width: 100%;
	max-width: 100%;
	max-height: 55px;
	overflow: hidden;
	background: var(--gray-100);
	border: 2px dashed var(--gray-300);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	font-size: 18px;
	font-weight: 500;
	border-radius: 5px;
	cursor: pointer;
}

.template-edit-box .upload-files-input:hover {
	border-color: var(--gray-200);
}

.template-edit-box .upload-files-input svg {
	font-size: 24px;
	color: var(--primary-500);
}

/* Update banners */
.banners-preview-container {
	width: 100%;
	height: 200px;
	margin: 0 120px;
	margin-bottom: 10px;
	border: 1px solid var(--white-400);
	box-shadow: 0 0 3px 0 var(--black-100);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.banners-preview-container {
	height: 200px;
	max-height: 200px;
	width: 100%;
	overflow: hidden;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.banners-box .hint {
	margin-right: 92px;
}

.banners-preview-container img {
	width: 100%;
	max-width: 100%;
	object-fit: contain;
	mix-blend-mode: multiply;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.add-banners-bts-wrapper label {
	padding: 0 50px 0 0;
	margin-left: 74px;
	font-size: 18px;
	font-weight: 500;
}

.add-banners-bts-wrapper .wrapper {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	width: 700px;
	max-width: 100%;
	gap: 1rem;
}

.view-more-btn a {
	background: #237eae;
	color: var(--white-100);
	padding: 12px 30px;
	border-radius: 30px;
}

.view-more-btn a:hover {
	color: var(--white-400);
}

.view-more-btn a svg {
	font-size: 22px;
	stroke-width: 0.4;
}

.switch-widget {
	border: 1px solid var(--gray-200);
	box-shadow: var(--bx-shadow);
	padding: 14px;
	border-radius: 5px;
	font-size: 22px;
	font-weight: 500;
	width: 550px;
	max-width: 100%;
	margin: auto;
}

.switch-widget .client-icon {
	width: 35px;
	height: 35px;
}

.switch-widget .client-icon path#Path_657 {
	fill: var(--black-900);
}

.css-8jgyx3-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
	color: #38dd73 !important;
}

/* Comments pages */
.comments-wrapper {
	width: 160px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Verify Store Page Styles */

.verify-message-box .verify-message {
	margin-left: 80px;
	color: var(--primary-500);
	font-weight: 500;
}

.verify-message-box .verify-message.with-icon {
	white-space: pre-wrap;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	gap: 0.3rem;
}

.verify-message-box .btns-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.verify-message-box .btns-box a {
	background: var(--primary-500);
	width: max-content;
	padding: 10px 35px;
	border-radius: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--white-100);
}

.verify-message-box .btns-box svg {
	cursor: pointer;
	color: var(--primary-500);
}

/* STYLE VERIFY PAGE  */
.page-wrapper {
	width: 85%;
	margin: 0 auto;
}

.verify-store-page .MuiFormControl-root {
	max-width: 100% !important;
}

.verify-tab-bx {
	background: var(--gray-100);
	color: var(--primary-500);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 278px;
	height: 140px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.verify-tab-bx.active {
	background-color: var(--second-500);
	color: var(--white-100);
}

.verify-tab-bx h5 {
	font-size: 20px;
	font-weight: 500;
}

.form-row {
	margin: 0;
	overflow: hidden;
	white-space: normal;
	background: #f4f5f7;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.form-row .form {
	min-height: 220px;
	height: auto;
}

.select-store-type .label {
	font-size: 18px;
	font-weight: 400;
	color: #011723;
}

.select-store-type .Country_Key {
	direction: ltr;
	position: absolute;
	left: 14px;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.select-store-type .upload-docs-label {
	color: var(--second-500);
}

.select-store-type .upload-doc-input {
	cursor: pointer;
}

.select-store-type .upload-docs-icon path {
	fill: #000000;
}

/* this style for radio input to select   */

.row-input-group {
	position: relative;
	min-height: 350px;
	max-height: max-content;
	background: rgb(255, 255, 255);
	border-radius: 8px;
	z-index: 1;
	padding: 20px;
}

.CommercialRegisterInputGroup {
	top: 10px;
	right: 10px;
}

.FreeLaborDocumentInputGroup {
	top: 10px;
	right: 10px;
}

/* end of this style for radio input to select   */

.select-store-type .css-nkj2dh-MuiFormControl-root {
	background-color: var(--white-100);
	border: none;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.select-store-type .MuiOutlinedInput-input {
	padding: 14.5px 14px;
}

.select-store-type .MuiFormLabel-root {
	color: var(--black-600) !important;
	font-weight: 400 !important;
	font-size: 20px;
	right: 50px !important;
	transform-origin: top right !important;
}

.select-store-type .MuiSelect-icon {
	display: block !important;
	position: absolute;
	right: 95% !important;
	top: calc(50% - 0.5em);
	pointer-events: none;
	color: var(--second-500);
}

.select-store-type .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border: none !important;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.select-btn {
	background-color: var(--primary-500) !important;
}

.select-btn .button {
	width: 100% !important;
	color: var(--white-100);
}

/* ---------------------- */

/* Next button and prev button */
.next-btn,
.social-save-btn,
.prev-btn {
	height: 56px;
	padding: 14px !important;
	font-size: 20px !important;
	font-weight: 500 !important;
	border-radius: 8px !important;
	-webkit-border-radius: 8px !important;
	-moz-border-radius: 8px !important;
	-ms-border-radius: 8px !important;
	-o-border-radius: 8px !important;
}

.next-btn {
	width: 376px;
}

.social-save-btn {
	width: 190px;
}

.next-btn,
.social-save-btn {
	background: var(--second-500) !important;
	color: var(--white-100) !important;
}

.custom-width {
	width: 176px;
}

.prev-btn {
	width: 176px;
	background: var(--white--100);
	border: 1px solid var(--second-500) !important;
	color: var(--second-500) !important;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* social-page styles */
.social-links-form {
	background-color: var(--gray-100);
	padding: 30px 30px 60px 30px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.social-links-form input {
	width: 80%;
	height: 50px;
	border-radius: 2px;
	padding: 2px 20px;
}

.social-links-form label {
	font-size: 20px;
	color: var(--black-500);
	margin-bottom: 10px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* shipping page style */

.data-container {
	background: var(--gray-100);
	width: 100%;
	padding: 50px 50px 25px 50px;
}

.data-widget {
	background: var(--white-100);
	margin-bottom: 22px;
	height: 200px;
	box-shadow: var(--bx-shadow);
}

.data-widget .data {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 150px;
	gap: 0.5rem;
}

.other-shipping-company {
	width: 100%;
	background: #fff;
	padding: 16px 8px;
	margin: 0 auto;
}

.other-shipping-company .data-widget {
	box-shadow: none;
	margin-bottom: 0px;
	border: 1px solid #f5f5f5;
}

.other-shipping-company .shipping-company-hint {
	background-color: transparent;
	color: var(--second-500);
	white-space: normal;
	padding: 0;
}

.other-shipping-company .shipping-price-input-box {
	width: 100%;
	height: clamp(48px, 56px, 56px);
	padding: 0;
	background-color: var(--white-100);
	border: 1px solid var(--gray-100);
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
	border-radius: 2px;
}

.other-shipping-company .shipping-price-hint {
	background-color: #f7f8f8;
	fill: var(--gray-700);
	padding: 0 10px;
	font-size: 16px;
	font-weight: 500;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.other-shipping-company .shipping-price {
	background-color: transparent;
	padding-right: 8px;
	width: 100%;
	height: 100%;
	font-size: 18px;
	font-weight: 400;
}

.other-shipping-company .shipping-price::placeholder {
	color: #bebebe;
	font-size: 16px;
	font-weight: 400;
}

.other-shipping-company .shipping-price-input-box .currency {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	font-size: 18px;
	font-weight: 400;
	color: #a3a3a3;
}

.data-widget .shipping-image-box .shipping-company-name {
	color: var(--primary-500);
	font-size: 18px;
	font-weight: 500;
}

.data-widget .shipping-image-box .current-price {
	color: var(--primary-500);
	font-size: 16px;
	font-weight: 500;
}

.data-widget .shipping-image-box .current-price span {
	color: var(--second-500);
	font-size: 16px;
	font-weight: 400;
}

.other-shipping-company .save-price-btn {
	background-color: var(--second-500);
	color: #fff;
	height: clamp(48px, 56px, 56px);
	width: 100%;
	font-size: 20px;
	font-weight: 500;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}

.data-widget .switch-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f7f8f8;
	height: 50px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* Payment get ways style  */
.payment-page .image-box {
	padding: 40px 40px 10px 40px;
}

.payment-page .current-price {
	padding-bottom: 10px;
	font-size: 16px;
}

.payment-page .current-price span {
	font-weight: 500;
	padding-left: 5px;
}

.payments-hint {
	padding: 10px 15px;
}

.payments-hint.pending {
	background-color: #ffecd1c7;
	border: 1px solid #ffecd1c7;
	color: #ff9f1a;
}

.payments-hint.rejected {
	background-color: #ffebeb;
	border: 1px solid #ffebeb;
	color: #ff7b7b;
}

.payments-hint.closed {
	background-color: #ffecd1c7;
	border: 1px solid #ffecd1c7;
	color: #ff9f1a;
}

.payments-hint.dormant {
	background-color: #ffecd1c7;
	border: 1px solid #ffecd1c7;
	color: #ff9f1a;
}

.payments-hint button {
	background-color: var(--second-500);

	color: white;
	padding: 10px 14px;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* bank-accounts and wallet */

.data-container.wallet-data-container {
	background: #e7e7e729;
	width: 100%;
	padding: 25px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.wallet-data-box.data-box .icon svg {
	width: 22px;
	height: 22px;
}

.wallet-data-box.data-box .icon {
	width: 30px;
	height: 30px;
	padding: 18px;
}

.wallet-data-box.data-box .data {
	width: 200px;
	max-width: 200px;
}

.wallet-data-box.data-box .data p {
	font-size: 18px;
	width: 100%;
}

.bank-accounts {
	background-color: #fdfdfd;
	border-radius: 6px 6px 0 0;
	border: 1px solid #f8f8f8;
	box-shadow: 0px 0px 6px 6px #00000005;
}

.bank-accounts .bank-accounts-head {
	padding: 16px 14px;
	border-bottom: 1px solid #f8f8f8;

	border-radius: 6px 6px 0 0;
}

.bank-accounts button {
	background-color: var(--second-500);
	color: white;
	padding: 10px 14px;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
}

.bank-accounts button span svg {
	font-size: 18px;
	font-weight: 500;
}

/* add bank account */
.add-bank-Account .form-body {
	padding: 20px 50px;
	border-radius: 16px;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
}

.add-bank-Account form label {
	font-size: 18px;
	font-weight: 400;
}

.add-bank-Account form .inputs-wrapper {
	width: 100%;
	max-width: 100%;
	height: 50px;
	overflow: hidden;
	padding: 18px 0px 18px 14px;
	background: #ffffff;
	border: 1px solid var(--gray-200) !important;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	white-space: normal !important;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.add-bank-Account form .inputs-wrapper input {
	background-color: transparent;
	width: 100%;
	font-size: 16px;
	font-weight: 400;
}

.add-bank-Account form .inputs-wrapper input::placeholder,
.add-bank-Account form .inputs-wrapper .tax-text {
	font-size: 16px;
	color: var(--gray-300);
}

.add-bank-Account form .inputs-wrapper .tax-text.docs-file-name {
	color: #727272;
}

.add-bank-Account form .inputs-wrapper .svg-container {
	margin-right: 14px;
}
.add-bank-Account form .inputs-wrapper svg {
	color: var(--black-100);
}

.add-bank-Account form .inputs-wrapper .sa-iban-hint {
	font-size: 16px;
	font-weight: 500;
	height: 50px;
	padding: 0 14px 0 0;
	border-right: 1px solid var(--gray-200) !important;
}

.add-bank-Account form .inputs-wrapper.upload-civil-id {
	cursor: pointer;
}

/*  bank table style */
.bank-accounts-box .MuiTableCell-head {
	font-size: 16px !important;
	font-weight: 500 !important;
}

.bank-accounts-box .MuiTableCell-body {
	font-size: 16px !important;
}

.bank-account-table .bank-row {
	font-size: 18px;
	font-weight: 400;

	color: var(--black-400);
}

/* MAIN INFO PAGE */

.main-info-form {
	background-color: var(--white-100);
	padding: 40px;
	border-radius: 10px;
	box-shadow: var(--bx-shadow);
}

.main-info-form .upload-log-btn {
	background: var(--gray-100);
	border: none;
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
}

.main-info-form .simple-img {
	background: var(--gray-200);
	width: 20px;
	height: 20px;
	display: inline-block;
}

.main-info-form .wrapper {
	width: 50px;
	height: 20px;
	display: flex;
	justify-content: flex-end;
}

.main-info-form .simple-img.rectangle-logo {
	width: 50px;
	height: 20px;
}

.main-info-form .upload-image-wrapper {
	width: 100%;
	height: 250px;
	border-radius: 8px;
	margin-bottom: 10px;
	box-shadow: 0 0 3px 0px var(--black-100);
}

.main-info-form .upload-image-wrapper .upload-image-bx {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding: 10px;
}

.main-info-form .upload-image-wrapper .upload-image-bx img {
	max-height: 100%;
}

.upload_image_hint {
	font-size: 1rem;
	color: #7d7d7d;
	white-space: normal;
}
.upload-image-wrapper .upload-image-bx .logo-img-uploaded {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.main-info-form .upload-log-btn {
	width: 100%;
	color: var(--black-200);
}

/* verification alert style */
.verification-alert-btns button {
	width: 45%;
	font-size: 22px;
	font-weight: 400;
	height: 56px;

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.verification-alert-btns button.verification-now-btn {
	background-color: #1dbbbe;
	color: #fff;
}

.verification-alert-btns button.verification-later-btn {
	color: #1dbbbe;

	background-color: #fff;
	border: 1px solid #1dbbbe;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* select-country */
.select-country .form-select,
.store-desc,
.domain-name .main-domain-hint,
.modal-input-group input,
.store-email-input,
.modal-input-group textarea {
	position: relative;
	background-color: var(--white-200) !important;
	height: 55px !important;
	border: 1px solid var(--gray-100) !important;
	padding: 0 45px 0 30px !important;
	color: var(--black-600);
	font-size: 18px !important;
}

.select-country .form-select::placeholder,
.store-desc::placeholder,
.domain-name input::placeholder,
.modal-input-group input::placeholder,
.store-email-input::placeholder,
.modal-input-group textarea::placeholder {
	color: var(--gray-300);
	text-align: right;
	direction: rtl;
}
.domain-wrapper {
	padding: 20px;
	border-radius: 6px;
	box-shadow: 0px 0px 12px 0 #f2f2f5;
	border: 1px solid var(--second-500);
}
.domain-wrapper .domain-name .domain-input-label {
	font-size: 1rem;
	color: #02466a;
	white-space: normal;
}

.domain-name .domain-input-box {
	position: relative;
	height: 55px;
	padding-right: 16px;

	background-color: var(--white-200);
	border: 1px solid var(--gray-100);
	color: var(--black-600);
	font-size: 18px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}
.domain-name .domain-input-box input {
	width: 100%;
	padding-left: 12px !important;
	padding: 0 45px 0 30px !important;
}

.domain-name .main-domain-hint {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ededed !important;
	width: auto;
	padding: 0 20px 0 20px !important;
}

.store_phone_number .main-domain-hint {
	width: auto;
	padding: 0 10px 0 10px !important;
}

.setting_label {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
	color: var(--primary-500);
	margin-bottom: 5px;
	font-size: 18px;
	font-weight: 500;
}

.main-info-form
	.select-country
	.MuiInputBase-root:is(:focus, :hover)
	.MuiOutlinedInput-notchedOutline {
	border-color: #ededed !important;
}

.upload-icon-btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--white-400);
	height: 55px;
	border-radius: 5px;
	padding: 20px;
	border: 3px dashed var(--black-100);
	color: var(--black-200);
}

.upload-icon-btn svg {
	position: absolute;
	left: 10px;
}

.upload-icon-hint {
	color: var(--black-200);
	font-size: 16px;
	font-weight: 400;
	margin-top: 3px;
}

.select-country .store-desc {
	border: 1px solid var(--gray-200) !important;
	height: 170px !important;
	padding: 12px 45px 12px 30px !important;
	white-space: pre-wrap;
}

.edit-icon {
	fill: var(--gray-300);
}

.select-icon {
	position: absolute;
	z-index: 1;
	top: 11px;
	right: 16px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* maintenance-modal */
.maintenance-modal-header {
	background-color: var(--second-500);
	color: var(--white-100);
	padding: 1rem;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.maintenance-modal-header svg {
	cursor: pointer;
}

.maintenance-modal-body {
	padding: 20px;
}

.maintenance-modal-body .modal-body-header .modal-desc {
	white-space: normal;
	font-size: 18px;
	font-weight: 400;
	color: var(--black-300);
}

.maintenance-modal-form {
	width: 90%;
}

.modal-input-group label {
	display: flex;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 8px;
}

.modal-input-group input,
textarea {
	width: 100%;
}

.maintenance-modal-form textarea {
	height: 120px !important;
}

.maintenance-modal-form textarea {
	padding-top: 18px !important;
}

.modal-input-group .modal-input-icon {
	position: absolute;
	top: 47px;
	right: 15px;
	z-index: 1;
}

.modal-input-group .modal-input-icon svg {
	fill: var(--black-100);
}

.maintenance-modal-footer {
	background-color: var(--gray-100);
	padding: 24px 0;
	border-radius: 0 0 8px 8px;
	-webkit-border-radius: 0 0 8px 8px;
	-moz-border-radius: 0 0 8px 8px;
	-ms-border-radius: 0 0 8px 8px;
	-o-border-radius: 0 0 8px 8px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* customer-page */
.customer-page .add-category {
	padding: 30px 16px;
}

.customer-page .add-category .search-input {
	width: 50%;
}

.customer-page .add-category .select-input {
	width: 25%;
}

.customer-page .add-category label {
	display: none;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* Management and users page  */

.management-page .add-page-btn button {
	width: 100%;
	height: 56px;
	box-shadow: none;
	font-size: 20px;
	font-weight: 500;
}

.management-page .add-page-btn .outline {
	background-color: var(--white-100) !important;
	color: var(--primary-500);
	border: 1px solid var(--primary-500) !important;
}

.management-page .add-page-btn .roles-btn {
	background-color: var(--primary-500) !important;
	width: 85%;
}

.management-page .table-filter .table-title {
	background: var(--white-100);
	padding: 20px;
}

.management-page .table-filter .filter-row {
	background-color: #f6fcfe;
	padding: 20px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.management-page .table-filter .table-title h5 {
	font-weight: 600;
	color: #000;
}

.table-filter .filter-user-input-group input {
	position: relative;
	background-color: #ffff;
	border: 1px solid var(--gray-100) !important;
	height: 60px;
	width: 100%;
	font-size: 18px;
	padding: 0 20px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.table-filter .filter-user-input-group input {
	padding: 0 50px 0 20px;
}

.table-filter .filter-user-input-group input::placeholder {
	color: var(--gray-300);
}

.select-role-hint {
	color: var(--second-400);
}

/* ------------------------- filter-user-selector -------------- */
.table-filter .filter-user-selector .MuiOutlinedInput-notchedOutline {
	border: 1px solid var(--gray-100) !important;
	border-radius: 8px !important;
	-webkit-border-radius: 8px !important;
	-moz-border-radius: 8px !important;
	-ms-border-radius: 8px !important;
	-o-border-radius: 8px !important;
}

.table-filter .filter-user-selector .MuiInputBase-root,
.table-filter .filter-user-selector .MuiMenuItem-root {
	background-color: #fff;
}

.table-filter .filter-user-selector .MuiSelect-icon {
	color: var(--second-500) !important;
}

.filter-user-input-group .search-icon {
	position: relative;
	top: 45px;
	right: 18px;
}

.filter-user-input-group .search-icon svg {
	color: var(--second-300);
	z-index: 1;
	width: 20px;
}

.table-filter .filter-user-input-group label {
	display: flex;
	margin-bottom: 8px;
}

/* Reports Page Style */

.reports-page button.print-report-btn {
	width: 200px;
	height: 60px;
	border-radius: 8px;
	box-shadow: none;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.reports-page button.print-report-btn svg path {
	fill: #fff;
}

.reports-page .select-report-links {
	background-color: var(--white-100);
	padding: 50px 30px;
	border-radius: 10px;
}

.reports-page .select-report-links button {
	background-color: var(--white-100);
	width: 300px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	border-radius: 10px;
}

.reports-page .select-report-links .sales-btn {
	border: 2px solid var(--second-400);
	color: var(--second-500);
}

.reports-page .select-report-links .customers-btn {
	border: 2px solid var(--black-400);
	color: var(--black-500);
}

.reports-page .select-report-links .customers-btn svg path {
	fill: #000;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* SALES REPORT PAGE STYLE AND CUSTOMERS REPORTS  */

.sales-reports-data,
.customers-reports-data {
	width: 80%;
	background: var(--bs-white);
	padding: 20ppx;
	border-radius: 10px;
	box-shadow: 0 0 5px 0 var(--gray-100);
}

.sales-reports-data .report-head,
.customers-reports-data .report-head {
	box-shadow: 0px 1px 3px 0 var(--black-100);
	padding: 20px;
}

.sales-reports-data .report-body,
.customers-reports-data .report-body {
	padding: 50px 20px;
}

.sales-reports-data .total-wrapper h6,
.sales-reports-data .total-wrapper h5 {
	font-size: 18px;
	font-weight: 500;
	color: var(--black-500);
}

.sales-reports-data .total-wrapper .report_numbers {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.sales-reports-data .report-footer {
	padding: 20px;
	background-color: #f4f5f7;
}

.sales-reports-data .report-footer h5 {
	font-weight: 600;
}

/* customers-BarChart */
.customers-BarChart {
	height: 300px;
	width: 80%;
	margin-right: 150px;
}

.report-body h5 {
	margin-right: 190px;
	margin-bottom: -10px;
	font-size: 20px;
}

.customers-BarChart .recharts-legend-item-text {
	font-size: 16px;
	margin-right: 5px;
	margin-left: 16px;
	font-weight: 500;
}

.customers-BarChart .recharts-legend-item svg {
	width: 16px;
}

.customers-BarChart .recharts-text {
	font-size: 16px;
	font-weight: 400;
}

@media print {
	.sales-reports-data,
	.customers-reports-data {
		width: 80%;
		background: var(--bs-white);
		border-radius: 10px;

		direction: rtl;
	}

	.sales-reports-data .report-head,
	.customers-reports-data .report-head {
		padding: 20px;
	}

	.sales-reports-data .report-body,
	.customers-reports-data .report-body {
		padding: 50px 20px;
	}

	.sales-reports-data .total-wrapper h6,
	.sales-reports-data .total-wrapper h5 {
		font-size: 18px;
		font-weight: 500;
		color: var(--black-500);
	}

	.sales-reports-data .total-wrapper .report_numbers {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.sales-reports-data .report-footer {
		padding: 20px;
		background-color: #f4f5f7;
	}

	.sales-reports-data .report-footer h5 {
		font-weight: 600;
	}

	/* customers-BarChart */
	.customers-BarChart {
		height: 300px;
		width: 80%;
		margin-right: 150px;
	}

	.report-body h5 {
		margin-right: 190px;
		margin-bottom: -10px;
		font-size: 20px;
	}

	.customers-BarChart .recharts-legend-item-text {
		font-size: 16px;
		margin-right: 5px;
		margin-left: 16px;
		font-weight: 500;
	}

	.customers-BarChart .recharts-legend-item svg {
		width: 16px;
	}

	.customers-BarChart .recharts-text {
		font-size: 16px;
		font-weight: 400;
	}

	/* order details in print screen */
	.order-details-print {
		direction: rtl;
		padding: 20px;
	}

	.order-details-print .head-category .row {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between !important;
	}

	.order-details-print .head-category .row .order__number {
		justify-content: flex-end !important;
	}

	.order-details-print .head-category .row .order-number .title h5,
	#printableArea .row .order__number .order-number .title h5 {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 8px;
		color: var(--gray-700);
	}

	.order-details-print .head-category .row .order-number .number,
	#printableArea .row .order__number .order-number .number {
		background: #b1bc10;
		color: var(--white-100);
		font-size: 30px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 190px;
		height: 65px;
		border-radius: 8px;
	}

	.order-details-print .head-category .breadcrumb {
		display: none;
	}
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* Start nested pages Styles */
/* Add Category form */
.form-title,
.add-form-wrapper .form-footer {
	background: var(--gray-100);
	padding: 40px 30px;
}

.form-title h5 {
	font-size: 26px;
	font-weight: 700;
}

.form-title p {
	color: var(--black-500);
	font-size: 22px;
	font-weight: 400;
}

.add-form-wrapper .form-body {
	background: var(--bs-gray-100);
	padding: 30px;
}

.add-form-wrapper .add-image-btn-box {
	height: 175px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 2px dashed var(--black-200);

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.uplod-wrap .tax-text {
	margin: 10px 0 25px 0;
}

.add-form-wrapper .add-image-btn-box svg {
	width: 36px;
	height: 36px;
	margin-bottom: 0;
}

.add-form-wrapper .add-image-btn-box label {
	font-size: 18px;
	font-weight: 500;
	color: var(--gray-800);
	margin-bottom: 12px;
}

.add-form-wrapper .add-image-btn-box span {
	font-size: 16px;
	font-weight: 400;
	color: var(--gray-600);
}

.add-form-wrapper .banners-preview-container {
	width: 100px;
	height: 100px;
	border: none;
	box-shadow: none;
	margin-right: 0;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.add-form-wrapper .banners-preview-container .banner-preview {
	position: relative;
	width: 100px;
	height: 100px;
	border: none;
	box-shadow: none;
	margin-right: 0;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.add-form-wrapper .banners-preview-container .banner-preview .close-icon {
	position: absolute;
	left: 0;
	font-size: 19px;
	cursor: pointer;
	z-index: 1;
}

.add-form-wrapper .banners-preview-container .banner-preview img {
	width: 100%;
	height: 100%;
	border: none;
	box-shadow: none;
	margin-right: 0;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
}

.add-form-wrapper form label {
	font-size: 18px;
	font-weight: 500;
	white-space: pre-wrap;
}

.add-form-wrapper form input,
.add-form-wrapper form textarea,
.create-offer-form-wrapper .form-body input,
.create-offer-form-wrapper .form-body textarea {
	width: 100%;
	max-width: 100%;
	height: 56px;
	padding: 20px;
	background: #ffffff;
	border: 1px solid #a7a7a71a !important;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	white-space: normal !important;
}

.add-form-wrapper form .MuiInputBase-root {
	background: #ffffff;
}

.add-form-wrapper form input:disabled,
.add-form-wrapper form textarea:disabled,
.create-offer-form-wrapper .form-body input:disabled,
.create-offer-form-wrapper .form-body textarea:disabled {
	background: #ffffff00;
	/* opacity: 0.9; */
}

.add-form-wrapper form input[type="text"],
.add-form-wrapper form input[type="password"],
.add-form-wrapper form input[type="email"],
.add-form-wrapper form textarea,
.add-form-wrapper form select {
	font-size: 18px;
}

.add-form-wrapper form input::placeholder,
.add-form-wrapper form textarea::placeholder {
	color: var(--gray-400);
}

.add-form-wrapper form .MuiOutlinedInput-notchedOutline {
	font-size: 18px;
	padding: 0;
	border: 1px solid #efeff0 !important;
	border-radius: 6px !important;
	-webkit-border-radius: 6px !important;
	-moz-border-radius: 6px !important;
	-ms-border-radius: 6px !important;
	-o-border-radius: 6px !important;
}

.add-form-wrapper form .MuiOutlinedInput-notchedOutline:hover {
	border: 1px solid #efeff0 !important;
}

.add-form-wrapper form .MuiFormLabel-root {
	color: var(--black-600) !important;
	font-weight: 400 !important;
	font-size: 20px;
	right: 50px !important;
	transform-origin: top right !important;
}

.MuiFormLabel-root.Mui-focused {
	display: none !important;
}

.add-form-wrapper form .MuiSelect-icon {
	display: block !important;
	position: absolute;
	right: 94%;
	top: calc(50% - 0.5em);
	pointer-events: none;
	z-index: 1;
}

/* EDIT IMPORT PRODUCTS */
.add-form-wrapper form .sub-category .tags {
	min-width: 134px;
	max-width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	white-space: pre-line;
	background: #eeeeef;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #67747b;
	border-radius: 28px;
}

.add-form-wrapper form .import_products_input {
	text-align: center;
	direction: ltr;
	border: none !important;
	border-radius: none !important;
	-webkit-border-radius: none !important;
	-moz-border-radius: none !important;
	-ms-border-radius: none !important;
	-o-border-radius: none !important;
}

.add-form-wrapper form .currency {
	width: 54px;
	height: 48px;
	background: #fafafa;
	border: 1px solid #ececec;
}

.add-form-wrapper form .currency_icon {
	width: 54px;
	height: 48px;
}

/* ------------------- */
.add-bank-Account .form-footer button,
.add-form-wrapper .form-footer button,
.order-details-body button {
	width: 100%;
	height: 60px;
	font-size: 22px;
	font-weight: 500;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.add-form-wrapper form .period-input {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
	height: 56px;
	padding: 20px 20px 20px 0;
	background: #ffffff;
	border: 1px solid #a7a7a71a;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	white-space: normal !important;
}
.add-form-wrapper form .period-input input {
	all: unset;
	border: none !important;
	width: 90%;
	max-width: 100%;
	height: 56px;
}

.add-form-wrapper form .period-input .input-type {
	height: 56px;
	width: 10%;
	font-size: 14px;
	background-color: #a7a7a71a;
	border: 1px solid #a7a7a71a;
}

@media (max-width: 768px) {
	.add-bank-Account .form-footer button,
	.add-form-wrapper .form-footer button,
	.order-details-body button {
		height: 44px;
		font-size: 18px;
		font-weight: 400;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}

	.add-form-wrapper form .period-input .input-type {
		width: 20%;
	}
}

.add-form-wrapper .form-footer button.save-btn,
.add-bank-Account .form-footer button.save-btn,
.order-details-body button.save-btn {
	background-color: var(--second-500);
	color: var(--white-100);
}

.add-form-wrapper .form-footer button.close-btn,
.add-bank-Account .form-footer button.close-btn,
.order-details-body button.close-btn {
	background-color: transparent;
	border: 1px solid var(--second-500);
	color: var(--second-500);
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* Add Product Form style */
.add-form-wrapper form textarea {
	height: 150px;
	resize: none;
	white-space: normal;
}

.add-form-wrapper form .add-new-cate-btn,
.product-option-btn {
	background-color: #ffffff00;
	border: 2px dashed var(--gray-300) !important;
	color: var(--black-400);
	font-size: 20px;
	font-weight: 400;
	height: 60px;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* Add Coupon Form Style */
.add-coupon-form .form-body {
	padding: 60px 30px;
}

.add-coupon-form.coupon-details .form-body {
	padding: 30px 30px 60px;
}

.add-coupon-form .coupon-status-wrapper {
	background: var(--bs-gray-100);
	padding-right: 100px;
}

.add-coupon-form .coupon-status-wrapper .coupon-status {
	width: 190px;
	height: 75px;
	font-size: 30px;
	color: #fff;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.add-coupon-form .coupon-status-wrapper .coupon-status.active {
	background: var(--green-500);
}

.add-coupon-form .coupon-status-wrapper .coupon-status.pending {
	background: var(--red-500);
}

.add-coupon-form .coupon-status-wrapper .coupon-status.disabled {
	background: var(--gray-300);
}

.add-coupon-form .coupon-status-wrapper .enable-coupon-btn {
	background: none;
	color: var(--blue-500);

	margin-top: 16px;
	font-size: 22px;
}

.add-coupon-form form.disabled label,
.add-coupon-form form.disabled .enable-switches span {
	color: rgba(0, 0, 0, 0.26);
}

.add-coupon-form
	form.disabled
	.enable-switches
	.Mui-checked
	+ .MuiSwitch-track {
	background-color: rgba(0, 0, 0, 0.26) !important;
}

.add-coupon-form form input:disabled,
input:disabled {
	cursor: not-allowed;
	color: rgba(0, 0, 0, 0.8);
}

.add-coupon-form form input:disabled,
input:disabled {
	background-color: var(--bs-light-bg-subtle);
}

.add-coupon-form .form-body label {
	font-size: 20px;
}

.add-coupon-form form input {
	padding: 18px;
	font-size: 20px;
}

.add-coupon-form .form-body .radio-box label {
	font-size: 22px;
}

.add-coupon-form .radio-box:first-child,
.add-coupon-form .radio-box.select-apply-offer {
	margin-right: -30px;
}

.add-coupon-form .search-icon {
	position: absolute;
	z-index: 1;
	right: 10px;
	top: 18px;
}

.add-coupon-form .search-icon path {
	fill: var(--black-100);
}

.add-coupon-form .row-title p {
	color: var(--black-200);
}

.add-coupon-form .radio-box .MuiSvgIcon-root {
	width: 25px;
}

/* Date-picker input style */
.react-datepicker-popper {
	transform: translate(260px, 0px) !important;
	-webkit-transform: translate(260px, 0px) !important;
	-moz-transform: translate(260px, 0px) !important;
	-ms-transform: translate(260px, 0px) !important;
	-o-transform: translate(260px, 0px) !important;
}

/* .datepicker__input-container input{
	cursor: pointer
} */
.react-datepicker__triangle {
	left: -18px !important;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker-wrapper input {
	padding-right: 100px !important;
	cursor: pointer;
}

.add-coupon-form .date-icon {
	background-color: #00ccf2;
	position: absolute;
	width: 90px;
	height: 56px;
	display: flex;
	z-index: 1;
	justify-content: center;
	align-items: center;
	/* cursor: pointer; */
	border-radius: 0 8px 8px 0;
}

.add-coupon-form .react-datepicker-popper {
	transform: translate(160px, 0px) !important;
	-webkit-transform: translate(160px, 0px) !important;
	-moz-transform: translate(160px, 0px) !important;
	-ms-transform: translate(160px, 0px) !important;
	-o-transform: translate(160px, 0px) !important;
}

/* ---------------- */
.add-coupon-form .radio-box .MuiRadio-root.Mui-checked {
	color: var(--black-500);
}

.add-coupon-form .radio-box .MuiRadio-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important;
	cursor: not-allowed;
}

.add-coupon-form .form-body .radio-box label.disabled {
	color: rgba(0, 0, 0, 0.26);
}

.add-coupon-form .form-body .enable-switches span {
	font-size: 22px;
	font-weight: 500;
}

.add-coupon-form .form-body .enable-switches span.disabled {
	color: rgba(0, 0, 0, 0.26);
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* create-offers-page */

.create-offer-form-wrapper {
	background-color: var(--white-100);
	box-shadow: 0 0 5px 0 var(--bs-gray-200);
	border: 1px solid var(--bs-gray-200);
	border-radius: 8px 8px 0px 0;
	-webkit-border-radius: 8px 8px 0px 0;
	-moz-border-radius: 8px 8px 0px 0;
	-ms-border-radius: 8px 8px 0px 0;
	-o-border-radius: 8px 8px 0px 0;
	margin-bottom: 60px;
}

.create-offer-form-wrapper .form-title {
	background: var(--bs-gray-200);
	border: 1px solid var(--bs-gray-300);
	border-radius: 8px 8px 0px 0;
	padding: 20px 35px;
}

.create-offer-form-wrapper .form-title h5 {
	font-size: 23px;
}

.create-offer-form-wrapper .form-body {
	padding: 40px 35px;
}

.create-offer-form-wrapper .form-section {
	background-color: var(--white-100);
}

.create-offer-form-wrapper .form-body .row-title h4 {
	color: var(--black-500);
	font-size: 22px;
	font-weight: 500;
}

.create-offer-form-wrapper .form-body .row-title p {
	color: var(--gray-400);
	font-size: 18px;
	font-weight: 400;
}

.create-offer-form-wrapper .form-body .radio-btn-group {
	margin-right: -30px;
}

.create-offer-form-wrapper .radio-box .MuiSvgIcon-root {
	width: 26px;
}

.create-offer-form-wrapper .radio-box .MuiButtonBase-root.Mui-checked {
	color: var(--second-500) !important;
}

.create-offer-form-wrapper .form-body .radio-btn-group .radio-box label {
	font-size: 22px;
	font-weight: 400;
	color: var(--primary-500);
}

.create-offer-form-wrapper .form-body .radio-btn-group .radio-box label.active {
	color: var(--second-500) !important;
}

.create-offer-form-wrapper label {
	color: var(--black-500);
	font-size: 20px;
	font-weight: 400;
}

.create-offer-form-wrapper .form-body label .sub-label {
	color: var(--gray-400);
	font-size: 18px;
	font-weight: 500;
	margin-right: 10px;
}

.create-offer-form-wrapper .form-body input[type="text"],
.create-offer-form-wrapper .form-body input[type="number"],
.create-offer-form-wrapper .form-body textarea,
.create-offer-form-wrapper .form-body select {
	background-color: var(--white-300) !important;
	box-shadow: 0 0 4px 0 var(--white-500) !important;
	border: 1px solid var(--gray-100) !important;
}

.create-offer-form-wrapper .form-body input[type="text"],
.create-offer-form-wrapper .form-body input[type="number"] {
	padding-right: 60px;
	padding-top: 14px;
	/* cursor: pointer; */
}

.create-offer-form-wrapper .form-body input::placeholder {
	font-size: 18px;
	font-weight: 400;
	color: var(--gray-300);
}

.create-offer-form-wrapper .form-body .input-icon {
	position: absolute;
	top: 45px;
	z-index: 1;
	right: 35px;
}

.create-offer-form-wrapper .form-body .input-icon svg {
	color: var(--primary-500);
}

.create-offer-form-wrapper .form-body .input-icon svg.quantity {
	width: 28px;
	height: 28px;
}

.create-offer-form-wrapper .form-body .input-icon svg.quantity path {
	fill: var(--primary-500);
}

.create-offer-form-wrapper .form-body .input-icon .search-icon {
	width: 30px;
	height: 26px;
	top: -30px;
	right: -20px;
}

.create-offer-form-wrapper .form-body .input-icon .search-icon path {
	fill: var(--gray-300);
}

/* Date-picker input style */

.create-offer-form-wrapper .form-body .date-icon {
	background-color: var(--primary-500);
	position: absolute;
	width: 90px;
	height: 56px;
	display: flex;
	z-index: 1;
	justify-content: center;
	align-items: center;
	/* cursor: pointer; */
	border-radius: 0 8px 8px 0;
}

.offers-options .form-body .radio-box {
	margin-right: -15px;
}

.offers-options .form-body .radio-box label.active {
	color: var(--second-500) !important;
}

/* Select Offer Platform */
.create-offer-form-wrapper .MuiFormControl-root {
	width: 100%;
	height: 60px !important;
}

.create-offer-form-wrapper .MuiFormControl-root .MuiInputBase-root {
	background-color: var(--white-100) !important;
	height: 60px !important;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.create-offer-form-wrapper
	.MuiFormControl-root
	.MuiInputBase-root
	.MuiOutlinedInput-notchedOutline,
.create-offer-form-wrapper
	.MuiFormControl-root
	.MuiInputBase-root
	.MuiOutlinedInput-notchedOutline:hover {
	border-color: #efeff0 !important;
}

.create-offer-form-wrapper .MuiFormControl-root #icon-24-chevron_down {
	right: 93% !important;
}

/* offers-data */
.create-offer-form-wrapper.offers-data .form-title {
	background: var(--second-500);
}

.create-offer-form-wrapper.offers-data .form-title h5 {
	color: var(--white-100);
	font-size: 26px;
	font-weight: 400;
}

.create-offer-form-wrapper.offers-data .form-title svg {
	width: 28px;
	height: 28px;
}

.create-offer-form-wrapper.offers-data .form-title svg path {
	fill: #fff;
}

.offers-data .form-body .offer-box {
	background-color: var(--second-100);
	position: relative;
	height: 260px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.offers-data .form-body .offer-box p {
	white-space: normal;
}

.offers-data .form-body .arrow-icon {
	position: absolute;
	z-index: 1;
	top: 50%;
	right: 50%;
	width: max-content;
	transform: translate(50%, -20%);
}

.offers-data .form-body .arrow-icon svg {
	background: var(--second-500);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
}

.offers-data .form-body .arrow-icon svg path {
	fill: var(--white-100);
}

/* create offers button */

.form-footer-btn .create-offer-btn {
	width: 100%;
	height: 65px;
	font-size: 26px;
	font-weight: 400;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.form-footer-btn .active-offer-btn {
	background-color: var(--second-500);
	color: var(--white-100);
}

.form-footer-btn .active-offer-btn:disabled {
	background-color: var(--black-200);
}

.form-footer-btn .cancel-offer-btn {
	background-color: transparent;
	border: 2px solid var(--black-200);
	color: var(--black-200);
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* create-pages-modal */

.create-pages-form-wrapper .form-title {
	background: #c6e1f0;
	padding: 25px;
}

.create-pages-form-wrapper .form-title h5 {
	color: var(--primary-500);
	font-size: 20px;
	font-weight: 400;
}

.create-pages-form-wrapper .form-title svg {
	color: var(--primary-500);
	stroke-width: 20px;
	cursor: pointer;
	width: 20px;
	height: 20px;
}

.create-pages-form-wrapper .form-body {
	padding: 25px;
}

.create-pages-form-wrapper .form-body input,
.create-pages-form-wrapper .form-body textarea {
	border: 1px solid var(--gray-200) !important;
	height: 56px;
	padding-right: 55px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	white-space: normal !important;
}

.form-body input.seo_link {
	text-align: left !important;
	direction: ltr;
	padding-left: 16px;
}

.form-body input.seo_link::placeholder {
	text-align: right;
}

.create-pages-form-wrapper .form-body input::placeholder,
.create-pages-form-wrapper .form-body textarea::placeholder {
	font-size: 18px;
	color: var(--gray-300);
}

.create-pages-form-wrapper .form-body .input-icon {
	position: absolute;
	z-index: 1;
	top: 15px;
	right: 30px;
}

.rdw-image-modal {
	position: absolute;
	top: 35px;
	/* left: 5px; */
	display: flex;
	flex-direction: column;
	width: 250px;
	border: 1px solid #f1f1f1;
	padding: 15px;
	border-radius: 2px;
	z-index: 100;
	background: white;
	box-shadow: 3px 3px 5px #bfbdbd;
}

.rdw-image-modal-url-input {
	width: 90% !important;
	height: 35px !important;
	margin: 15px 0 12px;
	border: 1px solid #f1f1f1;
	border-radius: 2px;
	font-size: 15px;
	padding: 0 5px;
}

.rdw-image-mandatory-sign {
	color: red;
	margin-left: 3px;
	margin-right: 3px;
}

.rdw-image-modal-size {
	display: flex;
	justify-content: center;
	align-items: center;
}

.rdw-image-modal-size-input {
	width: 40% !important;
	height: 20px !important;
	border: 1px solid #f1f1f1;
	padding-right: 10px !important;
	border-radius: 2px;
	font-size: 12px;
}

.rdw-image-modal-btn-section {
	margin: 10px auto 0;
}

.rdw-image-modal-btn {
	margin: 0 5px;
	width: 75px !important;
	height: 30px;
	border: 1px solid #f1f1f1;
	border-radius: 2px;
	cursor: pointer;
	background: white;
	text-transform: capitalize;
	font-size: 16px;
}

.rdw-image-modal-btn:disabled {
	background: #ece9e9;
}

.create-pages-form-wrapper .form-body .input-icon svg {
	width: 30px;
	height: 30px;
	fill: var(--gray-500);
}

.create-pages-form-wrapper .form-body .seo-inputs h4 {
	font-size: 24px;
	font-weight: 500;
}

.create-pages-form-wrapper .form-body .seo-inputs input {
	background-color: var(--gray-100);
}

.create-pages-form-wrapper .form-body .seo-inputs .input-icon {
	top: 52px;
}

.create-pages-form-wrapper .form-body .seo-inputs .input-icon svg path {
	fill: var(--gray-500);
}

.create-pages-form-wrapper .form-body .check-box-inputs .wrapper {
	background-color: var(--white-100);
	border: 1px solid var(--gray-100);
	height: 334px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.create-pages-form-wrapper .form-body .check-box-inputs .wrapper .title,
.create-pages-form-wrapper
	.form-body
	.check-box-inputs
	.wrapper
	.body
	.checkbox-row {
	border-bottom: 1px solid var(--gray-100);
	padding: 20px;
}

.create-pages-form-wrapper
	.form-body
	.check-box-inputs
	.wrapper
	.body.page-category {
	height: calc(334px - 71px);
	overflow-y: auto;
}

.create-pages-form-wrapper
	.form-body
	.check-box-inputs
	.wrapper
	.body
	.checkbox-row {
	padding: 18px;
	padding-right: 12px;
}

.create-pages-form-wrapper .form-body .check-box-inputs .wrapper .title h4 {
	font-size: 22px;
	font-weight: 400;
}

.create-pages-form-wrapper .form-body .check-box-inputs .wrapper input,
.create-pages-form-wrapper .form-body .check-box-inputs .wrapper button {
	background-color: var(--white-100) !important;
	border: 1px solid #237eae !important;
	height: 56px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.create-pages-form-wrapper .form-body .check-box-inputs .wrapper input {
	padding: 20px;
	caret-color: #237eae;
}

.create-pages-form-wrapper .form-body .check-box-inputs .wrapper button {
	color: #237eae;
	font-weight: 400;
	font-size: 20px;
}

.create-pages-modal .form-footer-btn .create-page-btn {
	width: 100%;
	height: 56px;
	border-radius: 8px;
	font-size: 24px;
	font-weight: 400;
}

.create-pages-modal .form-footer-btn .save-btn {
	background-color: var(--second-500);
	color: var(--white-100);
}

.create-pages-modal .form-footer-btn .share-btn {
	background-color: transparent;
	color: var(--second-500);
	border: 1px solid var(--second-500);
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* Order details page */
.order-details-page .breadcrumb-item .arrow-back-icon {
	background: #a1f9fc00;
	border-radius: 50%;
}

.order-details-page .breadcrumb-item .arrow-back-icon path {
	fill: var(--second-500);
}

.order-details-page .head-category h3 {
	font-size: 29px;
	margin-bottom: 1rem;
}

.order-details-page .head-category .order-number {
	margin-left: 20px;
}

.order-details-page .head-category .order-number .title h5,
#printableArea .row .order__number .order-number .title h5 {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 8px;
	color: var(--gray-700);
}

.order-details-page .head-category .order-number .number,
#printableArea .row .order__number .order-number .number {
	background: #b1bc10;
	color: var(--white-100);
	font-size: 30px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 190px;
	height: 65px;
	border-radius: 8px;
}

.order-details-body button.print-billing-btn {
	color: #eff9ff;
	font-size: 22px;
	font-weight: 400;
	height: 54px;
	width: 163px;
	background-color: #02466a;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

/* ------------------ */
.order-details-body {
	width: 90%;
}

.order-details-body .order-details-box {
	background-color: var(--white-100);
	box-shadow: var(--bx-shadow);
	padding: 25px 20px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.order-details-body .order-details-box .title h5 {
	font-size: 22px;
	font-weight: 400;
	margin-bottom: 16px;
}

.order-details-body .order-details-box .order-details-data .boxes {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	flex-wrap: wrap;
	gap: 1rem;
}

.order-details-body .order-details-box .order-details-data .boxes .box {
	flex: 1;
	min-width: 300px;
}

.order-details-body .order-details-box .order-details-data .order-head-row {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 8px;
}

.order-details-body .order-details-box .order-details-data .order-head-row svg {
	width: 30px;
	height: 30px;
}

.order-details-body
	.order-details-box
	.order-details-data
	.order-head-row
	svg
	path {
	fill: #1dbbbe;
}

.order-details-body
	.order-details-box
	.order-details-data
	.order-head-row
	svg.date-icon
	path {
	fill: var(--second-500);
}

.order-details-body
	.order-details-box
	.order-details-data
	.order-head-row
	span {
	font-size: 20px;
}

.order-details-body
	.order-details-box
	.order-details-data
	.order-head-row
	span.price
	span {
	font-size: 15px;
	font-weight: 500;
}

.order-details-body .order-details-box .order-details-data .order-data-row span,
.order-data-row > div {
	background: #cce4ff38;
	min-width: 190px;
	min-height: 65px;
	max-height: 100px;
	overflow: auto;
	box-shadow: 0 0 5px 0px #eded;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-size: 20px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.order-data-row.track_id_box span.track_id_input {
	box-shadow: none !important;
	background: transparent !important;
	border-radius: 0px !important;
	-webkit-border-radius: 0px !important;
	-moz-border-radius: 0px !important;
	-ms-border-radius: 0px !important;
	-o-border-radius: 0px !important;
	width: 90%;
	padding: 0 100px 0 10px;
}

.order-data-row.track_id_box span.track_id_input::-webkit-scrollbar {
	height: 8px;
	width: 1px;
}

.order-data-row.track_id_box span.track_id_input::-webkit-scrollbar-thumb {
	border: none;
}

.order-details-body
	.order-details-box
	.order-details-data
	.order-data-row
	span.status {
	background: var(--blue-100);
	color: var(--blue-500);
}

/*  to  handle copy-track_id-icon style  */
.copy-track_id-icon {
	background: transparent;
	width: 10%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.client-date img {
	width: 100px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.register-type {
	color: #b1bc10;
	font-size: 16px;
	font-weight: 500;
	white-space: normal !important;
}

.register-date {
	color: var(--primary-500);
	font-size: 18px;
	font-weight: 400;
}

.order-details-body .order-details-box .order-details-data .info-box {
	background: #cce4ff38;
	box-shadow: 0 0 5px 0px #eded;
	padding: 18px 30px;
	display: flex;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	align-items: center;
	justify-content: space-between;
}

.order-details-body .order-details-box .order-details-data .info-box svg {
	width: 30px;
	height: 30px;
}

.order-details-body
	.order-details-box
	.order-details-data
	.info-box
	.client-icon
	path {
	fill: var(--second-500);
}

.order-details-body .order-details-box .order-details-data .info-box span {
	width: 100%;
	text-align: center;
	direction: ltr;
}

.order-details-body .order-details-box .order-action-box {
	background-color: #cce4ff38;
	width: 100%;
	box-shadow: 0 0 5px 0px #eded;
	padding: 18px 20px;
	display: flex;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	align-items: center;
	justify-content: space-between;
}

.shipping-address-input {
	width: 100%;
	height: 56px;
	padding: 5px 14px;
	background-color: #cce4ff38;
	box-shadow: 0 0 5px 0px #cce4ff38;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.table-price_span {
	display: block;
	width: 100%;
	text-align: right;
}

.accordion-box span.video-name {
	font-size: 20px;
	color: var(--blue-500);
}

.accordion-box span.video-name:hover {
	color: var(--blue-600);
}

.accordion-box .accordion-body {
	padding: 20px 0;
}

.accordion-box .accordion-body ul li {
	padding: 16px 20px;

	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.accordion-box .accordion-body ul li:hover {
	background-color: var(--second-300);
}

.order-details-body .order-details-box .order-action-box .list-icon,
.order-details-body .order-details-box .action-title .list-icon {
	width: 20px;
	height: 20px;
}

.order-details-body .order-details-box .order-action-box svg:not(.list-icon) {
	width: 24px;
	height: 24px;
}

.order-details-body
	.order-details-box
	.order-action-box
	svg:not(.list-icon)
	path {
	fill: var(--second-500);
}

.order-details-body .order-details-box .order-action-box .delete-icon path {
	fill: red !important;
}

/* return order style */
.order-details-body .order-details-box .return-order-price-input {
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 5px 1rem;
	border: 1px solid rgba(204, 228, 255, 0.22);
	box-shadow: rgba(238, 221, 238, 0.867) 0px 0px 5px 0px;
}

.order-details-body .order-details-box .return-order-price-input input {
	background-color: transparent;
	padding-right: 10px;
}
.order-details-body
	.order-details-box
	.return-order-price-input
	.return-order-currency {
	font-size: 18px;
	position: absolute;
	left: 0;
	padding-left: 10px;
}

.order-details-body .order-details-box .refund-order-btn {
	width: max-content;
	height: 56px;
	background-color: rgb(29, 187, 190);
	color: #fff;
	font-size: 18px;
	font-weight: 400;
	padding: 12px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}
/* -------------------------------------------------------------------------------------------------------------------------------- */
/* course-details-page */

.course-details-page .course-image {
	width: 80%;

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.course-details-page .course-image img {
	border: 1px solid #e7e5e5;
	border-radius: 8px;
}

.course-details-page .course-actions .course-name h4 {
	font-size: 26px;
	line-height: 36px;
	font-weight: 400;
	white-space: normal;
}

.course-details-page .course-actions .course-unites {
	background: var(--gray-100);
	padding: 18px 20px;
	border-bottom: 1px solid var(--gray-300);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.course-details-page .course-actions .course-unites div {
	font-size: 20px;
}

.course-details-page .course-actions .course-unites div .unite-name {
	font-weight: 500;
	margin-left: 10px;
}

.course-details-page .course-actions .course-unites div .course-count,
.course-details-page .course-actions .course-unites div .course-time {
	color: var(--black-300);
	font-weight: 400;
	margin-left: 8px;
	font-size: 18px;
}

.course-details-page .course-actions .course-unites .arrow-icon svg path {
	fill: var(--black-600);
}

.course-unites .accordion-body ul li:hover {
	background-color: transparent;
}

.course-unites .accordion-body ul li .unit-files,
.course-unites .accordion-body ul li .unit-download {
	color: var(--blue-500);
}

.explain-details-page .explain-video,
.course-details-video-modal {
	width: 90%;
	height: 500px;

	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.video-course-close-icon {
	position: absolute;
	right: 5px;
	top: -30px;
	z-index: 1;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* SupportDetails-page */
.SupportDetails-page {
	background-color: var(--white-500);
}

.SupportDetails-page .head-category {
	background-color: var(--bs-gray-200);
	padding: 60px 75px 0 0;
}

.SupportDetails-page .issue-wrapper {
	padding: 0 75px 0 0;
}

.SupportDetails-page .issue-wrapper .issue-number h5 {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 8px;
	color: var(--gray-700);
}

.SupportDetails-page .issue-wrapper .issue-number div {
	background: #077dbc;
	color: var(--white-100);
	box-shadow: 0 0 2px 0px var(--primary-500);
	font-size: 28px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 190px;
	height: 65px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.SupportDetails-page .issue-wrapper .issue-details-box {
	background-color: var(--white-100);
	box-shadow: var(--bx-shadow);
	width: 90%;
	padding: 25px 20px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.SupportDetails-page .issue-wrapper .issue-details-box .box {
	width: 50%;
	height: 80px;
	display: flex;
	border-radius: 6px;
	padding: 20px;
	font-weight: 400;
	align-items: center;
	justify-content: flex-start;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.SupportDetails-page .issue-wrapper .issue-details-box .box svg path {
	fill: var(--second-500);
}

.SupportDetails-page .issue-wrapper .issue-details-box .box.success-box {
	background: #cce4ff57;
	color: var(--blue-400);
	box-shadow: 0 0 2px 0px var(--gray-100);
}

.SupportDetails-page .issue-wrapper .issue-details-box .box.pending-box {
	background: #ffecd147;
	color: var(--orange-400);
	box-shadow: 0 0 2px 0px var(--gray-100);
}

.SupportDetails-page .issue-wrapper .issue-details-box .box.wring-box {
	background: #fac5c512;
	color: var(--blue-400);
	box-shadow: 0 0 2px 0px var(--gray-100);
}

.SupportDetails-page .issue-content {
	width: 90%;
}

.SupportDetails-page .issue-content .issue-title {
	font-weight: 400;
}

.SupportDetails-page .issue-content .text-editor-icons {
	background: var(--white-100);
	border-radius: 6px;
	border: 1px solid var(--gray-100);
	padding: 18px;
}

.SupportDetails-page .issue-content .text-editor-icons svg {
	cursor: pointer;
}

.SupportDetails-page .issue-content textarea {
	height: 200px;
	padding: 25px;
	font-size: 20px;
	white-space: normal;
	line-height: 1.6;
	color: var(--black-300);
	resize: none;
	box-shadow: 0 0 2px 0px var(--gray-100);
}

.SupportDetails-page .issue-content textarea:disabled {
	background-color: #ffffff;
}

.SupportDetails-page .close-btn {
	width: 90%;
}

.SupportDetails-page .close-btn button {
	background-color: transparent;
	color: var(--primary-400);
	border: 2px solid var(--primary-400);
	font-size: 24px;
	font-weight: 400;
	width: 150px;
	height: 65px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* add-customer-form */
.add-customer-form .form-body {
	padding: 60px 120px !important;
}

.add-customer-form .form-body input,
.add-customer-form .form-body select {
	width: calc(100% + 50px);
	max-width: 100%;
}

.add-customer-form .banners-preview-container,
.add-customer-form .banners-preview-container .banner-preview {
	width: 200px;
	height: 250px;
	margin-top: 2px;
	background: var(--gray-100);
	border-color: var(--gray-100);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.add-customer-form .banners-preview-container .banner-preview img {
	border-radius: inherit;
	-webkit-border-radius: inherit;
	-moz-border-radius: inherit;
	-ms-border-radius: inherit;
	-o-border-radius: inherit;
}

.add-customer-form .banners-preview-container .image-icon {
	width: 40px;
	height: 40px;
	fill: var(--gray-500);
}

.add-customer-form .add-image-btn-bx {
	width: 200px;
}

.add-customer-form .add-image-btn-bx label {
	font-size: 19px;
	font-weight: 400;
}

.add-customer-form .add-image-btn-bx .add-image-btn {
	width: 90px;
	height: 50px;
	display: flex;
	background: var(--gray-100);
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	cursor: pointer;
}

.add-customer-form .add-image-btn-bx .add-image-btn svg {
	width: 25px;
	height: 25px;
}

/* Customer-data-page */
.add-form-wrapper.customer-data .form-footer button.close-btn {
	color: var(--primary-400);
	border-color: var(--primary-400);
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* user-cart-data */
.user-cart-data {
	padding: 35px 45px;
}

.user-cart-data-wrapper {
	width: 85%;
}

.user-cart-data .userData-container {
	padding: 0;
	background-color: var(--white-100);
	box-shadow: 0 0 1px 0px #dbdbdb;
	border: 1px solid #f4f2f2;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.user-cart-data .userData-container .container-title {
	background-color: #edededa1;
	padding: 16px 20px;
	font-size: 22px;
	font-weight: 500;
	border-radius: 6px 6px 0 0;
	-webkit-border-radius: 6px 6px 0 0;
	-moz-border-radius: 6px 6px 0 0;
	-ms-border-radius: 6px 6px 0 0;
	-o-border-radius: 6px 6px 0 0;
}

.user-cart-data .userData-container .container-title .product-count {
	font-size: 17px;
	font-weight: 500;
	color: var(--black-200);
}

.active-discount-btn button {
	display: none;
	background-color: var(--primary-500);
	color: var(--white-100);
	font-weight: 300;
	padding: 8px 16px;
	border-radius: 6px;
}

/* percent-menu */

.userData-container .ul-percent-menu {
	position: relative !important;
	width: 100%;
	text-align: right;
	border: 0;
	font-size: 20px;
	padding: 0;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	transform: translate(0px, 0px) !important;
	-webkit-transform: translate(0px, 0px) !important;
	-moz-transform: translate(0px, 0px) !important;
	-ms-transform: translate(0px, 0px) !important;
	-o-transform: translate(0px, 0px) !important;
}

.userData-container .percent-menu {
	background: #e8fbff;
	margin-bottom: 4px;
}

.userData-container .percent-input-wrapper {
	height: 56px;
	width: 100%;
	padding-right: 16px;
	background: #fff;
	border: 1px solid #67747b1a;
	display: flex;
	align-items: center;
	justify-content: center;
}

.userData-container .percent-input-wrapper input {
	all: unset;
	direction: ltr;
	text-align: right;
	height: 100%;
	padding-right: 16px;
	font: normal normal normal 16px/22px "Tajawal";
	letter-spacing: 0.16px;
	color: var(--black-800);
}

.userData-container .percent-input-wrapper input::placeholder {
	/* padding-right: 10px; */
	font: normal normal normal 16px/22px "Tajawal";
	letter-spacing: 0.16px;
	color: #adb5b9;
}

.userData-container .percent-input-wrapper .percent-sign {
	width: 98px;
	height: 100%;
	background: #67747b1a;
	display: flex;
	justify-content: center;
	align-items: center;
}

.userData-container .percent-menu li:hover {
	background: hsla(181, 52%, 60%, 0.986);
}

.user-cart-data .userData-container .container-body {
	padding: 16px 20px;
}

.user-cart-data .userData-container .header {
	background-color: #dafdfe2e;
	padding: 16px;
	font-weight: 500;
}

.user-cart-data .userData-container .products-details {
	padding: 14px 20px;
}

.user-cart-data .userData-container .products-details .product,
.user-cart-data .userData-container .products-details .count,
.user-cart-data .userData-container .products-details .price,
.user-cart-data .userData-container .products-details .total {
	font-size: 20px;
}

.user-info-row {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 18px;
}

.user-cart-data .userData-container .container-body .user-name {
	font-size: 24px;
	font-weight: 500;
	color: var(--primary-500);
	text-align: right;
}

.user-cart-data .userData-container .container-body .user-info span {
	color: var(--black-300);
	font-size: 19px;
}

.user-cart-data .userData-container .container-body .user-info svg {
	width: 24px;
	height: 24px;
}

.discount-radio-box {
	margin-right: -30px;
}

/*  */
.user-cart-data .userData-container .text-editor-icons {
	padding: 16px 20px;
	border: 2px solid var(--bs-gray-200);
	border-radius: 5px;
	margin: -3px;
	margin-bottom: -10px;

	z-index: 1;
}

.user-cart-data-wrapper .create-offer-row {
	display: flex;
	padding: 0;

	gap: 18px;
}

.user-cart-data-wrapper .create-offer-row .box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
}

.user-cart-data-wrapper .create-offer-row .user-name-box {
	width: calc(100% - 220px);
}

.user-cart-data-wrapper .create-offer-row .total-discount-box {
	width: 40%;
}

.user-cart-data-wrapper .create-offer-row .discount-date-box {
	width: 50%;
}

.user-cart-data-wrapper .create-offer-row .total-discount-box {
	align-content: center;
}

.user-cart-data-wrapper .create-offer-row .discount-date-box {
	align-content: end;
}

.user-cart-data-wrapper .create-offer-row .user-name-box input {
	width: 100%;
}

.user-cart-data-wrapper .create-offer-row .total-discount-box input,
.user-cart-data-wrapper .create-offer-row .discount-date-box input {
	width: 100%;
}

.user-cart-data-wrapper .create-offer-row .discount-date-box input {
	overflow: hidden;
}

.user-cart-data-wrapper
	.create-offer-row
	.discount-date-box
	.react-datepicker-wrapper
	input {
	padding-right: 75px !important;
	color: var(--black-800);
	font-size: 16px;
	font-weight: 400;
}

.user-cart-data-wrapper .create-offer-row .discount-date-box .date-icon {
	position: absolute;
	top: 31%;
	z-index: 1;
	right: 0px;
	width: 58px;
	height: 65px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-cart-data-wrapper
	.create-offer-row
	.discount-date-box
	.date-icon
	svg
	path {
	fill: var(--blue-800);
}

.user-cart-data-wrapper
	.create-offer-row
	.discount-date-box
	.react-datepicker-popper {
	transform: translate(-300px, 0px) !important;
	-webkit-transform: translate(-300px, 0px) !important;
	-moz-transform: translate(-300px, 0px) !important;
	-ms-transform: translate(-300px, 0px) !important;
	-o-transform: translate(-300px, 0px) !important;
}

.user-cart-data-wrapper .create-offer-row .box label {
	color: var(--black-300);
	padding-left: 10px;
}

.user-cart-data-wrapper .create-offer-row .box input {
	background-color: var(--second-200);
	padding: 18px;
	height: 65px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.user-cart-data .send-offer-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-cart-data .send-offer-btn button {
	background-color: var(--primary-500);
	color: var(--white-100);
	width: 100%;
	height: 65px;
	font-size: 24px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.user-cart-data .send-offer-btn button svg {
	fill: var(--white-100);
	width: 28px;
	height: 28px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* add-user-page */

.add-user-form .form-body label {
	font-weight: 400;
	color: var(--black-400);
}

.add-user-form .form-body input {
	padding: 8px 50px;
}

.add-user-form .form-body input[name="phonenumber"] {
	padding-left: 94px;
}

.add-user-form .form-body .Country_Key {
	position: absolute;
	left: 65px;
	top: 13px;
	direction: ltr;
}

.add-user-form .form-body .input-icons {
	position: absolute;
	z-index: 1;
	top: 10px;
	right: 30px;
}

.add-user-form .form-body .input-icons.password-icon {
	right: 95%;
	cursor: pointer;
}

.add-user-form .form-body .input-icons svg,
.add-user-form .form-body .input-icons svg path {
	fill: var(--gray-300);
}

.add-user-form .form-body .upload-personal-image {
	background-color: #fff !important;
	font-size: 18px;
	width: 100%;
	height: 60px;
	padding: 20px;
	white-space: normal;
	border: 1px solid var(--gray-100) !important;

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.add-user-form .form-body .upload-personal-image .helper {
	color: var(--gray-300);
}

.add-user-form .form-body .upload-personal-image span {
	position: absolute;
	left: 0;
	top: 0;
	width: 200px;
	height: 100%;
	font-size: 20px;
	cursor: pointer;
	display: flex;
	background-color: var(--second-500);
	color: var(--white-100);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	align-items: center;
	justify-content: center;
}

.add-user-form .form-footer button.save-btn {
	background-color: var(--primary-500);
}

.add-user-form .form-footer button.close-btn {
	border-color: var(--primary-500);
	color: var(--primary-500);
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* job-title-page */
.job-title-page .form-title {
	background-color: transparent;
	padding: 30px 50px 0 0px;
}

.job-title-page .header-row,
.job-title-page .job-titles-wrapper {
	width: 90%;
}

.job-title-page .header-row .create-job-title-btn button {
	background-color: var(--second-500);
	color: var(--white-100);
	width: 220px;
	height: 60px;
	line-height: 0.8;
	font-size: 24px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	justify-content: center;
	align-items: center;
}

.job-title-page .job-titles-wrapper .job-box {
	background-color: var(--white-100);

	padding: 10px 20px;
}

.job-title-page .job-titles-wrapper .job-box .job-name {
	font-size: 22px;
	font-weight: 500;
}

.job-title-page .job-titles-wrapper {
	padding: 60px 20px;
}

.job-title-page .job-titles-wrapper .job-box .job-btn-group button {
	width: 130px;
	height: 50px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.job-title-page .job-titles-wrapper .job-box .job-btn-group .edit-btn {
	background: #ebf7ff;
	color: #1da5fb;
}

.job-title-page .job-titles-wrapper .job-box .job-btn-group .edit-btn svg path {
	stroke: #1da5fb;
}

.job-title-page .job-titles-wrapper .job-box .job-btn-group .delete-btn {
	background-color: #ffd7d738;
	color: var(--red-500);
}

.job-title-page
	.job-titles-wrapper
	.job-box
	.job-btn-group
	.delete-btn
	svg
	path {
	fill: var(--red-500);
}

.job-title-page .close-btn button {
	background: transparent;
	border: 2px solid var(--primary-500);
	color: var(--primary-500);
	width: 150px;
	height: 65px;
	font-size: 26px;
	font-weight: 400;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

/* create role page */

.create-role-wrapper {
	padding-right: 75px;
}

.create-role-wrapper .search-wrapper,
.create-role-wrapper .create-role-title {
	padding-right: 120px;
}

.create-role-wrapper label,
.create-role-wrapper .create-role-title span {
	font-size: 22px;
	color: var(--black-500);
}

.create-role-wrapper .star-icon path {
	fill: red;
}

.create-role-wrapper input {
	width: 60%;
	height: 65px;
	border: 2px solid var(--gray-400) !important;
	padding: 20px 30px;
	border-radius: 8px;
	font-size: 16px;
	font-weight: 500;
}

.create-role-wrapper input::placeholder {
	color: var(--gray-300);
}

.create-role-wrapper .create-role-tables {
	background: #fafafa;
	width: 90%;
	margin: 0 auto;
	padding: 20px 40px;
	border-radius: 10px;
	box-shadow: 0 0 20px 1px #f4f2f2;
}

.create-role .MuiToolbar-root {
	justify-content: flex-start !important;
	/* margin-right: -16px !important; */
}

.create-role .MuiToolbar-root .MuiFormControlLabel-root {
	margin-right: 0 !important;
}

.create-role .MuiToolbar-root .MuiFormControlLabel-root span {
	font-weight: 500;
}

.create-role-wrapper .MuiTableCell-root,
.edit-job-wrapper .MuiTableCell-root {
	padding: 10px 20px !important;
}

.create-role-wrapper .MuiFormControlLabel-root,
.edit-job-wrapper .MuiFormControlLabel-root {
	margin: unset !important;
	padding: unset !important;
	border: unset !important;
}

.create-role-wrapper .MuiTableCell-root,
.create-role .MuiToolbar-root .MuiFormControlLabel-root span {
	font-size: 22px;
}

.create-role-wrapper .create-role-tables .role-wrapper {
	background-color: var(--white-100);
}

.create-role .btn-wrapper button {
	background-color: transparent;
	border: 2px solid var(--primary-500);
	color: var(--primary-500);
	width: 100%;
	height: 65px;
	font-size: 24px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* Edit job title Page */
.edit-job-title-modal {
	background-color: #fff !important;
}

.edit-job-title {
	width: 100%;
	margin: 30px auto;
	border-radius: 14px;
	-webkit-border-radius: 14px;
	-moz-border-radius: 14px;
	-ms-border-radius: 14px;
	-o-border-radius: 14px;
}

.edit-job-title .page-wrapper {
	padding: 30px 0;
}

.edit-job-title .page-header {
	background-color: var(--second-500);
	padding: 40px 40px 40px 30px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.edit-job-title .page-header span {
	font-size: 32px;
	font-weight: 500;
	color: var(--white-100);
}

.edit-job-title .page-header .search .input-icon {
	position: absolute;
	z-index: 1;
	top: 14px;
	right: 30px;
}

.edit-job-title .page-header .search .input-icon svg {
	width: 26px;
	height: 26px;
}

.edit-job-title .page-header .search .input-icon svg path {
	fill: var(--gray-300);
}

.edit-job-title .page-header .search .save-btn,
.edit-job-title .page-header .search input {
	height: 60px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.edit-job-title .page-header .search input {
	background: var(--white-100);
	padding: 18px 50px 18px 18px;
	font-size: 18px;
}

.edit-job-title .page-header .search input::placeholder {
	color: var(--gray-300);
}

.edit-job-title .page-header .search .save-btn {
	background-color: var(--primary-500);
	color: var(--white-100);
	font-size: 26px;
	font-weight: 400;
}

.edit-job-wrapper .MuiToolbar-root {
	background-color: transparent !important;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */
/* user-details-page */
.edit-user-body {
	padding-bottom: 0 !important;
}

.user-details-title {
	background-color: #ebebeb;
	padding: 40px 40px 0px 0px;
}

.user-details-title h5 {
	font-size: 24px;
	letter-spacing: 0.24px;
	color: #011723;
}

.user-details-title .breadcrumb {
	font-size: 18px;
	letter-spacing: 0.18px;
	color: #011723;
}

.user-details-body {
	padding: 70px 90px 300px 0;
}

.user-details-body .banners-preview,
.user-details-body img {
	width: 180px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.user-details-body .user-info .user-name {
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 0.2px;
	color: #011723;
}

.user-details-body .contact-info span {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.18px;
	color: #67747b;
}

.user-details-body .job-title {
	background: #1dbbbe;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #eff9ff;
	width: 100%;
	height: 60px;
	font-size: 20px;
	font-weight: 500;
	box-shadow: 0px 3px 6px #0000000f;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.user-details-footer {
	background-color: #ebebeb;
	padding: 55px;
}

.user-details-footer button {
	width: 100%;
	height: 56px;
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0.24px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.user-details-footer button.edit-btn {
	background-color: var(--primary-500);
	color: #fff;
}

.user-details-footer button.close-btn {
	color: var(--primary-500);
	border: 1px solid var(--primary-500);
}

/* Edit user details page */

.user-details-body label {
	font-size: 18px;
	letter-spacing: 0.18px;
	color: #011723;
}

.user-details-body .password-type {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	background-color: #ebebeb;
	border-radius: 8px;
	width: 100%;
	height: 60px;
	letter-spacing: 0.14px;
	font-size: 18px;
	padding: 20px;
}

.user-details-body .password-type input {
	width: 100%;
	height: 100%;
	background-color: transparent;
	padding: 0;
	border-radius: 0;
}

.user-details-body .password-type svg {
	fill: var(--gray-300);
	cursor: pointer;
}

.user-details-body input,
.user-details-body .upload-user-image {
	background-color: #ebebeb;
	border-radius: 8px;
	width: 100%;
	height: 60px;
	letter-spacing: 0.14px;
	font-size: 18px;
	padding: 20px;
}

.user-details-body .password-hint {
	font-size: 16px;
	letter-spacing: 0px;
	color: #adb5b9;
}

.user-details-body input::placeholder {
	color: #adb5b9;
}

.user-details-body .phone-input {
	position: relative;
	padding-right: 55px;
	padding-left: 58px;
}

.user-details-body .Country_Key {
	direction: ltr;
	position: absolute;
	left: 32px;
	top: 51px;
	z-index: 6;
	font-size: 18px;
}

/* Upload user image input */
.user-details-body .upload-user-image {
	cursor: pointer;
}

.user-details-body .upload-user-image .upload-icon {
	position: relative;
	top: 0;
	left: -19px;
	background-color: var(--primary-500);
	border-radius: 4px;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.user-details-body .upload-user-image svg {
	color: #fff;
}

/* Phone number input */
.user-details-body .phone-input::placeholder {
	color: #011723;
}

.user-details-body .input-icon {
	position: absolute;
	right: 30px;
	top: 45px;
}

.user-details-body .input-icon svg path {
	fill: #1dbbbe;
}

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------------------------------------------------------------- */

/* souqOtlobha-page */

.souqOtlobha-page .total-products {
	position: relative;
	display: flex;
	flex-direction: row;
	height: 60px;
	width: 90%;
	color: #ffff;
}

.souqOtlobha-page .total-products span {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 13px;
}

.souqOtlobha-page .total-products .shipping-icon {
	position: relative;
	min-width: 60px;
	background-color: #e3c504;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.souqOtlobha-page .total-products .shipping-icon .number {
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #ffdd00;
	top: -12px;
	right: -12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #494949;
}

.souqOtlobha-page .total-products .text {
	flex: 1;
	background-color: #ffdd00;
	width: 430px;
	color: #494949;
}

.souqOtlobha-page .cart-menu-layout {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: #00000000;
	z-index: 99;
	height: 100vh;
	overflow: hidden;
}

.souqOtlobha-page .total-products .cart-menu {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	width: 100%;
	background-color: #ffffff;
	color: #3d464d;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	text-align: center;
	z-index: 999;
}

.souqOtlobha-page .total-products .cart-menu .products-list {
	padding: 20px 16px;
	display: block;
}

.souqOtlobha-page .total-products .cart-menu .products-list .product {
	display: flex;
}

.souqOtlobha-page .total-products .cart-menu .products-list .product .image {
	flex-shrink: 0;
	width: 70px;
}

.souqOtlobha-page .total-products .cart-menu .products-list .product .image a {
	display: block;
	padding-bottom: 100%;
	position: relative;
	width: 100%;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.image
	a
	img {
	display: block;
	height: 100%;
	object-fit: scale-down;
	position: absolute;
	width: 100%;
}

.souqOtlobha-page .total-products .cart-menu .products-list .product .info {
	padding-left: 10px;
	padding-right: 16px;
	flex-grow: 1;
	margin: 0;
	text-align: justify;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.name {
	font-size: 15px;
	line-height: 18px;
	margin-top: -2px;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.name
	a {
	color: inherit;
	transition: 0.15s;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.name
	a:hover {
	color: #1a66ff;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.name
	.product-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.2rem;
	flex-wrap: wrap;
	white-space: nowrap;
	list-style: none;
	margin: 3px 0 0;
	padding: 0;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.name
	.product-options
	li {
	font-size: 13px;
	line-height: 16px;
	color: #999;
	white-space: nowrap;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.mate {
	font-size: 13px;
	margin-top: 5px;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.mate
	span {
	padding: 0;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.info
	.mate
	.price {
	font-size: 14px;
	font-weight: 700;
}

.souqOtlobha-page .total-products .cart-menu .products-list .product .remove {
	flex-shrink: 0;
	margin-top: -6px;
	margin-left: -6px;
	font-size: 0.875rem;
	font-weight: 500;
	width: calc(1.875rem + 2px);
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	fill: #ccc;
	background: transparent;
	border-color: transparent;
	color: #fff;
}

.souqOtlobha-page
	.total-products
	.cart-menu
	.products-list
	.product
	.remove:hover {
	fill: #fff;
	background-color: var(--second-500);
	border-color: var(--second-500);
	color: #fff;
}

.souqOtlobha-page .total-products .cart-menu .totals {
	border-top: 1px solid #ebebeb;
	line-height: 24px;
	padding: 16px 20px 0;
}

.souqOtlobha-page .total-products .cart-menu .totals table {
	width: 100%;
	border-collapse: collapse;
}

.souqOtlobha-page .total-products .cart-menu .totals table tr {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.souqOtlobha-page .total-products .cart-menu .totals table th {
	padding-left: 16px;
}

.souqOtlobha-page .total-products .cart-menu .totals table th .tax-text {
	color: #b3b3b3;
	font-size: 0.7rem;
	font-weight: 500;
	margin: 0 0 14px;
	padding: 0;
}

.souqOtlobha-page .total-products .cart-menu .totals table td {
	text-align: left;
}

.souqOtlobha-page .total-products .cart-menu .buttons {
	display: flex;
	margin-top: 4px;
	padding: 20px;
	gap: 8px;
}

.souqOtlobha-page .total-products .cart-menu .buttons .btn {
	flex: 1;
	background-color: var(--second-500);
	border-color: var(--second-500);
	color: #ffffff;
	border-radius: 2px;
	font-size: 1rem;
	font-weight: 500;
	height: calc(2.25rem + 2px);
	line-height: 1.5;
	padding: 0.375rem 1.25rem;
	transition: all 0.1s ease-in-out;
}

.souqOtlobha-page .total-products .cart-menu .buttons .btn:hover {
	background-color: var(--primary-500);
	border-color: var(--primary-500);
}

.souqOtlobha-page .total-products .cart-menu .empty {
	font-size: 0.95rem;
	font-weight: 600;
}

.souqOtlobha-page .btn-group button {
	height: 56px;
	color: white;
	font-size: 20px;
	font-weight: 500;
	padding: 16px;
}

.souqOtlobha-page .btn-group button:first-child {
	width: 140px;
	background-color: #1dbbbe00;
	color: var(--second-500);
	border: 1px solid var(--second-500);
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.souqOtlobha-page .btn-group button:first-child svg {
	width: 20px;
	height: 20px;
	margin-left: 8px;
}

.souqOtlobha-page .btn-group button:first-child svg path {
	fill: var(--second-500);
}

.souqOtlobha-page .btn-group button:last-child {
	width: 290px;
	background-color: #b6be34;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.MuiList-root {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

/* Filter section */
.apply-btn {
	background-color: var(--second-500);
	color: white;
	width: 100%;
	height: 56px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

/* product-box */
.product-box {
	background-color: white;
	width: 1144px;
	height: 174px;
	border: 1px solid #ececec;
	padding: 10px 18px 10px 60px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.product-box img {
	width: 160px;
	height: 144px;
	object-fit: contain;
	object-position: center;
	aspect-ratio: 1/1;
	mix-blend-mode: multiply;
}

.product-box .star-icon-bg {
	background: #ebfcf1;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.product-box .star-icon {
	width: 20px;
	height: 20px;
	color: #3ae374;
}

.product-box .product-name {
	background: #ffffff;
	width: 376px;
	height: 48px;
	border: 1px solid #ececec;
}

.product-box .product-name span {
	color: #011723;
	font-size: 18px;
	font-weight: 400;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.product-box .product-category {
	width: 180px;
	height: 48px;
	background: #ffeeee;
	border: 1px solid #ececec;
	border-radius: 2px;
	color: #02466a;
	font-size: 20px;
	font-weight: 400;
}

.product-box .product-price .input {
	width: 180px;
	height: 48px;
	background: #ffffff;
	border: 1px solid #ececec;
}

.ProductRefund-wrapper .product-price .label,
.ProductRefund-wrapper .product-price .count,
.product-box .product-price .label,
.product-box .product-price .count {
	font-size: 16px;
	font-weight: 400;
}

.ProductRefund-wrapper .product-price .currency,
.product-box .product-price .currency {
	width: 54px;
	height: 48px;
	font-size: 18px;
	background: #fafafa;
	border: 1px solid #ececec;
}

.ProductRefund-wrapper .product-price .price-icon,
.product-box .product-price .price-icon {
	width: calc(100% - 54px);
}

.ProductRefund-wrapper .product-price .price-icon svg,
.product-box .product-price .price-icon svg {
	fill: #adb5b9;
}

.ProductRefund-wrapper .product-price .price,
.product-box .product-price .price {
	font-size: 18px;
	font-weight: 400px;
	color: #011723;
}

.recovery-button button {
	background-color: var(--second-500);
	width: 180px;
	height: 48px;
	color: #ffffff;
	font: 20px;
	font-weight: 500;
	border-radius: 2px;
}

.souqOtlobha-page .pagination-box {
	width: 1144px;
	max-width: 1144px;
}

/* cart-page */
.cart-page h3 {
	margin-bottom: 2rem;
}

.cart-page .cart-table {
	border-collapse: initial;
	border-spacing: 0;
	width: 100%;
}

.cart-page .cart-table thead {
	background-color: #f7f7f7;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
}

.cart-page .cart-table thead tr th {
	padding: 12px 18px;
	font-weight: 500;
	border-top-right-radius: 2.5px;
	border-top-left-radius: 2.5px;
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
}

.cart-page .cart-table thead tr th:first-child {
	border-right: 1px solid #ebebeb;
}

.cart-page .cart-table thead tr th:last-child {
	border-left: 1px solid #ebebeb;
}

.cart-page .cart-table tbody tr td {
	border-bottom: 1px solid #ebebeb;
	padding: 12px 18px;
	text-align: center;
}

.cart-page .cart-table tbody tr .name {
	min-width: 300px;
	max-width: 500px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: justify;
}

.cart-page .cart-table tbody tr .name a {
	font-size: 1rem;
	color: #000000;
	transition: all 0.2s ease-in-out;
}

.cart-page .cart-table tbody tr .name a:hover {
	color: #1675e0;
}

.cart-page .cart-table tbody tr .qty {
	width: 90px;
	height: calc(1.5em + 0.75rem + 2px);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #e9ecef;
	color: #495057;
	border: 1px solid #ced4da;
	border-radius: 2px;
	margin: auto;
}

.cart-page .cart-table tbody tr .qty input {
	width: 100%;
	text-align: center;
	font-size: 1rem;
	font-weight: 400;
	background-color: transparent;
	outline: none;
	border: none;
	direction: ltr;
}

.cart-page .cart-table tbody tr .qty button {
	min-width: 24px;
	height: 100%;
	background-color: transparent;
	opacity: 0.3;
	font-size: 1.2rem;
	font-weight: 800;
	transition: opacity 0.18s;
	user-select: none;
	cursor: pointer;
	border: none;
}

.cart-page .cart-table tbody tr td:first-child {
	border-right: 1px solid #ebebeb;
}

.cart-page .cart-table tbody tr td:last-child {
	border-left: 1px solid #ebebeb;
}

.cart-page .cart-table tbody tr td .image {
	width: 80px;
}

.cart-page .cart-table tbody tr td .image a {
	display: block;
	padding-bottom: 100%;
	position: relative;
	width: 100%;
}

.cart-page .cart-table tbody tr td .image a img {
	display: block;
	height: 100%;
	object-fit: scale-down;
	position: absolute;
	width: 100%;
}

.cart-page .cart-table tbody tr td .remove,
.render-checkout-info .remove {
	flex-shrink: 0;
	margin-top: -6px;
	margin-left: -6px;
	font-size: 0.875rem;
	font-weight: 500;
	width: calc(1.875rem + 2px);
	height: calc(1.875rem + 2px);
	line-height: 1.25;
	fill: #ccc;
	background: transparent;
	border-color: transparent;
	color: #fff;
}

.cart-page .cart-table tbody tr td .remove:hover,
.render-checkout-info .remove:hover {
	fill: #fff;
	background-color: var(--second-500);
	border-color: var(--second-500);
	color: #fff;
}

.cart-page .actions {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-top: 1.75rem;
	gap: 10px;
}

.cart-page .actions .buttons {
	display: flex;
	align-items: center;
	flex: 1 1;

	justify-content: flex-end;

	gap: 0.5rem;
}

.cart-page .actions .buttons button {
	width: 138px;
	height: 40px;
}

.cart-page .actions .buttons .discount-code-input {
	width: 100%;
	height: 40px !important;
	border-radius: 2px;
	padding: 10px 14px;
	border: 1px solid #dddd !important;
	font-size: 16px;
	font-weight: 500;
}

.cart-page .actions .buttons a {
	border-radius: 2px;
	font-size: 1rem;
	font-weight: 500;
	color: #000000;
	height: calc(2.25rem + 2px);
	line-height: 1.8;
	padding: 0.375rem 1.25rem;
	transition: all 0.1s ease-in-out;
	cursor: pointer;
}

.cart-page .actions .buttons .update {
	background-color: var(--second-500);
	color: #ffffff;
	border-radius: 2px;
	font-size: 1rem;
	font-weight: 500;
	height: calc(2.25rem + 2px);
	line-height: 1.5;
	padding: 0.375rem 1.25rem;
	transition: all 0.1s ease-in-out;
}

.cart-page .actions .buttons .update:disabled {
	opacity: 0.65;
}

.cart-page .card {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
}

.cart-page .card .card-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 2rem;
}

.cart-page .card .card-body .title {
	margin-bottom: 1.75rem;
	font-size: 28px;
	font-weight: 700;
	line-height: 1.2;
}

.cart-page .card .card-body table {
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 40px;
	width: 100%;
	border-collapse: collapse;
}

.cart-page .card .card-body table th {
	font-weight: 700;
}

.cart-page .card .card-body table td,
.cart-page .card .card-body table th {
	padding: 6px 0;
	vertical-align: top;
}

.cart-page .card .card-body table tr > :first-child {
	padding-left: 20px;
}

.cart-page .card .card-body table thead tr:last-child > * {
	padding-bottom: 10px;
}

.cart-page .card .card-body table tfoot {
	font-size: 24px;
}

.cart-page .card .card-body table tr > :last-child {
	text-align: left;
}

.cart-page .card .card-body table tbody tr:last-child > * {
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 20px;
}

.cart-page .card .card-body table tbody tr:first-child > * {
	border-top: 1px solid #ebebeb;
	padding-top: 20px;
}

.cart-page .card .card-body table tfoot th {
	font-weight: 500;
}

.cart-page .card .card-body table tfoot tr:first-child > * {
	padding-top: 20px;
}

.cart-page .card .card-body .checkout-btn {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.375rem;
	font-weight: 500;
	height: calc(3.625rem + 2px);
	line-height: 1;
	padding: 1.125rem 3rem;
	border-radius: 2px;
	background-color: var(--second-500);
	color: #ffffff;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
}

.cart-page .card .card-body .checkout-btn:hover {
	background-color: var(--primary-500);
}

.cart-page .empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
}

.cart-page .empty span {
	font-size: 1.2rem;
	font-weight: 700;
	color: #000000;
}

.cart-page .empty a {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.375rem;
	font-weight: 500;
	height: calc(3.625rem + 2px);
	line-height: 1;
	padding: 1.125rem 3rem;
	border-radius: 2px;
	background-color: var(--second-500);
	color: #ffffff;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
}

.cart-page .empty a:hover {
	background-color: var(--primary-500);
}

.cart-page .cart-table .options {
	width: fit-content;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.2rem;
	flex-wrap: wrap;
	white-space: nowrap;
	list-style: none;
	margin: 4px 0 0;
	border: 2px solid var(--second-500);
	border-radius: 3px;
	padding: 4px 8px;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.cart-page .cart-table .options li {
	font-size: 14px;
	color: #999;
	line-height: 19px;
}

.cart-page .cart-table .options:hover {
	border: 2px solid var(--second-600);
}

/* checkout-page */
.checkout-page h3 {
	margin-bottom: 2rem;
}

.checkout-page .block {
	margin-bottom: 46px;
}

.checkout-page .block .card {
	border: 2px solid #f0f0f0;
	border-radius: 2px;
	word-wrap: break-word;
	background-clip: initial;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	min-width: 0;
	position: relative;
}

.checkout-page .block .card .card-body {
	padding: 2rem;
	flex: 1 1 auto;
	min-height: 1px;
}

.checkout-page .block .card .card-divider {
	background-color: #f0f0f0;
	height: 2px;
}

.checkout-page .block .card .card-body .card-title {
	margin-bottom: 1.75rem;
	font-size: 28px;
	font-weight: 700;
}

.checkout-page .block .card .card-body .form-group {
	margin-bottom: 1.5rem;
}

.checkout-page .block .card .card-body .form-group label {
	color: #3d464d;
	font-family: Tajawal, "sans-serif";
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	display: inline-block;
	margin-bottom: 0.25rem;
}

.checkout-page .block .card .card-body .form-group .form-control {
	border: 1px solid #ced4da !important;
	border-radius: 2px;
	line-height: 1.25;
}

.checkout-page .block .card .card-body .form-group input[type="text"],
.checkout-page .block .card .card-body .form-group select {
	height: 38px;
}

.checkout-page .block .card .card-body .form-group .required {
	font-size: 1.2rem;
	font-weight: 500;
	color: #dc3545;
}

.checkout-page .block .card .card-body .form-group .form-control:focus {
	background-color: #fff;
	border-color: #80bdff !important;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
	color: #495057;
	outline: 0 !important;
}

.checkout-page .block .card .card-body .form-group .form-check {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	padding-left: 1.25rem;
	padding-right: 1.5rem;
	position: relative;
}

.checkout-page .block .card .card-body .form-group .form-check .input-check {
	display: inline-block;
	margin-right: -1.5rem;
	margin-top: 0.1875rem;
	margin-left: -1.25rem;
	position: absolute;
}

.checkout-page
	.block
	.card
	.card-body
	.form-group
	.form-check
	.input-check
	.body {
	display: block;
	height: 16px;
	position: relative;
	width: 16px;
}

.checkout-page
	.block
	.card
	.card-body
	.form-group
	.form-check
	.input-check
	.body
	input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
	cursor: inherit;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.checkout-page
	.block
	.card
	.card-body
	.form-group
	.form-check
	.input-check
	.body
	.input-check-box {
	background: #ffffff;
	border-radius: 1.2px;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
	display: block;
	height: 16px;
	transition: background 0.15s, box-shadow 0.15s;
	width: 16px;
}

.checkout-page
	.block
	.card
	.card-body
	.form-group
	.form-check
	.input-check
	.body
	.input-check-icon {
	fill: #fff;
	left: 4px;
	pointer-events: none;
	position: absolute;
	top: 5px;
	transform: scale(0);
	transition: fill 0.15s, visibility 0s 0.15s, -webkit-transform 0.15s;
	transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
	transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s,
		-webkit-transform 0.15s;
}

.checkout-page
	.block
	.card
	.card-body
	.form-group
	.form-check
	.input-check
	.body
	input:checked
	~ .input-check-box {
	background: var(--second-500);
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.checkout-page
	.block
	.card
	.card-body
	.form-group
	.form-check
	.input-check
	.body
	input:checked
	~ .input-check-icon {
	fill: #fff;
	transform: scale(1);
	transition-delay: 0s, 0s, 0s;
}

.checkout-page .block .card .card-body .form-group .form-check label {
	margin-bottom: 0;
}

.checkout-page .block .card .card-body .checkout-totals {
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 20px;
	width: 100%;
}

.checkout-page .block .card .card-body .checkout-totals tr > :first-child {
	padding-left: 20px;
	text-align: justify;
}

.checkout-page .block .card .card-body .checkout-totals tr > :last-child {
	text-align: left;
}

.checkout-page .block .card .card-body .checkout-totals th {
	font-weight: 700;
	padding: 4px 0;
}

.checkout-page .block .card .card-body .checkout-totals td {
	padding: 4px 0;
}

.checkout-page
	.block
	.card
	.card-body
	.checkout-totals
	thead
	tr:last-child
	> * {
	padding-bottom: 10px;
}

.checkout-page
	.block
	.card
	.card-body
	.checkout-totals
	.products
	tr:first-child
	> * {
	border-top: 1px solid #ebebeb;
	padding-top: 20px;
}

.checkout-page
	.block
	.card
	.card-body
	.checkout-totals
	.products
	tr:last-child
	> * {
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 20px;
}

.checkout-page
	.block
	.card
	.card-body
	.checkout-totals
	.subtotals
	tr:first-child
	> * {
	padding-top: 20px;
}

.checkout-page .block .card .card-body .checkout-totals tfoot {
	font-size: 24px;
}

.checkout-page
	.block
	.card
	.card-body
	.checkout-totals
	tfoot
	tr:first-child
	> * {
	padding-top: 20px;
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 20px;
}

.checkout-page .block .card .card-body .checkout-totals tfoot th {
	font-weight: 500;
}

.checkout-page .block .card .card-body .checkout-totals tfoot td {
	white-space: nowrap;
}

.checkout-page
	.block
	.card
	.card-body
	.checkout-totals
	.products
	.product-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.2rem;
	flex-wrap: wrap;
	white-space: nowrap;
	list-style: none;
	margin: 3px 0 0;
	padding: 0;
}
.checkout-page
	.block
	.card
	.card-body
	.checkout-totals
	.products
	.product-options
	li {
	font-size: 13px;
	line-height: 16px;
	color: #999;
	white-space: nowrap;
}

.checkout-page .block .card .card-body .payment-methods {
	margin-bottom: 30px;
}

.checkout-page .block .card .card-body .payment-methods h6 {
	font-size: 16px;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 0.5rem;
}

.checkout-page .block .card .card-body .payment-methods .list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.checkout-page .block .card .card-body .payment-methods .list .item {
	background: transparent;
	border: 1px solid #e5e5e5;
	border-radius: 2px;
}

.checkout-page .block .card .card-body .payment-methods .list .item .header {
	align-items: center;
	cursor: pointer;
	display: flex;
	font-size: 15px;
	font-weight: 500;
	justify-content: space-between;
	letter-spacing: 0.01em;
	margin-bottom: 0;
	padding: 8px 14px;
}

.checkout-page
	.block
	.card
	.card-body
	.payment-methods
	.list
	.item
	.header
	.input-radio {
	display: inline-block;
	margin-left: 10px;
}

.checkout-page
	.block
	.card
	.card-body
	.payment-methods
	.list
	.item
	.header
	.input-radio
	.body {
	display: block;
	height: 16px;
	position: relative;
	width: 16px;
}

.checkout-page
	.block
	.card
	.card-body
	.payment-methods
	.list
	.item
	.header
	.input-radio
	.body
	.input {
	cursor: inherit;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	box-sizing: border-box;
	padding: 0;
}

.checkout-page
	.block
	.card
	.card-body
	.payment-methods
	.list
	.item
	.header
	.input-radio
	.body
	.input-radio-circle {
	background: #ffffff;
	border-radius: 8px;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.17);
	display: block;
	height: 16px;
	transition: background 0.2s, box-shadow 0.2s;
	width: 16px;
}

.checkout-page
	.block
	.card
	.card-body
	.payment-methods
	.list
	.item
	.header
	.input-radio
	.body
	.input-radio-circle:after {
	background: #ffffff;
	border-radius: 3px;
	content: "";
	display: block;
	height: 6px;
	left: 5px;
	position: absolute;
	top: 5px;
	transform: scale(0);
	transition: background 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s;
	transition: background 0.2s, transform 0.2s, visibility 0s 0.2s;
	transition: background 0.2s, transform 0.2s, visibility 0s 0.2s,
		-webkit-transform 0.2s;
	visibility: hidden;
	width: 6px;
}

.checkout-page
	.block
	.card
	.card-body
	.payment-methods
	.list
	.item
	.header
	.input-radio
	.body
	.input:checked
	~ .input-radio-circle {
	background: var(--second-500);
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.checkout-page
	.block
	.card
	.card-body
	.payment-methods
	.list
	.item
	.header
	.input-radio
	.body
	.input:checked
	~ .input-radio-circle:after {
	background: #ffffff;
	transform: scale(1);
	transition-delay: 0s, 0s, 0s;
	visibility: visible;
}

.checkout-page .block .card .card-body .checkout-btn {
	display: block;
	width: 100%;
	background: var(--second-500);
	color: #ffffff;
	font-size: 1.375rem;
	font-weight: 500;
	height: calc(3.625rem + 2px);
	line-height: 1;
	padding: 1.125rem 3rem;
	border-radius: 2px;
	transition: all 0.1s ease-in-out;
	cursor: pointer;
}

.checkout-page .block .card .card-body .checkout-btn:hover {
	background-color: var(--primary-500);
}
.checkout-page .block .card .card-body .checkout-btn:disabled {
	background-color: #ddd;
	cursor: not-allowed;
}

.checkout-page .empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
}

.checkout-page .empty span {
	font-size: 1.2rem;
	font-weight: 700;
	color: #000000;
}

.checkout-page .empty a {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.375rem;
	font-weight: 500;
	height: calc(3.625rem + 2px);
	line-height: 1;
	padding: 1.125rem 3rem;
	border-radius: 2px;
	background-color: var(--second-500);
	color: #ffffff;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
}

.checkout-page .empty a:hover {
	background-color: var(--primary-500);
}

.checkout-page .apply-coupon {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.checkout-page .apply-coupon .coupon {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 0.2rem;
	cursor: pointer;
	margin-bottom: 0.6rem;
}

.checkout-page .apply-coupon .coupon h6 {
	font-size: 1rem;
	font-weight: 600;
	color: #db190b;
	margin: 0;
}

.checkout-page .apply-coupon .coupon svg {
	width: 18px;
	height: 18px;
}

.checkout-page .apply-coupon .coupon-wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}

.checkout-page .apply-coupon .coupon-wrapper .error {
	font-size: 0.8rem;
	font-weight: 600;
	color: #d51010;
}

.checkout-page .apply-coupon .coupon-wrapper .coupon-form {
	height: 42px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px;
	border: 1px solid var(--second-500);
	border-radius: 5px;
}

.checkout-page .apply-coupon .coupon-wrapper .coupon-form input {
	height: 100% !important;
	font-weight: 600;
	padding: 0 5px !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.checkout-page .apply-coupon .coupon-wrapper .coupon-form input::placeholder {
	font-weight: 500;
}

.checkout-page .apply-coupon .coupon-wrapper .coupon-form button {
	height: 100%;
	background-color: var(--second-500);
	font-size: 0.9rem;
	padding: 2px 10px;
	border-radius: 4px;
	border: none;
	outline: none;
	transition: all 0.3s ease-in-out;
}

.checkout-page .apply-coupon .coupon-wrapper .coupon-form button:hover {
	background-color: var(--second-600);
}

.checkout-page .apply-coupon .coupon-wrapper .coupon-form button:disabled {
	opacity: 0.3;
}

/* ----------------------------------------------------------------- */

/* ProductRefund page */
.ProductRefund {
	overflow: auto;
}

.ProductRefund-wrapper .container-row {
	justify-content: space-between;
	padding-left: 80px;
	margin-bottom: 60px;
}

.ProductRefund-wrapper .product-title {
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 0px;
	color: #011723;
	white-space: normal;
}

.product-images {
	display: flex;
	gap: 30px;
}

.product-images .main-image {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 2px #ededed;
	padding: 20px;
}

.import-product-main-image {
	max-width: 100%;
	max-height: 100%;
	box-shadow: inset 0 0 0 2px #ededed;
	padding: 20px;
}

.product-images .main-image img,
.product-images .main-image video,
.import-product-main-image .wrapper img {
	width: 254px;
	height: 230px;
	max-width: 254px;
	max-height: 100%;
	object-fit: contain;
	object-position: center;
	mix-blend-mode: multiply;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.import-product-main-image .wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.import-product-main-image .wrapper video {
	width: 100%;
	height: 230px;
	max-height: 100%;
}

.product-images .another-image {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 10px;
}

.product-images .another-image div {
	width: 70px;
	height: 70px;
}

.import-product-multi-images div {
	width: 120px;
	height: 120px;
}

.product-images .another-image div,
.import-product-multi-images div {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	box-shadow: inset 0 0 0 2px #ededed;
}

.product-images .another-image div:hover,
.product-images .another-image div.active,
.import-product-multi-images div:hover,
.import-product-multi-images div.active {
	box-shadow: inset 0 0 0 2px var(--second-500);
}

.product-images .another-image div img,
.product-images .another-image div video,
.import-product-multi-images div img,
.import-product-multi-images div video {
	object-fit: contain;
	mix-blend-mode: multiply;
	aspect-ratio: 1/1;
}

.product-images .another-image .video_wrapper,
.import-product-multi-images .video_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-images .another-image .video_wrapper {
	height: 70px;
}

.import-product-multi-images .video_wrapper {
	height: 120px;
}

.product-images .another-image .video_wrapper .play-video-icon,
.import-product-multi-images .video_wrapper .play-video-icon {
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 1;
	background: #0000004f;
	width: 70px;
	height: 65px;
	transform: translate(0%, 0%);
	-webkit-transform: translate(0%, 0%);
	-moz-transform: translate(0%, 0%);
	-ms-transform: translate(0%, 0%);
	-o-transform: translate(0%, 0%);
}

.product-images .another-image .video_wrapper .play-video-icon svg,
.import-product-multi-images .video_wrapper .play-video-icon svg {
	color: #ffff;
	top: 16px;
	left: -24px;
	cursor: pointer;
}

.ProductRefund-wrapper .product-category {
	overflow-y: auto;
	background: #f0f0f0;
	width: 347px;
	height: 248px;
	padding: 10px;
	border-radius: 4px;
	box-sizing: content-box;
}

.ProductRefund-wrapper .product-category h3 {
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0px;
	color: #67747b;
}

.ProductRefund-wrapper .product-category .label {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #67747b;
}

.ProductRefund-wrapper .product-category .input {
	background: #ffffff;
	display: flex;
	align-items: center;
	width: 308px;
	height: 44px;
	padding: 5px 16px;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #67747b;
	border-radius: 4px;
}

.ProductRefund-wrapper .product-category .tags {
	min-width: 130px;
	max-width: 300px;
	padding: 4px 8px;
	text-align: center;
	white-space: pre-line;
	background: #ffffff;
	color: #67747b;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0px;
	border-radius: 18px;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	-ms-border-radius: 18px;
	-o-border-radius: 18px;
}

.ProductRefund-wrapper .selling-price-label,
.ProductRefund-wrapper .about-product-label {
	font-size: 18px;
	font-weight: 500 !important;
	color: #011723;
}

.ProductRefund-wrapper .selling-price-label span {
	font-size: 16px;
	color: #1dbbbe;
}

.ProductRefund-wrapper .input {
	width: 524px;
	max-width: 100%;
	height: 48px;
	background: #ffffff;
	border: 1px solid #ececec;
}

.ProductRefund-wrapper .input.textarea {
	height: 340px;
	max-width: 100%;
	color: #67747b;
	font-size: 20px;
	font-weight: 400;
	padding: 20px 16px 25px 13px;
	white-space: normal;
	overflow-y: auto;
}

.ProductRefund-wrapper .refund-btn {
	background: #1dbbbe;
	width: 670px;
	height: 56px;
	border-radius: 28px;
	font-size: 22px;
	font-weight: 500px;
	letter-spacing: 0px;
	color: #ffffff;
}

.ProductRefund-wrapper .product-options {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	margin: 1rem 0;
}

.ProductRefund-wrapper .product-options label {
	display: flex;
	font-size: 1rem;
	font-weight: 500;
	color: #000000;
	margin: 0;
	white-space: nowrap;
}

.ProductRefund-wrapper .product-options label span {
	font-size: 1rem;
	color: #ef4444;
}

.ProductRefund-wrapper .product-options .radio-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
}

.ProductRefund-wrapper .product-options .radio-options .option {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1px solid #e5e5e5;
	border-radius: 30px;
	cursor: pointer;
}

.ProductRefund-wrapper .product-options .radio-options .option label {
	font-size: 14px;
	font-weight: 400;
	color: #3d464d;
	padding: 3px 8px 2px;
	cursor: pointer;
}

.ProductRefund-wrapper .product-options .radio-options .option input {
	display: none;
}

.ProductRefund-wrapper .product-options .radio-options .option.active {
	border: 2px solid var(--second-500);
}

.ProductRefund-wrapper .product-options .radio-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
}

.ProductRefund-wrapper .product-options .radio-options .option {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1px solid #e5e5e5;
	border-radius: 30px;
	cursor: pointer;
}

.ProductRefund-wrapper .product-options .radio-options .option label {
	font-size: 14px;
	font-weight: 400;
	color: #3d464d;
	padding: 3px 8px 2px;
	cursor: pointer;
}

.ProductRefund-wrapper .product-options .radio-options .option input {
	display: none;
}

.ProductRefund-wrapper .product-options .radio-options .option.active {
	border: 2px solid var(--second-500);
}

.ProductRefund-wrapper .product-options .radio-options .option-color {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1px solid #e5e5e5;
	border-radius: 30px;
	cursor: pointer;
}

.ProductRefund-wrapper .product-options .radio-options .option-color label {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	font-size: 1rem;
	font-weight: 500;
	color: #3d464d;
	padding: 3px 8px 2px;
	cursor: pointer;
}

.ProductRefund-wrapper
	.product-options
	.radio-options
	.option-color
	label
	span {
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.ProductRefund-wrapper .product-options .radio-options .option-color input {
	display: none;
}

.ProductRefund-wrapper .product-options .radio-options .option-color.active {
	border: 2px solid var(--second-500);
}

.ProductRefund-wrapper .product-options .radio-options .option-image {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 85px;
	background: transparent;
	border: 1px solid #e5e5e5;
	cursor: pointer;
	border-radius: 4px;
}

.ProductRefund-wrapper .product-options .radio-options .option-image label {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.ProductRefund-wrapper
	.product-options
	.radio-options
	.option-image
	label
	.image {
	width: 100%;
	height: 85px;
	padding: 5px;
}

.ProductRefund-wrapper
	.product-options
	.radio-options
	.option-image
	label
	.image
	img {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	object-fit: cover;
}

.ProductRefund-wrapper
	.product-options
	.radio-options
	.option-image
	label
	span {
	font-size: 0.8rem;
	font-weight: 500;
	color: #3d464d;
	text-align: center;
	padding: 2px;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
}

.ProductRefund-wrapper .product-options .radio-options .option-color input {
	display: none;
}

.ProductRefund-wrapper .product-options .radio-options .option-color.active {
	border: 2px solid var(--second-500);
}

/* Notifications  page style*/
.notifications {
	height: 100%;
	background-color: #fafafa;
	padding: 100px 100px 30px;
}

.notifications .page-title {
	font: bold 20px/29px "Tajawal";
	letter-spacing: 0.2px;
	color: #011723;
}

.notifications .notifications-title {
	font: 500 20px/20px "Tajawal";
	letter-spacing: 0px;
	color: #011723;
	margin-bottom: 16px;
}

.notification-user-name {
	font: normal 18px/20px "Tajawal";
	letter-spacing: 0px;
	color: #011723;
}

.notification-time {
	font: 300 16px/22px "Tajawal";
	letter-spacing: 0.16px;
	color: #a7a7a7;
}

.notifications-table .notification-box .show-more {
	display: block;
	font-size: 1.3rem;
	color: var(--second-500);
	cursor: pointer;
}

.read-more-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: #0000008a;
	z-index: 1300;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.read-more-modal .modal {
	width: 50%;
	top: 300px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 6px;
	height: auto;
	max-height: 400px;
	display: flex;
	flex-direction: column;
}

.read-more-modal .modal .header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: end;
	justify-content: end;
	padding: 10px;
}

.read-more-modal .modal .header svg {
	font-size: 1.5rem;
	color: var(--red-500);
	cursor: pointer;
}

.read-more-modal .modal .body {
	padding: 0 1rem 1rem;
	height: 100%;
	overflow: auto;
	white-space: break-spaces;
}

.postponed-page-title {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-size: 250px;
}

.PlatformServices-page .delegate-request-btn {
	width: 172px;
	height: 56px;
	font: 500 20px/20px "Tajawal";
	letter-spacing: 0px;
	color: #f7fcff;
	background: #b6be34;
	border-radius: 8px;
}

.PlatformServices-page .delegate-request-form {
	width: 100%;
	background: #f4f5f7;
	padding: 20px 20px 30px 20px;
	border-radius: 8px;
}

.PlatformServices-page .delegate-request-form .form-name {
	font: 500 20px/30px "Tajawal";
	letter-spacing: 0px;
	color: #011723;
}

.PlatformServices-page .delegate-request-form label {
	font: normal 18px/30px "Tajawal";
	letter-spacing: 0px;
	color: #011723;
}

.PlatformServices-page .delegate-request-form label span {
	font: normal 16px/30px "Tajawal";
	color: #ff3838;
	margin-right: 6px;
}

.PlatformServices-page .delegate-request-form input {
	height: 56px;
	font-size: 18px;
	background: #ffffff;
	padding: 18px;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	-moz-border-radius: 4px !important;
	-ms-border-radius: 4px !important;
	-o-border-radius: 4px !important;
}

.PlatformServices-page .delegate-request-form textarea {
	height: auto;
	font-size: 18px;
	background: #ffffff;
	padding: 18px;
	border-radius: 4px !important;
	-webkit-border-radius: 4px !important;
	-moz-border-radius: 4px !important;
	-ms-border-radius: 4px !important;
	-o-border-radius: 4px !important;
}

.PlatformServices-page .delegate-request-form textarea::placeholder {
	font-size: 1rem;
}

/*  .store-activity-input */
.PlatformServices-page .delegate-request-form .store-activity-input,
.form-row .form .main-categories {
	background-color: #ffffff;
	min-height: 56px;
	max-height: 180px;
	overflow-y: auto;
	border: none;
	padding: 14px;
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.PlatformServices-page .delegate-request-form .store-activity-input .activity,
.form-row .form .main-categories .categories,
.multiple_select_items {
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
	background: #eeeeee;
	padding: 0px 8px;
	color: #67747b;
	border-radius: 18px;
	letter-spacing: 0px;
	font: normal 18px/30px "Tajawal";
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	-ms-border-radius: 18px;
	-o-border-radius: 18px;
}

.PlatformServices-page .delegate-request-form .new-service-hint {
	font-size: 16px;
	font-weight: 400;
	color: var(--second-500);
	white-space: normal;
}

.PlatformServices-page
	.delegate-request-form
	input.new-service-input::placeholder {
	font-size: 16px;
}

textarea.new-service-input {
	resize: none;
}

.PlatformServices-page .delegate-request-form .upload-request-btn {
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	font: 500 20px/32px "Tajawal";
	letter-spacing: 0.2px;
	color: #ffffff;
	background: #1dbbbe;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

/* select Delegate page */
.select-delegate {
	height: 180px;
	background: #ffffff;
	box-shadow: -3px 0px 12px #0000000a;
	border-radius: 16px;
}

.select-delegate-title {
	font: 500 20px/28px "Tajawal";
	letter-spacing: 0.2px;
	color: #02466a;
}

/* style works hours modal  */
.work-hours-head {
	background-color: #1dbbbe;
	border-radius: 8px 8px 0 0;
	-webkit-border-radius: 8px 8px 0 0;
	-moz-border-radius: 8px 8px 0 0;
	-ms-border-radius: 8px 8px 0 0;
	-o-border-radius: 8px 8px 0 0;
}

.work-hours-wrapper .works-switch {
	font-size: 20px;
	padding: 14px;
	color: #fff;
	border-radius: 8px;
	margin-bottom: 20px;

	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.work-hours-wrapper {
	width: 100%;
	background: #ffffff;
	padding: 20px;
	border-radius: 0 0 8px 8px;
	-webkit-border-radius: 0 0 8px 8px;
	-moz-border-radius: 0 0 8px 8px;
	-ms-border-radius: 0 0 8px 8px;
	-o-border-radius: 0 0 8px 8px;
}

.work-day {
	width: 100%;
	min-height: 80px;
	background-color: #ffffff;
	box-shadow: 0px 3px 20px #0000000f;
	border: 1px solid #0000000f;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	margin-bottom: 10px;
}

.work-day:last-of-type {
	margin-bottom: 30px;
}

.choose-time {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 1rem;
	width: 100%;
}

.choose-time .time-input {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 18px;
	height: 48px;
	border-radius: 4px;
	border: 1px solid rgb(173, 181, 185);
}

.choose-time .time-input input {
	background-color: transparent;

	max-width: 100%;
}

.choose-time .time-input input:disabled {
	opacity: 0.3;
}

.day-switch {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 105px;
	height: 32px;
	border-radius: 16px;
	padding: 10px;
	cursor: pointer;
	transition: all 0.3s;
}

.day-switch span {
	font-size: 14px !important;
	color: #ffffff;
	font-weight: 400;
	font-family: "Tajawal", sans-serif;
	letter-spacing: 0.14px;
}

.day-switch .circle {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #ffffff;
	margin: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
	background: url("./data/Icons/down-arrow.svg") no-repeat;
	background-size: 100%;
	cursor: pointer;
}

.save-work-hours {
	height: 56px;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	background-color: #1dbbbe;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.add-new-product-form .add-product-images {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	background-color: #fafafa;
	border: 2px dashed #237eae;
	border-radius: 4px;
	cursor: pointer;
}

.add-new-product-form .add-product-images img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.add-new-product-form .add-product-images video {
	width: 100%;
	height: 100%;
}

.add-new-product-form .add-product-images .play-video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #adb5b9;
	cursor: pointer;
}

.add-new-product-form .add-product-images .delete-video-icon {
	display: flex;
	position: absolute;
	top: 2px;
	left: 2px;
	cursor: pointer;
}

.add-new-product-form .add-product-images .add-icon {
	display: flex;
	cursor: pointer;
	font-size: 1.6rem;
	color: #707070;
}

.add-new-product-form .add-product-images .delete-icon {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: all 0.2s;
}

.add-new-product-form .add-product-images .delete-icon:hover {
	opacity: 0.7;
	background-color: #2d2d2d;
}

.add-new-product-form .rti--container {
	display: flex;
	flex-direction: row;
	align-items: baseline !important;
	height: 150px !important;
	min-height: 150px !important;
	overflow-y: auto;
	padding: 1rem !important;
}

.add-new-product-form .rti--container input {
	padding: 0;
	height: 45px;
	/* width: fit-content !important; */
	border: none !important;
}

.add-new-product-form .rti--container:focus-within {
	border: none !important;
	box-shadow: none !important;
}

.add-new-product-form .file-input {
	position: relative;
	width: 100%;
	height: 56px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
}

.add-new-product-form .input-pixel {
	direction: ltr;
	flex: 1 1;
	width: 100%;
	height: 100%;
	min-height: 160px;
	max-height: 160px;
	background-color: #ffffff;
	outline: none;
	border: none;
	overflow: auto;
}

.add-new-product-form .input-pixel textarea {
	border: none !important;
}

.video-url-content {
	display: flex;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	max-width: 90%;
	width: 400px;
	height: 300px;
	z-index: 9999;
}

.video-url-content svg.close_video_icon {
	color: #1b1b1b;
	cursor: pointer;
}

.video-url-content video {
	width: 100%;
	height: 100%;
}

.paint-store-page {
	background-color: #f7f7f7;
	display: flex;
	flex-direction: column;
	padding: 30px 20px;
	margin-top: 20px;
	border-radius: 8px;
}

.paint-store-page .data-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-color: transparent;
	min-height: 100vh;
}

.paint-store-page .data-container .MuiPaper-root {
	box-shadow: none;
}

.paint-store-page .data-container .MuiPaper-root::before {
	position: unset;
}

.paint-store-page .data-container .MuiButtonBase-root {
	min-height: 70px;
	background-color: #f7fcff;
	border: 1px solid #efefef;
	border-radius: 4px;
}

.paint-store-page .data-container .MuiAccordionSummary-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	gap: 12px;
}

.paint-store-page .data-container .MuiAccordionSummary-content .icon path {
	fill: #02466a;
}

.paint-store-page .data-container .MuiAccordionSummary-content h6 {
	font: normal normal medium 20px/28px Tajawal;
	letter-spacing: 0.2px;
	color: #02466a;
}

.paint-store-page .data-container .MuiAccordionSummary-content p {
	font: normal normal normal 16px/22px Tajawal;
	letter-spacing: 0.16px;
	color: #67747b;
	white-space: pre-line;
}

.paint-store-page .data-container .MuiAccordionSummary-expandIconWrapper {
	transform: rotate(90deg);
}

.paint-store-page
	.data-container
	.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
	transform: rotate(0deg);
}

.paint-store-page .data-container .MuiAccordionDetails-root {
	padding: 1rem;
	display: flex;
	flex-direction: column;
}

.paint-store-page .data-container .MuiAccordionDetails-root .content {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	column-gap: 90px;
	row-gap: 1rem;
	margin-right: 80px;
}

.paint-store-page .data-container .MuiAccordionDetails-root .content .text {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	min-width: 115px;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.text
	span {
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.18px;
	color: #02466a;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.text
	svg
	path {
	fill: #1dbbbe;
}

.paint-store-page .data-container .MuiAccordionDetails-root .content .picker {
	width: 180px;
	height: 38px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #ffffff;
	gap: 30px;
	border-width: 1px;
	border-style: solid;
	padding: 10px;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker
	label {
	position: relative;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker
	label
	svg {
	cursor: pointer;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker
	input[type="color"] {
	width: 0 !important;
	height: 0 !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker
	input {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1.2px;
	display: flex;
	width: 100%;
	height: 100% !important;
	border: none;
	outline: none;
	direction: ltr;
	text-align: end;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker
	span {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1.2px;
}

.paint-store-page .data-container .MuiAccordionDetails-root button {
	width: 170px;
	height: 56px;
	background-color: #1dbbbe;
	color: #eff9ff;
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0.24px;
	border: none;
	outline: none;
	border-radius: 8px;
	align-self: flex-end;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker-bg {
	width: 180px;
	height: 38px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 30px;
	padding: 10px;
	border-width: 1px;
	border-style: solid;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker-bg
	label {
	position: relative;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker-bg
	label
	svg {
	cursor: pointer;
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker-bg
	input[type="color"] {
	width: 0 !important;
	height: 0 !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.paint-store-page
	.data-container
	.MuiAccordionDetails-root
	.content
	.picker-bg
	input {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1.2px;
	display: flex;
	width: 100%;
	height: 100% !important;
	border: none;
	outline: none;
	background-color: transparent;
	color: #ffffff;
	direction: ltr;
	text-align: end;
}

.paint-store-page .data-container .MuiAccordionDetails-root .buttons h6 {
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 0.2px;
	color: #1dbbbe;
	margin-bottom: 22px;
	margin-right: 80px;
}

.paint-store-page .data-container .MuiAccordionDetails-root .reset {
	background-color: #02466a;
	color: #ffffff;
	font-size: 1rem;
	margin-bottom: 1rem;
}

.seo-store-page {
	background-color: #fafafa;
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	padding: 30px 20px;
	border-radius: 8px;
}

.seo-store-page .data-container {
	background-color: transparent;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-left: 220px;
}

.seo-store-page .data-container .inputs-group {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.seo-store-page .data-container .inputs-group .label {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.seo-store-page .data-container .inputs-group .label label {
	font-size: 18px;
	font-weight: 500;
	color: #200407;
	margin: 0;
}

.seo-store-page .data-container .inputs-group .input {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 22px;
}

.seo-store-page .data-container .inputs-group .input input {
	width: 100%;
	height: 60px;
	padding: 1rem;
	background-color: #eeeeef;
	box-shadow: 0px 3px 6px #e622370f;
	outline: none;
	border: none;
	border-radius: 8px;
}

.seo-store-page .data-container .inputs-group .input input::placeholder {
	color: #c6c6c6;
}

.seo-store-page .data-container .inputs-group .file-input-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 22px;
}

.seo-store-page
	.data-container
	.inputs-group
	.file-input-content
	.file-wrapper {
	direction: ltr;
	width: 100%;
	height: 100%;
	min-height: 160px;
	max-height: 160px;
	background-color: #eeeeef;
	box-shadow: 0px 3px 6px #e622370f;
	outline: none;
	border: none;
	border-radius: 8px;
	overflow: auto;
}

.seo-store-page .data-container .inputs-group .robot_link_text_area {
	direction: ltr;
	padding: 16px;
	width: 100%;
	height: 100%;
	min-height: 160px;
	max-height: 160px;
	background-color: #eeeeef;
	box-shadow: 0px 3px 6px #e622370f;
	outline: none;
	border: none;
	border-radius: 8px;
	overflow: auto;
}

.seo-store-page .data-container .inputs-group .input .rti--container {
	width: 100%;
	min-height: 192px !important;
	padding: 1rem !important;
	background-color: #eeeeef;
	outline: none;
	border: none;
	border-radius: 8px;
}

.seo-store-page
	.data-container
	.inputs-group
	.input
	.rti--container:focus-within {
	border-color: transparent;
	box-shadow: none;
}

.seo-store-page
	.data-container
	.inputs-group
	.input
	.rti--container
	.rti--input,
.add-new-product-form .rti--container .rti--input {
	width: fit-content;
	height: 35px;
	padding: 10px !important;
	background-color: #ffffff;
	border: 1px solid #d4d4d4;
	border-radius: 15px;
}
/*  upload og image styles */
.upload_og_image {
	width: 100%;
}

.upload_image_box {
	height: 175px;
	width: 50%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 2px dashed var(--black-200);

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}
.og_image_preview_box {
	width: 50%;
	max-height: 300px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	margin-bottom: 10px;
	border: 1px solid var(--black-200);
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.add-new-product-form .rti--container .rti--input {
	background-color: #f2f2f2;
	width: 213px;
}

.seo-store-page
	.data-container
	.inputs-group
	.input
	.rti--container
	.rti--input::placeholder,
.add-new-product-form .rti--container .rti--input::placeholder {
	font-size: 17px;
}

.add-new-product-form .rti--container .rti--input::placeholder {
	color: #505050;
}

.product-texteditor .quill .ql-container.ql-snow {
	border: none;
	height: 230px;
}

.product-souq-texteditor .quill .ql-toolbar.ql-snow {
	display: none;
}

.product-souq-texteditor .quill .ql-container.ql-snow {
	border: none;
	height: 230px;
}

.product-souq-texteditor .quill .ql-container.ql-snow .ql-editor {
	background-color: #eeeeef;
}

.product-texteditor .ql-toolbar.ql-snow {
	border-color: #e6e4e4;
}

.seo-store-page .data-container .inputs-group .input .rti--container .rti--tag {
	width: fit-content;
	height: 35px;
	padding: 10px !important;
	background-color: #ffffff !important;
	border: 1px solid #d4d4d4;
	border-radius: 15px;
}

.seo-store-page
	.data-container
	.inputs-group
	.input
	.rti--container
	.rti--tag
	button {
	font-size: 14px;
	color: #c6c6c6;
	margin-top: 3px;
}

.seo-store-page .data-container .social-media-inputs {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 0.5rem;
}

.seo-store-page .data-container .social-media-inputs .label {
	min-width: 180px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.seo-store-page .data-container .social-media-inputs .label label {
	font-size: 20px;
	font-weight: 500;
	color: #011723;
	white-space: normal !important;
}

.seo-store-page .data-container .social-media-inputs .input {
	direction: ltr;
	flex: 1;
	width: 100%;
	height: 100%;
	min-height: 160px;
	max-height: 160px;
	background-color: #eeeeef;
	box-shadow: 0px 3px 6px #e622370f;
	outline: none;
	border: none;
	border-radius: 8px;
	overflow: auto;
}

.seo-store-page .data-container .wrong-text {
	font-size: 0.9rem;
	font-weight: 500;
	color: #ff3838;
	margin: 0;
}

/* Syntax highlighting */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #90a4ae;
}

.npm__react-simple-code-editor__textarea:empty {
	position: relative !important;
	top: -35px !important;
	text-align: right;
	direction: rtl;
}

.token.punctuation {
	color: #9e9e9e;
}

.namespace {
	opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
	color: #e91e63;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #4caf50;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
	color: #795548;
}

.token.atrule,
.token.attr-value,
.token.keyword {
	color: #3f51b5;
}

.token.function {
	color: #f44336;
}

.token.regex,
.token.important,
.token.variable {
	color: #ff9800;
}

.token.important,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

.tax-text {
	font-size: 0.8rem;
	font-weight: 500;
	color: #727272;
	margin: 0;
}

.order-toolbar .search-input-box {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 0.5;
	padding: 0 16px;
	width: 100%;
	height: 56px;
	border: 1px solid var(--bs-white--100);
	background-color: aliceblue;
}

.order-toolbar .search-input-box input {
	width: 100%;
	height: 100% !important;
	background-color: transparent;
	padding-right: 10px;
	border-radius: 5px;
	font-size: 18px;
}

.carts-page .search-input-box input::placeholder,
.order-toolbar .search-input-box input::placeholder,
.coupon-form .add-category .input-box input::placeholder {
	color: var(--primary-300);
}

.order-toolbar .search-input-box svg,
.coupon-form .add-category .input-box svg.search-icon {
	color: var(--primary-400);
}

.order-toolbar .select-input-box {
	flex: 0.3;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	padding: 0 10px 0 0;
	border: 1px solid var(--bs-white--100);
	background-color: aliceblue;
}

.order-toolbar .select-input-box .filter-icon,
.input-box svg.filter-icon {
	color: #02466a;
	z-index: 1;
}

.order-toolbar .select-input-box .form-select {
	width: 100%;
	height: 100% !important;
	background-color: transparent;
	border-radius: 5px;
	font-size: 18px;
	border: none !important;
}

.tags-boxes {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	gap: 1rem;
}

.tags-boxes .tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.2rem;
	padding: 5px;
	background-color: var(--gray-100);
	border-radius: 6px;
}

.tags-boxes .tag span {
	font-size: 1rem;
	font-weight: 500;
	line-height: 18px;
}

.tags-boxes .tag svg {
	width: 1rem;
	height: 1rem;
	cursor: pointer;
}

.modal-content {
	border: none;
	border-radius: 2px;
	box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.15);
}

.modal-content .quickview {
	padding: 30px;
	position: relative;
}

.modal-content .quickview .close {
	position: absolute;
	top: 0;
	left: 0;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: transparent;
	border-radius: 3px;
	border: none;
	fill: #bfbfbf;
	transition: all 0.2s;
	z-index: 2;
}

.modal-content .quickview .close svg {
	width: 24px;
	height: 24px;
	fill: #797979;
}

.modal-content .quickview .product-price {
	margin-top: 18px;
	margin-bottom: 0;
	font-size: 28px;
	font-weight: 700;
	letter-spacing: -0.03em;
	color: #3d464d;
}

.modal-content .quickview .product-price .tax-text {
	font-size: 0.7rem;
	font-weight: 500;
	color: #b3b3b3;
	margin: 0;
	margin-bottom: 14px;
}

.modal-content .quickview .product-price .tax-currency {
	font-size: 0.9rem;
	font-weight: 500;
	color: #3d464d;
	margin: 0;
	margin-bottom: 14px;
}

.modal-content .quickview .form-product-option label {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	gap: 0.2rem;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 500;
	color: #6c757d;
	padding-bottom: 2px;
	margin-bottom: 0.25rem;
}

.modal-content .quickview .form-product-option label span {
	font-size: 14px;
	color: var(--second-500);
}

.modal-content .quickview .form-product-option .product-actions {
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
}

.modal-content .quickview .form-product-option .product-actions button {
	border-color: var(--second-500);
	background: var(--second-500);
	color: #ffffff;
	font-size: 1.125rem;
	height: calc(2.75rem + 2px);
	padding: 0.8125rem 2rem;
	line-height: 1;
	font-weight: 500;
	border-radius: 2px;
	transition: all 0.1s ease-in-out;
}

.modal-content .quickview .form-product-option .product-actions button:hover {
	border-color: var(--primary-500);
	background: var(--primary-500);
}

.modal-content
	.quickview
	.form-product-option
	.product-actions
	button:disabled {
	border-color: var(--black-100);
	background: var(--black-100);
}

.modal-content .quickview .form-product-option .product-actions .quantity {
	width: 120px;
	display: block;
	position: relative;
	border: 1px solid #ced4da;
}

.modal-content
	.quickview
	.form-product-option
	.product-actions
	.quantity
	button {
	position: absolute;
	height: calc(100% - 2px);
	width: 24px;
	font-weight: 500;
	font-size: 1.5rem;
	top: 1px;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	background-color: transparent;
	border: none;
	color: #000000;
	transition: opacity 0.18s;
	padding: 0;
	z-index: 1;
}

.modal-content
	.quickview
	.form-product-option
	.product-actions
	.quantity
	button:hover {
	opacity: 0.5;
}

.modal-content .quickview .form-product-option .product-actions .quantity .add {
	left: 0;
}

.modal-content .quickview .form-product-option .product-actions .quantity .sub {
	right: 0;
}

.modal-content
	.quickview
	.form-product-option
	.product-actions
	.quantity
	input {
	display: block;
	width: 100%;
	min-width: 88px;
	padding: 0 24px 1px;
	text-align: center;
	height: calc(2.75rem + 2px);
	line-height: 1.25;
	font-size: 1.25rem;
	font-weight: 400;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
}

.modal-content .quickview .product-options {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	margin: 1rem 0;
}

.modal-content .quickview .product-options label {
	display: flex;
	font-size: 1rem;
	font-weight: 500;
	color: #000000;
	margin: 0;
	white-space: nowrap;
}

.modal-content .quickview .product-options label span {
	font-size: 1rem;
	color: #ef4444;
}

.modal-content .quickview .product-options .radio-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
}

.modal-content .quickview .product-options .radio-options .option {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1px solid #e5e5e5;
	border-radius: 30px;
	cursor: pointer;
}

.modal-content .quickview .product-options .radio-options .option label {
	font-size: 14px;
	font-weight: 400;
	color: #3d464d;
	padding: 3px 8px 2px;
	cursor: pointer;
}

.modal-content .quickview .product-options .radio-options .option input {
	display: none;
}

.modal-content .quickview .product-options .radio-options .option.active {
	border: 2px solid var(--second-500);
}

.modal-content .quickview .product-options .radio-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
}

.modal-content .quickview .product-options .radio-options .option {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1px solid #e5e5e5;
	border-radius: 30px;
	cursor: pointer;
}

.modal-content .quickview .product-options .radio-options .option label {
	font-size: 14px;
	font-weight: 400;
	color: #3d464d;
	padding: 3px 8px 2px;
	cursor: pointer;
}

.modal-content .quickview .product-options .radio-options .option input {
	display: none;
}

.modal-content .quickview .product-options .radio-options .option.active {
	border: 2px solid var(--second-500);
}

.modal-content .quickview .product-options .radio-options .option-color {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1px solid #e5e5e5;
	border-radius: 30px;
	cursor: pointer;
}

.modal-content .quickview .product-options .radio-options .option-color label {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	font-size: 1rem;
	font-weight: 500;
	color: #3d464d;
	padding: 3px 8px 2px;
	cursor: pointer;
}

.modal-content
	.quickview
	.product-options
	.radio-options
	.option-color
	label
	span {
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.modal-content .quickview .product-options .radio-options .option-color input {
	display: none;
}

.modal-content .quickview .product-options .radio-options .option-color.active {
	border: 2px solid var(--second-500);
}

.modal-content .quickview .product-options .radio-options .option-image {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 85px;
	background: transparent;
	border: 1px solid #e5e5e5;
	cursor: pointer;
	border-radius: 4px;
}

.modal-content .quickview .product-options .radio-options .option-image label {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.modal-content
	.quickview
	.product-options
	.radio-options
	.option-image
	label
	.image {
	width: 100%;
	height: 85px;
	padding: 5px;
}

.modal-content
	.quickview
	.product-options
	.radio-options
	.option-image
	label
	.image
	img {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	object-fit: cover;
}

.modal-content
	.quickview
	.product-options
	.radio-options
	.option-image
	label
	span {
	font-size: 0.8rem;
	font-weight: 500;
	color: #3d464d;
	text-align: center;
	padding: 2px;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	white-space: nowrap;
}

.modal-content .quickview .product-options .radio-options .option-color input {
	display: none;
}

.modal-content .quickview .product-options .radio-options .option-color.active {
	border: 2px solid var(--second-500);
}

/* ----------------------------------------------------------------------- */
@media (min-width: 1400px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1380px;
	}
}

@media (max-width: 1600px) {
	.category,
	.products,
	.orders-pages,
	.offers-page,
	.carts-page,
	.seo-page,
	.marketing-page,
	.rating-page,
	.pages-page,
	.academy-page,
	.social-page,
	.customer-page,
	.main-info-page,
	.management-page,
	.reports-page,
	.coupon-page,
	.create-offers-page,
	.user-cart-data-wrapper,
	.PlatformServices-page,
	.delegate-page,
	.page-wrapper {
		width: 100%;
	}
}

@media (max-width: 1400px) {
	.add-category,
	.products .mange-file {
		width: 100%;
	}

	.input-box {
		width: 36.33%;
	}

	.add-category-bt-box button,
	.products .mange-file button,
	.upload-files label {
		font-size: 18px;
		font-weight: 400;
	}

	.products .input-group .select-input,
	.drop-files {
		width: 43%;
		margin-left: 0 !important;
	}

	.add-category-bt-box,
	.add-new-product {
		width: 24%;
	}

	.add-category-bt-box button svg,
	.add-new-product-btn svg {
		width: 20px;
		height: 20px;
	}

	.seo-weight-edit-box .input-bx.banners-box,
	.seo-weight-edit-box .input-bx.banners-box.first-one {
		width: 642px;
		margin: 0px 110px;
	}

	.data-box .icon {
		width: 50px;
		height: 50px;
		padding: 10px;
	}

	.data-box .icon svg {
		width: 25px;
		height: 25px;
	}

	.data-box .data p {
		font-size: 18px;
	}

	.souqOtlobha-page .total-products {
		width: 100%;
	}

	.banners-box .hint {
		padding-right: 28px;
	}

	.explain-boxes .box {
		width: 270px;
		height: auto;
	}
}

@media (max-width: 1200px) {
	.add-category .input-group {
		gap: 1rem;
	}

	.add-category .input-group .search-input,
	.add-category .input-group .select-input,
	.add-category .input-group .add-category-bt-box {
		flex: 1 1 auto;
	}

	.products .mange-file {
		width: 100%;
		flex-wrap: wrap;
		gap: 1rem;
	}

	.products .mange-file .export-upload-btn-group,
	.products .mange-file .drop-files {
		width: 50%;
		margin: 0 !important;
		flex: 1 1 auto;
	}

	.add-new-product {
		width: 100%;
	}

	.filter-row {
		flex-wrap: wrap;
	}

	.seo-weight-edit-box .input-bx.banners-box,
	.seo-weight-edit-box .input-bx.banners-box.first-one {
		width: 100%;
		margin: 0px -121px;
	}

	.package-boxes {
		flex-wrap: wrap;
	}

	.package-boxes > div {
		flex: 1 1 auto;
	}

	.data-container {
		padding: 20px;
	}

	.notifications {
		padding: 16px 20px;
	}

	.user-cart-data-wrapper {
		width: 100%;
	}

	.user-cart-data .userData-container .container-body .user-name {
		padding-right: 30px;
	}

	.user-info-row {
		justify-content: center;

		padding-right: 10px;
	}

	.social-links-form input {
		width: 100%;
	}

	.souqOtlobha-page .total-products .shipping-icon {
		width: 70px;
		font-size: 18px;
	}

	.souqOtlobha-page .total-products .text {
		width: auto;
		font-size: 16px;
	}

	.souqOtlobha-page .btn-group button:last-child {
		width: 190px;
		font-size: 16px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}

	.souqOtlobha-page .btn-group button:first-child {
		width: 130px;
		font-size: 16px;
	}

	.souqOtlobha-page .btn-group button:first-child svg {
		width: 18px;
		height: 18px;
	}

	.souqOtlobha-wrapper .MuiInputBase-root {
		height: 50px !important;
		border-radius: 6px !important;
		font-size: 18px !important;
	}

	.souqOtlobha-wrapper .souq_storge_category_filter_items {
		font-size: 16px;
	}

	.apply-btn {
		width: 188px;
		font-size: 18px;
	}

	.rate-act-btn .margin-right-lg {
		margin-right: 12px;
	}

	.academy-page .btns-group-container button {
		font-size: 20px;
		font-weight: 400;
		width: 250px;
		height: 118px;
	}

	.edit-job-title {
		width: 96%;
	}

	.page-wrapper {
		width: 98%;
	}

	.edit-job-title .page-header .search .save-btn {
		font-size: 22px;
	}

	/* Explain Details */
	.explain-details-page .explain-title {
		margin-right: 0;
	}

	.seo-store-page .data-container {
		padding-left: 0px;
	}

	.checkout-page .block .card .card-body {
		padding: 1rem;
	}

	.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean svg {
		left: -90px;
	}

	.banners-box .hint {
		padding-right: 23px;
	}

	.explain-boxes .box {
		width: 275px;
		height: auto;
	}
}

@media (max-width: 992px) {
	.navbar > .container {
		flex-wrap: nowrap;
	}

	.dropdown-title {
		align-items: center;
	}

	.navbar-brand {
		width: 60px;
		height: 60px;
	}

	.navbar-nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}

	.navbar-nav .search-box {
		width: 100%;
	}

	.navbar-nav .search-box input {
		width: 100%;
	}

	.navbar-nav .notification-dropdown {
		position: absolute;
	}

	.navbar-nav .dropdown-menu.user-info-dropdown {
		margin-top: 5px;
	}

	.content > .row > .pages-content {
		width: calc(100% - 210px);
	}

	.main-content {
		/* margin-top: 76px; */
		padding: 16px;
	}

	.sidebar-col {
		width: 210px !important;
		padding: 0 !important;
	}

	.sidebar {
		width: 210px !important;
		min-width: 210px !important;
		top: 76px;
		right: 0;
		padding: 0;
		/* padding-bottom: 80px; */
	}

	.sidebar .ps-menu-root > ul {
		margin-bottom: 90px;
	}

	.content > .row > .pages-content {
		padding: 0;
		margin: 0;
	}

	.head-category .breadcrumb {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		font-size: 16px;
	}

	.head-category .breadcrumb .breadcrumb-item img {
		width: 25px;
		height: 25px;
	}

	.order-details-page .head-category h3 {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 6px;
	}

	.reports-page .reports-tabs-buttons .nav-item {
		width: 100%;
		margin-right: 0 !important;
	}

	.reports-page .select-report-links button {
		width: 100%;
	}

	.sales-reports-data,
	.customers-reports-data {
		width: 100%;
	}

	.customers-BarChart {
		width: 100%;
		margin-right: 0;
	}

	.react-datepicker-popper,
	.add-coupon-form .react-datepicker-popper {
		transform: translate(0px, 0px) !important;
		-webkit-transform: translate(0px, 0px) !important;
		-moz-transform: translate(0px, 0px) !important;
		-ms-transform: translate(0px, 0px) !important;
		-o-transform: translate(0px, 0px) !important;
	}

	.user-cart-data-wrapper
		.create-offer-row
		.discount-date-box
		.react-datepicker-popper {
		transform: translate(-200px, 0px) !important;
		-webkit-transform: translate(-200px, 0px) !important;
		-moz-transform: translate(-200px, 0px) !important;
		-ms-transform: translate(-200px, 0px) !important;
		-o-transform: translate(-200px, 0px) !important;
	}

	.user-cart-data-wrapper .create-offer-row .discount-date-box .date-icon {
		right: 0px;
		width: 50px;
	}

	.user-cart-data-wrapper
		.create-offer-row
		.discount-date-box
		.react-datepicker-wrapper
		input {
		padding-right: 45px !important;
		font-size: 14px;
	}

	.rating-page .user-info {
		width: 200px;
	}

	.rate-act-btn .margin-right-lg {
		margin-right: 30px;
	}

	.rate-act-btn button span {
		font-size: 16px;
	}

	.rate-act-btn button svg {
		width: 20px;
	}

	.rate-act-btn .publish-btn svg {
		font-size: 16px;
		stroke-width: 2;
	}

	.seo-weight-edit-box .input-bx {
		width: 99%;
		margin: 0 auto;
	}

	.edit-job-title .page-header .search .save-btn {
		font-size: 20px;
		font-weight: 400;
	}

	.add-user-form .form-body .input-icons.password-icon {
		right: 92%;
	}

	.add-user-form .form-body input#email {
		padding-left: 17px;
	}

	.souqOtlobha-page .total-products {
		width: 100%;
	}

	.souqOtlobha-page .total-products .shipping-icon {
		width: 70px;
		font-size: 18px;
	}

	.souqOtlobha-page .total-products .text {
		width: 100%;
		font-size: 18px;
	}

	.souqOtlobha-page .btn-group button:first-child {
		flex: 1;
	}

	.souqOtlobha-page .btn-group button:last-child {
		flex: 1;
	}

	.carts-page .search-input-box {
		padding: 0 14px;
		flex: 0.8;
		margin: 0 auto 0 0;
		height: 50px;
	}

	.cart-page h3 {
		font-size: 1.5rem;
	}

	.cart-page .card .card-body table tfoot th {
		font-size: 1.1rem;
	}

	.cart-page .card .card-body table td,
	.cart-page .card .card-body table th {
		font-size: 0.9rem;
	}

	.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean svg {
		left: -20px;
	}
}

@media (max-width: 768px) {
	.loading-text {
		font-size: 16px;
	}

	.delete-category-alert {
		font-size: 18px;
	}

	.add-user-form .form-body input[name="phonenumber"] {
		padding-left: 50px;
		font-size: 16px;
	}

	.add-user-form .form-body .Country_Key {
		top: 11px;
		left: 30px;
		font-size: 16px;
	}

	.user-details-body .Country_Key {
		left: 35px;
		top: 45px;
		font-size: 16px;
	}

	.password-back-box .all-content .box-form-banner .info-svg h4 {
		line-height: 44px !important;
		font-size: 44px !important;
	}

	.password-back-box .all-content .box-container-form .all h2 {
		font-size: 24px !important;
		line-height: 26px !important;
		margin-bottom: 26px !important;
		font-weight: 500 !important;
		white-space: normal !important;
	}

	::-webkit-scrollbar {
		width: 5px;
	}

	::-webkit-scrollbar-thumb {
		border: 1px solid transparent;
	}

	h4,
	h3 {
		font-size: 18px;
		line-height: 20px;
	}

	h5 {
		font-size: 16px;
		line-height: 18px;
	}

	.MuiSelect-icon {
		right: 95% !important;
	}

	.app .top-bar {
		z-index: 999;
	}

	.navbar {
		height: 70px;
	}

	.navbar-nav {
		width: auto;
		flex-direction: row-reverse;
		gap: 20px;
	}

	.navbar-nav .avatar-box {
		width: 100%;
	}

	.navbar-nav .dropdown-menu.user-info-dropdown {
		position: fixed !important;
		width: 100%;
		top: 70px;
		margin: 0;
		padding: 0;
		border-radius: 0;
	}

	.navbar-nav .dropdown-menu.user-info-dropdown li {
		background-color: #03787a;
		margin: 0 !important;
		border-radius: 0;
	}

	.navbar-nav .notification-dropdown {
		position: fixed !important;
		width: 90%;
		top: 70px !important;
		right: 0;
		left: 0;
		margin: auto;
	}

	.navbar-menu-icon {
		color: #fafafa !important;
		cursor: pointer;
	}

	.sidebar {
		display: none;
		top: 70px;
		right: 0;
		padding: 0;
		z-index: 99;
	}

	.sidebar.show {
		width: 260px !important;
		min-width: 260px !important;
		display: flex;
	}

	.content > .row > .pages-content {
		width: 100%;
	}

	.main-content {
		padding: 16px;
		height: auto;
	}

	.head-category .breadcrumb {
		font-size: 14px;
		letter-spacing: 0.14px;
	}

	.breadcrumb-item + .breadcrumb-item {
		padding: 0;
	}

	.breadcrumb-item + .breadcrumb-item::before {
		padding: 0 5px;
	}

	.head-category .breadcrumb .breadcrumb-item img {
		width: 18px;
		height: 18px;
	}

	.line-charts,
	.pie-charts {
		height: auto;
	}

	.line-chart,
	.line-chart-tab-content {
		height: 340px;
	}

	.line-chart-tab-content {
		overflow-y: hidden;
		overflow-x: scroll;
		padding-right: 10px;
	}

	.line-chart-tab-pane {
		height: 100px;
		bottom: -220px;
	}

	.charts .comp-title h4 {
		font-size: 18px;
	}

	.nav-pills .nav-link {
		font-size: 16px;
	}

	.offers-table {
		overflow-x: scroll;
	}

	.analyse-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.analyse-box h5 {
		font-size: 16px;
	}

	.analyse-box p {
		font-size: 18px;
	}

	.analyse-box p .currency {
		font-size: 14px;
	}

	.analyse-box svg {
		font-size: 18px;
	}

	.arrow-style {
		font-size: 18px;
	}

	.comp-title h4 {
		font-size: 18px;
	}

	.css-17gjw9x-MuiToolbar-root {
		margin-bottom: 8px;
	}

	.search-header-box {
		width: 100%;
		height: 48px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		background-color: #108699;
		border-radius: 24px;
		margin-bottom: 14px;
	}

	.search-header-box svg.search_icon {
		width: 50px;
		padding: 12px;
		height: 100%;
		background-color: #fafafa;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 0px 60px 60px 60px;
	}

	.search-header-box input {
		width: 100%;
		height: 100%;
		background-color: transparent;
		color: #f2f3f3;
		font-size: 18px;
		padding-right: 18px;
	}

	.search-header-box input::placeholder {
		color: #f2f3f3;
	}

	.date-picker .rs-stack-item:last-child {
		width: 75px;
	}

	.date-picker .rs-stack-item:last-child svg {
		font-size: 18px;
	}

	.date-picker .rs-picker-toggle .rs-picker-toggle-placeholder {
		font-size: 16px;
	}

	.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
		font-size: 16px;
	}

	/* user-details-page */
	.user-details {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.user-details-title {
		padding: 20px 16px;
	}

	.user-details-title h5 {
		font-size: 20px;
	}

	.user-details-body {
		flex: 1;
		padding: 20px 16px;
	}

	.user-details-body img,
	.user-details-body .banners-preview {
		width: 92px;
		height: 92px;
	}

	.user-details-body .user-info {
		display: flex;
		flex-direction: column;
		margin-top: 16px;
	}

	.user-details-body .user-info .user-name {
		text-align: center;
	}

	.user-details-body .job-title {
		width: 162px;
		height: 48px;
		margin-bottom: 24px;
	}

	input,
	.user-details-body input,
	.user-details-body .password-type,
	.user-details-body .upload-user-image,
	.user-details-body .upload-user-image .upload-icon,
	.input_wrapper {
		height: 45px !important;
		font-size: 16px;
	}

	input::placeholder {
		font-size: 16px;
	}

	.user-details-footer {
		padding: 20px 16px;
		background-color: #f6f6f6;
	}

	.user-details-footer button {
		height: 45px;
		font-size: 20px;
	}

	/* order-page */
	.data-box .icon {
		width: 35px;
		height: 35px;
		padding: 10px;
	}

	.data-box .icon svg {
		width: 18px;
		height: 18px;
	}

	.data-box .data h4 {
		font-size: 22px;
		font-weight: 400;
	}

	.data-box .data p {
		font-size: 18px;
		font-weight: 300;
	}

	.filter-row {
		flex-direction: column;
		align-items: flex-start !important;
		padding: 0;
	}

	.filter-row .title-col {
		width: 100%;
		margin-bottom: 1rem;
	}

	.filter-row .filter-btn-col {
		width: 100%;
		margin-right: 0;
	}

	.date-picker .rs-picker-default .rs-picker-toggle.rs-btn {
		height: 45px;
	}

	.date-picker .rs-picker-default .rs-picker-toggle.rs-btn .rs-stack {
		height: 100%;
		position: relative;
	}

	.filter-wrapper .filter-select {
		height: 45px;
	}

	.MuiTableCell-root {
		font-size: 16px !important;
	}

	.MuiToolbar-root {
		flex-direction: column-reverse !important;
		align-items: flex-start !important;
	}

	.MuiToolbar-root .delete-all,
	.MuiToolbar-root .switch-all {
		font-size: 16px;
	}

	.MuiToolbar-root .delete-all {
		margin-right: 10px;
	}

	.select-table-row-wrapper .table-row-select .MuiSelect-icon {
		width: 35px;
		height: 35px;
		padding: 5px;
	}

	.order-toolbar .search-input-box {
		height: 46px;
		flex: 1;
		flex: auto;
	}

	.order-toolbar .search-input-box input {
		padding-right: 5px;
		font-size: 16px;
	}

	.order-toolbar .search-input-box input::placeholder {
		font-size: 12px;
	}

	.order-toolbar .select-input-box {
		flex: 1;
		height: 46px;
	}

	/*order-details*/
	.order-details-page .head-category h3 {
		font-size: 24px;
	}

	.order-details-page .head-category .order-number,
	#printableArea .row .order-number {
		margin-left: 0;
	}

	.order-details-page .order-number,
	#printableArea .row .order__number .order-number {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.order-details-page .head-category .order-number .title h5,
	#printableArea .row .order__number .order-number .title h5 {
		font-size: 16px;
	}

	.order-details-page .head-category .order-number .number,
	#printableArea .row .order__number .order-number .number {
		width: 98px;
		height: 35px;
		font-size: 16px;
		border-radius: 6px;
	}

	.order-details-body button.print-billing-btn {
		height: 35px;
		width: max-content;
		padding: 0 12px;
		font-size: 16px;
	}

	.order-details-body {
		width: 100%;
	}

	.order-details-body .order-details-box {
		padding: 16px;
	}

	.order-details-body .order-details-box .title h5 {
		font-size: 20px;
	}

	.order-details-body
		.order-details-box
		.order-details-data
		.order-head-row
		svg {
		width: 25px;
		height: 25px;
	}

	.order-details-body
		.order-details-box
		.order-details-data
		.order-head-row
		span {
		font-size: 18px;
		margin-right: 10px !important;
	}

	.order-details-body
		.order-details-box
		.order-details-data
		.order-data-row
		span {
		width: 100%;
		height: 45px;
		font-size: 20px;
	}

	.order-details-data .client-date {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.client-date img {
		width: 86px;
		height: 86px;
	}

	.order-details-data .client-date .text {
		margin-right: 12px;
	}

	.register-type {
		font-size: 14px;
	}

	.register-date {
		font-size: 16px;
	}

	.order-details-body .order-details-box .order-details-data .info-box {
		height: 45px;
	}

	.order-details-body .order-details-box .order-details-data .info-box svg {
		width: 24px;
		height: 24px;
	}

	.order-details-body .order-details-box .order-details-data .info-box span {
		width: 100%;
		font-size: 18px;
		text-align: center;
	}
	.order-details-body .order-details-box .order-action-box {
		padding: 16px 20px;
	}
	.order-details-body .order-details-box .order-action-box,
	.order-details-body .order-details-box .return-order-price-input,
	.order-details-body .order-details-box .refund-order-btn {
		height: 45px;
	}
	.return-order-wrapper {
		flex-wrap: wrap;
	}
	.order-details-body .order-details-box .return-order-price-input input {
		height: 100% !important;
	}

	.order-details-body .order-details-box .refund-order-btn {
		width: 100%;
	}

	.order-details-body .order-details-box .order-action-box.accordion-box {
		padding: 10px 20px;
		height: auto;
	}

	.order-details-body .order-details-box .order-action-box .action-title svg,
	.order-details-body .order-details-box .action-title .list-icon {
		width: 15px;
		height: 15px;
	}

	.order-details-body .order-details-box .order-action-box .action-title span {
		font-size: 18px;
	}

	.order-details-body .order-details-box .order-action-box .action-icon svg {
		width: 18px;
		height: 18px;
	}

	.products .input-group .search-input {
		width: 100%;
	}

	.products .input-group .search-input input {
		height: 45px;
		font-size: 16px;
	}

	.products .input-group .search-input svg {
		width: 18px;
		height: 18px;
		top: 16px;
	}

	.products .input-group .select-input {
		flex: 1;
		margin-left: 14px !important;
	}

	.products .input-group .select-input label {
		margin-bottom: 8px;
		font-size: 16px;
		font-weight: 500;
	}

	.products .input-group .select-input select {
		height: 45px;
		font-size: 18px !important;
	}

	.products .input-group .add-category-bt-box {
		flex: 1;
		height: 45px;
	}

	.seo-store-page
		.data-container
		.inputs-group
		.input
		.rti--container
		.rti--input,
	.add-new-product-form .rti--container .rti--input {
		width: 70%;
		height: 35px !important;
		padding: 0 10px !important;
	}

	.products .input-group .add-category-bt-box button {
		height: 100%;
		font-size: 14px;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}

	button {
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}

	.products .mange-file {
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		padding: 0;
		margin: 0;
	}

	.products .mange-file .export-upload-btn-group {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		margin-bottom: 1rem;
	}

	.products .mange-file .export-upload-btn-group .export-files {
		flex: 1;
		height: 45px;
		margin: 0;
	}

	.products .mange-file .export-upload-btn-group .export-files button {
		height: 100%;
		font-size: 16px;
	}

	.products .mange-file .export-upload-btn-group .upload-files {
		flex: 1;
		height: 45px;
		margin: 0;
	}

	.products .mange-file .export-upload-btn-group .upload-files label {
		height: 100%;
		font-size: 16px;
	}

	.products .mange-file .drop-files {
		width: 100%;
		height: 45px !important;
		font-size: 16px;
		margin-bottom: 1rem;
	}

	.products .mange-file .add-new-product {
		width: 100%;
		height: 45px;
	}

	.products .mange-file .add-new-product button {
		height: 100%;
		font-size: 16px;
	}

	/* add-product-from-store */
	.add-product-from-store {
		height: 100%;
		padding: 20px 16px !important;
	}

	.add-product-from-store h3 {
		font-size: 20px !important;
	}

	.add-product-from-store .content {
		margin-bottom: 20px !important;
	}

	.add-product-from-store .content p {
		font-size: 18px !important;
	}

	.add-product-from-store-footer button {
		height: 48px !important;
		font-size: 18px !important;
	}

	/* product options  */

	.add-form-wrapper form .add-new-cate-btn,
	.product-option-btn {
		font-size: 16px;
		font-weight: 500;
		height: 44px;
		padding: 10px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	.product-option-btn svg {
		font-size: 20px;
	}

	.product-options .active-options-switch .switch-label {
		height: 20px;
		font-size: 16px;
	}

	/*  show import product  */
	.product-options .options-section {
		padding: 25px 10px 20px;
	}

	.import-product-multi-images div,
	.import-product-multi-images .video_wrapper {
		width: 70px;
		height: 70px;
		padding: 10px;
	}

	.product-options .option-name-input,
	.product-options .option-name-input .MuiBox-root,
	.product-options .option-select-input,
	.product-options .option-name-input,
	.product-options .option-color-input {
		height: 45px;
	}

	.product-options .delete-option-section-icon {
		height: 25px;
		width: 25px;
		padding: 5px;
		top: -40px;
		left: -18px;
		position: absolute;
		align-items: center;
		justify-content: center;
		border: 1px solid #f2f5f7;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}

	.product-options .delete-icon svg#icon-24-delete {
		width: 20px;
	}

	.product-options .delete-option-section-icon svg#icon-24-delete {
		width: 18px;
	}

	.product-options .option-name-input .input-type,
	.product-options .option-color-input .input-type,
	.product-options .option-name-input span,
	.product-options .option-name-input .MuiBox-root {
		font-size: 14px !important;
	}

	.product-options .option-name-input .MuiBox-root div {
		width: 45px;
	}

	.product-options .options-section .add-new-value-btn,
	.product-options .add-new-option-section-btn {
		height: 44px;
		padding: 10px;
		font-size: 16px;
		font-weight: 500;
	}

	.product-options .add-product-image {
		font-size: 12px;
		font-weight: 500;
		padding: 0 3px;
	}

	.ProductImageModal-content {
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		padding: 6px;
		left: 11%;
		top: 100px;
	}

	.products-attr .MuiAccordionSummary-root {
		padding: 0 5px !important;
	}

	/* add-new-product */
	.form-title {
		padding: 20px 16px;
	}

	.form-title h5 {
		font-size: 20px !important;
	}

	.form-title p {
		font-size: 16px;
		white-space: break-spaces;
	}

	.add-form-wrapper .form-body {
		background-color: #f6f6f6;
		padding: 20px 16px;
	}

	.add-form-wrapper form label {
		font-size: 16px;
		margin-bottom: 10px;
	}

	.add-form-wrapper .add-image-btn-box {
		height: 140px;
		margin-bottom: 10px;
	}

	.add-form-wrapper .add-image-btn-box svg {
		width: 22px;
		height: 22px;
	}

	.add-form-wrapper .add-image-btn-box label {
		font-size: 16px;
		margin-bottom: 8px;
	}

	.add-form-wrapper .add-image-btn-box span {
		font-size: 14px;
	}

	.add-form-wrapper .banners-preview-container {
		width: 75px;
		height: 70px;
	}

	.add-new-product-form .add-product-images {
		width: 100px;
		height: 60px;
	}

	.add-form-wrapper .banners-preview-container.import-product-PreviewImage {
		width: 150px !important;
		height: 150px !important;
	}

	.add-form-wrapper .banners-preview-container .banner-preview {
		width: 75px;
		height: 70px;
	}

	.add-form-wrapper form input[type="text"],
	.add-form-wrapper form input[type="password"],
	.add-form-wrapper form input[type="email"] {
		height: 45px;
		padding: 5px 20px;
		font-size: 16px;
	}

	.add-form-wrapper form textarea {
		height: 110px;
		padding: 5px 20px;
		font-size: 16px;
	}

	.add-form-wrapper .form-footer {
		padding: 20px 16px;
	}

	.add-form-wrapper .form-footer button {
		height: 45px;
		font-size: 18px;
	}

	.MuiOutlinedInput-root {
		height: 45px;
		font-size: 16px !important;
	}

	.rti--input,
	.rti--tag {
		font-size: 16px !important;
	}

	.rti--tag:focus {
		outline: none !important;
	}

	.key-word-icons {
		right: 8px;
	}

	.MuiFormControl-root {
		font-size: 16px !important;
	}

	.MuiMenuItem-root {
		font-size: 16px !important;
	}

	/*  change product cateories  */
	.MuiToolbar-root .edit-all-categories-btn {
		margin: 10px auto;
		font-size: 16px;
		font-weight: 500;
	}

	.change-categories-modal-content {
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
	}

	.change-categories-modal-content .form-title {
		border-radius: 8px 8px 0 0 !important;
		-webkit-border-radius: 8px 8px 0 0 !important;
		-moz-border-radius: 8px 8px 0 0 !important;
		-ms-border-radius: 8px 8px 0 0 !important;
		-o-border-radius: 8px 8px 0 0 !important;
	}

	.change-categories-modal-content .form-title h5,
	.change-categories-modal-content label {
		font-size: 16px !important;
	}

	.product-options .option-info-label span,
	.change-categories-modal-content .option-info-label span {
		font-size: 14px;
	}

	.change-categories-modal-content .save-change-btn {
		height: 48px;
	}

	/* souqOtlobha-page */
	.souqOtlobha-page .total-products {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 45px;
	}

	.souqOtlobha-page .total-products .shipping-icon {
		width: 100px;
		height: 100%;
		font-size: 18px;
	}

	.souqOtlobha-page .total-products .text {
		width: 100%;
		height: 100%;
		font-size: 14px;
	}

	.souqOtlobha-page .btn-group button:first-child,
	.souqOtlobha-page .btn-group button:last-child {
		width: 100%;
		flex: 1;
		height: 45px;
		font-size: 16px;
	}

	.apply-btn {
		width: 100%;
		height: 45px;
		font-size: 18px;
	}

	.product-box {
		max-width: 100%;
		height: auto;
		padding: 12px;
	}

	.product-box img {
		width: 100%;
	}

	.ProductRefund-wrapper .product-category .input {
		height: 40px;
		padding: 4px 16px;
		font-size: 16px;
	}

	.ProductRefund-wrapper .product-category .tags {
		font-size: 16px;
	}

	.product-box .product-name,
	.product-box .product-category,
	.product-box .product-name span,
	.product-box .product-category span {
		font-size: 14px;
	}

	.product-box .product-category {
		padding: 0 6px;
	}

	.ProductRefund-wrapper .product-price .currency,
	.product-box .product-price .currency {
		height: 46px;
		font-size: 14px;
	}

	.product-price,
	.recovery-button {
		width: 100%;
		margin-bottom: 16px;
	}

	.product-box .product-price .input {
		width: 100%;
		height: 45px;
	}

	.product-box .recovery-button button {
		width: 100%;
	}

	.recovery-button button {
		font-size: 18px;
	}

	.souqOtlobha-page .pagination-box {
		width: 100%;
		max-width: 100%;
	}

	/* ProductRefund*/
	.ProductRefund-wrapper .container-row {
		padding: 0;
		margin-bottom: 1rem;
	}

	.ProductRefund-wrapper .product-title {
		font-size: 20px;
		text-align: center;
		margin: 0;
	}

	.product-images {
		flex-direction: column;
		justify-content: center;
	}

	.product-images .main-image img,
	.product-images .main-image video {
		width: 185px;
		height: 165px;
	}

	.product-images .another-image {
		flex-direction: row;
		justify-content: flex-start;
	}

	.ProductRefund-wrapper .product-category {
		width: 100%;
		height: auto;
		padding: 10px;
		max-width: 90%;
		margin: auto;
	}

	.ProductRefund-wrapper .product-category .label {
		font-size: 16px;
	}

	.ProductRefund-wrapper .product-price .label {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 0 !important;
	}

	.ProductRefund-wrapper .product-category .input {
		width: 100%;
		max-width: 85%;
	}

	.ProductRefund-wrapper .input.textarea {
		font-size: 18px;
	}

	.ProductRefund-wrapper .refund-btn {
		height: 46px;
		font-size: 18px;
	}

	.cart-page .actions .buttons input {
		font-weight: 400 !important;
	}

	.cart-page .actions .buttons.update-cart-btn {
		flex-direction: column-reverse;
	}

	.cart-page .actions .buttons.update-cart-btn button {
		width: 100%;
	}

	/* category */
	.add-category {
		width: 100%;
		padding: 16px 0px;
	}

	.add-category .input-group {
		gap: 1rem;
	}

	.search-input.input-box {
		width: 100%;
	}

	.input-box input,
	select {
		height: 45px;
		font-size: 16px;
	}

	.input-box select {
		font-size: 16px !important;
	}

	.input-box svg {
		font-size: 18px;
		top: 15px;
	}

	.input-box svg.filter-icon {
		top: 20px;
	}

	.select-input.input-box {
		flex: 1;
	}

	.select-input.input-box label {
		font-size: 16px;
	}

	.add-category-bt-box {
		flex: 1;
		width: 100%;
	}

	.select-input.input-box,
	.coupon-form .add-category .select-input {
		width: 100%;
	}

	.search-input-box .MuiTextField-root {
		height: 45px;
	}

	.rate-act-btn .MuiButtonBase-root.MuiButton-root {
		min-width: 45px;
	}

	.rate-act-btn .margin-right-lg {
		margin-right: 0;
	}

	.add-category-bt-box button {
		height: 45px;
		font-size: 16px;
	}

	.add-form-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.add-form-wrapper .form-h-full {
		min-height: calc(100vh - 96px);
		display: flex;
		flex-direction: column;
	}

	.add-form-wrapper form .add-new-cate-btn {
		font-size: 16px;
		height: 56px;
	}

	.add-form-wrapper form .form-footer {
		background-color: #f6f6f6;
		margin-top: auto;
	}

	/* offers-page */
	.add-offer-btn {
		width: 145px;
		height: 45px;
		font-size: 18px;
	}

	.offers-table table thead {
		height: 50px;
	}

	.tb-offer-title svg {
		width: 18px;
		height: 18px;
	}

	.tb-offer-title h4 {
		font-size: 18px;
	}

	.tb-offer-title p {
		font-size: 16px;
	}

	.offer-content {
		padding: 5px;
	}

	.offer-heading {
		font-size: 18px;
	}

	.offer-info {
		font-size: 18px;
	}

	.offer-calender svg {
		width: 18px;
		height: 18px;
		margin-right: 0;
	}

	.offer-calender span {
		font-size: 16px;
	}

	.MuiSwitch-root {
		width: 24px !important;
		height: 13px !important;
		padding: 0 !important;
		overflow: visible !important;
		margin-left: 5px;
		margin-right: 8px;
	}

	.MuiButtonBase-root.MuiSwitch-switchBase {
		padding: 0 !important;
		top: 2px !important;
		left: 2px !important;
	}

	.MuiSwitch-thumb {
		width: 9px !important;
		height: 9px !important;
		background-color: #ffffff !important;
		transform: none !important;
	}

	.MuiSwitch-root .MuiSwitch-track {
		width: 100% !important;
		height: 100% !important;
	}

	.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
		transform: translateX(10px) !important;
	}

	.toggle-offer-switch .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
		transform: translateX(10px) !important;
	}

	/* create-offer */
	.create-offer-form-wrapper {
		margin-bottom: 1rem;
	}

	.create-offer-form-wrapper .form-body {
		padding: 20px 16px;
	}

	.create-offer-form-wrapper .form-title {
		padding: 10px 12px;
	}

	.create-offer-form-wrapper .form-title h5 {
		font-size: 18px;
		margin: 0 !important;
	}

	.create-offer-form-wrapper .form-body .row-title h4 {
		font-size: 18px;
	}

	.create-offer-form-wrapper .form-body .row-title p {
		font-size: 15px;
		white-space: pre-wrap;
	}

	.create-offer-form-wrapper .radio-box .MuiSvgIcon-root {
		width: 20px;
	}

	.create-offer-form-wrapper .form-body .radio-btn-group .radio-box label {
		font-size: 16px;
	}

	.create-offer-form-wrapper .form-body .radio-btn-group {
		margin-right: -20px;
	}

	.MuiButtonBase-root.MuiRadio-root {
		padding: 5px;
	}

	.create-offer-form-wrapper label {
		font-size: 16px;
	}

	.create-offer-form-wrapper .MuiFormControl-root {
		height: 45px !important;
	}

	.create-offer-form-wrapper .form-body input {
		height: 45px;
		font-size: 16px;
	}

	.create-offer-form-wrapper .form-body input::placeholder {
		font-size: 16px;
	}

	.create-offer-form-wrapper .form-body input[type="text"],
	.create-offer-form-wrapper .form-body input[type="number"] {
		padding: 0;
		padding-right: 50px;
	}

	.create-offer-form-wrapper .form-body .input-icon {
		top: 35px;
		right: 30px;
	}

	.create-offer-form-wrapper .MuiFormControl-root #icon-24-chevron_down {
		right: 90% !important;
	}

	.create-offer-form-wrapper .form-body label .sub-label {
		font-size: 12px;
		margin-right: 5px;
	}

	.create-offer-form-wrapper .MuiFormControl-root .MuiInputBase-root {
		height: 45px !important;
	}

	.create-offer-form-wrapper .form-body .date-icon {
		width: 70px;
		height: 45px;
	}

	.create-offer-form-wrapper .form-body .date-icon svg {
		width: 24px;
	}

	.create-offer-form-wrapper .form-body .input-icon svg.quantity {
		width: 24px;
		height: 24px;
	}

	.create-offer-form-wrapper.offers-data .form-title h5 {
		font-size: 18px !important;
		margin-right: 10px !important;
	}

	.offers-data .form-body .offer-box {
		height: 120px;
	}

	.offers-data .form-body .arrow-icon svg {
		width: 24px;
		height: 24px;
	}

	.offers-data .form-body .arrow-icon {
		width: unset;
		left: 45%;
		right: auto;
		top: 44%;
		transform: translateX(-50%);
		transform: rotate(270deg);
		-webkit-transform: rotate(270deg);
		-moz-transform: rotate(270deg);
		-ms-transform: rotate(270deg);
		-o-transform: rotate(270deg);
	}

	.form-footer-btn .create-offer-btn {
		height: 45px;
		font-size: 18px;
	}

	/* .carts-page */
	.carts-page .search-input-box {
		justify-content: flex-start;
		padding: 0 8px;
		flex: 0.8;
		margin: 0 auto;
		gap: 4px;
		height: 45px;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}

	.carts-page .search-input-box input {
		height: 100%;
		font-size: 16px;
	}

	.user-cart-data .userData-container .container-body .user-name {
		text-align: right;
	}

	.user-cart-data .userData-container .container-body .user-info span {
		font-size: 16px;
	}

	.user-cart-data-wrapper
		.create-offer-row
		.discount-date-box
		.react-datepicker-popper {
		transform: translate(-300px, 0px) !important;
		-webkit-transform: translate(-300px, 0px) !important;
		-moz-transform: translate(-300px, 0px) !important;
		-ms-transform: translate(-300px, 0px) !important;
		-o-transform: translate(-300px, 0px) !important;
	}

	.user-cart-data-wrapper .create-offer-row .discount-date-box .date-icon {
		width: 45px;
		height: 55px;
	}

	.user-info-row {
		padding-left: 24px;
		gap: 0;
	}

	.user-cart-data {
		padding: 20px 16px;
	}

	.user-cart-data .userData-container .container-title {
		font-size: 16px;
		padding: 12px 10px;
	}

	.client-date {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
	}

	.tit-box {
		margin-left: 0.5rem;
	}

	.user-cart-data .userData-container .header,
	.user-cart-data .userData-container .products-details {
		padding: 10px;
	}

	.user-cart-data .userData-container .header .product,
	.user-cart-data .userData-container .header .count,
	.user-cart-data .userData-container .header .price,
	.user-cart-data .userData-container .header .total {
		font-size: 16px;
	}

	.products-details .product img {
		width: 30px;
		height: 30px;
	}

	.products-details .product span {
		font-size: 16px;
	}

	.products-details .count,
	.products-details .price,
	.products-details .total {
		font-size: 16px !important;
		text-align: center;
	}

	.user-cart-data-wrapper .create-offer-row {
		flex-direction: column;
	}

	.total-of-orders,
	.total-of-price {
		font-size: 17px;
	}

	.discount-type-radio-group {
		gap: 14px;
	}

	.create-offer-row .box {
		width: 100% !important;
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		/* margin: 0 !important;
		margin-bottom: 1rem; */
	}

	.create-offer-row .box label {
		font-size: 18px;
		/* margin-bottom: 10px; */
	}

	.create-offer-row .box input {
		width: 100% !important;
		height: 45px !important;
		font-size: 16px;
	}

	.user-cart-data .send-offer-btn button {
		height: 45px;
		font-size: 18px;
	}

	.discount-radio-box,
	.last_one {
		margin-right: -16px !important;
	}

	/* seo-page */
	.seo-weight-edit-box .title {
		padding: 20px 16px;
	}

	.seo-weight-edit-box .MuiFormControl-root {
		padding: 0 !important;
	}

	.seo-weight-edit-box .input-bx.banners-box,
	.seo-weight-edit-box .input-bx.banners-box.first-one {
		width: 100%;
		margin: 0px -120px;
	}

	.seo-weight-edit-box .css-a5ci81-MuiFormLabel-root {
		font-size: 18px !important;
	}

	.seo-weight-edit-box .seo-form-check-label {
		font-size: 18px !important;
	}

	.seo-weight-edit-box button.MuiButtonBase-root {
		height: 45px;
		font-size: 18px;
	}

	.seo-form-check {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
	}

	.seo-form-check-label {
		display: flex;
		flex-direction: column;
	}

	.seo-weight-edit-box .seo-form-check-label .link {
		font-size: 16px;
		white-space: pre-wrap;
	}

	.upload_og_image {
		width: 100%;
	}

	.upload_image_box {
		height: 140px;
		width: 100%;

		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}
	.og_image_preview_box {
		width: 100%;
		max-height: 200px;
	}

	/* add-page */
	.add-page-btn {
		width: 100%;
		height: 48px;
	}

	.add-page-btn button {
		height: 100%;
		font-size: 18px;
	}

	.create-pages-form-wrapper .form-title,
	.create-pages-form-wrapper .form-body {
		padding: 20px 16px !important;
	}

	.create-pages-form-wrapper .form-title h5 {
		font-size: 18px;
		margin-bottom: 0 !important;
	}

	.create-pages-form-wrapper .form-body .seo-inputs h4 {
		font-size: 18px;
	}

	.seo-inputs label {
		font-size: 16px;
	}

	.create-pages-form-wrapper .form-body input {
		height: 45px;
		font-size: 16px;
	}

	.create-pages-form-wrapper .form-body input::placeholder {
		font-size: 16px;
	}

	.create-pages-form-wrapper .form-body .seo-inputs .input-icon {
		top: 33px;
	}

	.create-pages-form-wrapper .form-body .check-box-inputs .wrapper .title {
		padding: 20px 16px;
	}

	.create-pages-form-wrapper .form-body .check-box-inputs .wrapper .title h4 {
		font-size: 18px;
	}

	.create-pages-form-wrapper
		.form-body
		.check-box-inputs
		.wrapper
		.body
		.checkbox-row {
		padding: 5;
	}

	.create-pages-form-wrapper .form-body .check-box-inputs .wrapper {
		height: auto;
	}

	.create-pages-form-wrapper .form-body .check-box-inputs .wrapper button {
		height: 45px;
		font-size: 18px;
	}

	.create-pages-form-wrapper .form-body .check-box-inputs .wrapper input {
		height: 45px;
		font-size: 16px;
		padding: 20px 16px;
	}

	.create-pages-modal .form-footer-btn {
		padding: 20px 16px;
	}

	.create-pages-modal .form-footer-btn .create-page-btn {
		height: 45px;
		font-size: 18px;
	}

	.pages-search-bx .MuiInputBase-root,
	.pages-filters-bx .form-select {
		height: 45px;
	}

	.pages-filters-bx label {
		top: 10px;
	}

	.create-pages-form-wrapper .form-body .input-icon {
		top: 7px;
		right: 15px;
	}

	.create-pages-form-wrapper .form-body input {
		padding-right: 38px;
	}

	/* academy-page */
	.academy-page .btns-group-container button {
		height: 60px;
		width: 100%;
		font-size: 18px;
	}

	.academy-widget {
		min-height: 125px;
		height: auto;
	}

	.video-preview {
		width: 100%;
		max-width: 100%;
		max-height: 100%;
		border-radius: 0 4px 4px 0px;
		-webkit-border-radius: 0 4px 4px 0px;
		-moz-border-radius: 0 4px 4px 0px;
		-ms-border-radius: 0 4px 4px 0px;
		-o-border-radius: 0 4px 4px 0px;
	}

	.video-preview .img-wrapper {
		width: 100%;
		max-width: 100%;
		max-height: 100%;
	}

	.academy-widget .course-bx {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		padding-top: 9px;
		margin-right: 0px;
		padding-bottom: 9px;
	}

	.course-figure .course-prev-image {
		height: 100%;
		max-height: 100%;
	}

	.course-figure .figure-caption {
		font-size: 16px;
		padding: 8px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.course-figure .play-video-icon {
		position: absolute;
		top: 50%;
		left: 50%;
	}

	.explain-details-page .explain-video,
	.course-details-video-modal {
		width: 100%;
		height: 218px;
	}

	.academy-widget .course-details h5 {
		font-size: 16px;
		margin-bottom: 8px !important;
	}

	.academy-widget .watch-course-btn {
		padding: 0;
		padding-top: 8px;
	}

	.academy-widget .watch-course-btn .btn-wrapper {
		width: 100%;
	}

	.academy-widget .course-info .text {
		font-size: 12px;
		font-weight: 400;
	}

	.academy-widget .watch-course-btn button {
		font-size: 14px;
		width: 100%;
		height: 30px;
	}

	.academy-widget .watch-course-btn button svg {
		font-size: 20px;
	}

	/* course-details-page */
	.course-details-page .course-actions .course-name h4 {
		font-size: 20px;
		line-height: 26px;
	}

	.course-details-page .course-actions .course-unites div .unite-name {
		font-size: 16px;
	}

	.accordion-box .accordion-body ul li {
		padding: 5px 0;
	}

	.course-details-page .course-actions .course-unites div .course-count,
	.course-details-page .course-actions .course-unites div .course-time {
		font-size: 14px;
	}

	.course-details-page .course-actions .course-unites span {
		font-size: 16px;
	}

	.course-details-page .course-image {
		width: 100%;
	}

	/* template-page */
	.template-edit-box h4 span {
		font-size: 14px;
		white-space: pre-wrap;
		margin-right: 10px;
	}

	.banners-preview-container {
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	.banners-box .hint {
		margin-right: 98px;
	}

	.banners-preview-container img {
		width: 100%;
		max-width: 100%;
		object-fit: contain;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	.add-banners-bts-wrapper label {
		font-size: 16px;
		padding: 0;
		margin-bottom: 8px;
	}

	.template-edit-box .upload-files-input {
		height: 40px;
		font-size: 16px;
	}

	.view-more-btn a {
		font-size: 16px;
	}

	.widget-text svg {
		width: 20px !important;
		height: 20px !important;
	}

	.other-shipping-company {
		padding: 16;
	}

	.other-shipping-company .data-widget {
		box-shadow: none;
		margin-bottom: 20px;
		border: 1px solid #f5f5f5;
	}

	.widget-text span {
		font-size: 16px;
	}

	/* support */
	.SupportDetails-page .head-category {
		padding: 16px 20px;
	}

	.SupportDetails-page .issue-wrapper {
		padding: 16px 20px;
	}

	.SupportDetails-page .issue-wrapper .issue-number {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}

	.SupportDetails-page .issue-wrapper .issue-number h5 {
		font-size: 18px;
		margin: 0;
	}

	.SupportDetails-page .issue-wrapper .issue-number div {
		width: 165px;
		height: 45px;
		font-size: 18px;
	}

	.SupportDetails-page .issue-wrapper .issue-details-box {
		width: 100%;
		padding: 12px 10px;
	}

	.SupportDetails-page .issue-wrapper .issue-details-box .box.label-box {
		width: 100%;
		height: auto;
		font-size: 16px;
		padding: 0;
		margin-bottom: 10px;
	}

	.SupportDetails-page .issue-wrapper .issue-details-box .box.label-box svg {
		width: 20px;
		height: 20px;
	}

	.SupportDetails-page .issue-wrapper .issue-details-box .box {
		width: 100%;
		height: 50px;
		font-size: 18px;
		padding: 16px 20px;
	}

	.SupportDetails-page .issue-content,
	.SupportDetails-page .close-btn {
		width: 100%;
	}

	.SupportDetails-page .close-btn button {
		width: 165px;
		height: 45px;
		font-size: 18px;
	}

	/* reports-page */
	.reports-page .print-report-btn-box {
		width: 100%;
	}

	.reports-page .print-report-btn-box button {
		width: 100%;
		height: 100%;
	}

	.reports-page .select-report-links {
		padding: 0;
	}

	.reports-page .select-report-links .nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
	}

	.reports-page .select-report-links .nav-item {
		flex: 1;
		width: 165px;
		height: 45px;
	}

	.reports-page .select-report-links button {
		width: 100%;
		height: 100%;
		font-size: 18px;
	}

	.sales-reports-data,
	.customers-reports-data {
		width: 100%;
	}

	.sales-reports-data .report-body,
	.customers-reports-data .report-body {
		padding: 12px 10px;
	}

	.report-body h5 {
		margin: 0;
		font-size: 16px;
	}

	.customers-BarChart {
		width: 100%;
		margin: 0;
	}

	.customers-BarChart .recharts-legend-wrapper {
		width: 100% !important;
		right: 0 !important;
		top: 10px !important;
	}

	.customers-BarChart .recharts-legend-wrapper ul li {
		margin: 0 !important;
	}

	.customers-BarChart .recharts-legend-item-text {
		font-size: 14px;
		margin-left: 10px;
	}

	.css-8a32wk-MuiFormControlLabel-root .MuiFormControlLabel-label {
		margin-right: 10px;
	}

	/* rating-page */
	.rating-page .user-info {
		width: 95%;
		height: 100%;
		padding: 6px 8px;
	}

	.MuiAvatar-root {
		width: 30px !important;
		height: 30px !important;
	}

	.rating-page .user-info .user-name {
		font-size: 14px;
	}

	.rating-page .user-info .text {
		font-size: 14px;
	}

	.order-rate-number {
		font-size: 16px;
		padding: 20px 0 0 20px;
	}

	.rating-page .rating-widget {
		height: auto;
	}

	.ratin-bx {
		max-width: 100%;
		margin: 0;
		padding: 0;
	}

	.rates-starts {
		max-width: 100%;
		padding: 0;
	}

	.rates-starts .start {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.rates-starts .start svg {
		width: 16px;
		height: 16px;
	}

	.ratin-bx .rates-starts h6 {
		font-size: 16px;
		white-space: pre-wrap;
	}

	.rating-page .product-name span {
		font-size: 14px;
		font-weight: 400;
	}

	.rate-act-btn {
		max-width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		margin-top: 0.5rem;
	}

	.rate-act-btn button span {
		display: none;
	}

	.rate-act-btn button svg {
		font-size: 16px !important;
		width: 16px;
		height: 16px;
	}

	/* send-replay-modal */
	.send-replay-modal h3 {
		height: 60px !important;
	}

	/* Courses  */

	/* main-info-form */
	.main-info-form {
		padding: 16px 20px;
	}

	.setting_label {
		font-size: 16px;
	}

	.setting_label .phone-hint {
		display: block;
	}

	.upload-icon-hint {
		font-size: 14px;
	}

	.select-country .form-select,
	.store-desc,
	.modal-input-group input,
	.modal-input-group textarea {
		font-size: 16px !important;
	}

	/* maintenance-modal */
	.maintenance-modal-header,
	.maintenance-modal-body,
	.maintenance-modal-footer {
		padding: 16px 20px;
	}

	.maintenance-modal-header span {
		font-size: 18px;
	}

	.maintenance-modal-body .modal-body-header .modal-desc {
		font-size: 14px;
	}

	.maintenance-modal-form {
		width: 100%;
	}

	.maintenance-modal-form .modal-input-group label {
		font-size: 16px;
	}

	.maintenance-modal-form .modal-input-group input {
		height: 45px !important;
	}

	.modal-input-group .modal-input-icon {
		top: 38px;
	}

	.management-page .add-page-btn button {
		height: 45px;
		font-size: 16px;
	}

	.management-page .add-page-btn button svg {
		font-size: 20px;
	}

	.management-page .add-page-btn button span {
		margin-right: 2px !important;
	}

	.management-page .add-page-btn .roles-btn {
		width: 100%;
	}

	.management-page .table-filter .filter-row {
		padding: 10px 12px;
	}

	.management-page .table-filter .filter-row .filter-user-selector label {
		font-size: 18px;
	}

	.filter-user-input-group .search-icon {
		right: 13px;
	}

	.filter-user-input-group .search-icon svg {
		width: 17px;
	}

	.management-page .table-filter .filter-row .filter-user-input-group input {
		height: 45px;
		padding: 0 35px 0 20px;
	}

	.management-page
		.table-filter
		.filter-row
		.filter-user-input-group
		.search-icon {
		top: 38px;
	}

	/* add-user */
	.add-user-form .form-body .input-icons {
		top: 5px;
		right: 20px;
	}

	.add-user-form .form-body input {
		padding-right: 40px !important;
	}

	.add-user-form .form-body .upload-personal-image {
		height: 45px;
		padding: 10px 12px;
		font-size: 16px;
	}

	.add-user-form .form-body .upload-personal-image span {
		width: 30%;
		font-size: 16px;
	}

	.add-user-form .form-body .input-icons.password-icon {
		right: 88%;
	}

	/* job-title-page */
	.job-title-page .header-row {
		width: 100%;
		margin: 0;
	}

	.job-title-page .job-titles-wrapper {
		width: 100%;
		padding: 16px 20px;
		margin: 0;
		background-color: transparent;
	}

	.job-title-page .form-title {
		padding: 16px 20px;
	}

	.job-title-page .header-row .create-job-title-btn {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.job-title-page .job-titles-wrapper .job-box .job-name {
		font-size: 18px;
	}

	.job-title-page .job-titles-wrapper .job-box .job-btn-group {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	.job-title-page .job-titles-wrapper .job-box .job-btn-group button {
		width: 110px;
		height: 45px;
		font-size: 16px;
	}

	.job-title-page .header-row .create-job-title-btn button,
	.job-title-page .close-btn button {
		width: 162px;
		height: 45px;
		font-size: 18px;
	}

	.edit-job-title {
		width: 100%;
		margin: 0;
		padding: 16px 20px;
	}

	.edit-job-title .page-wrapper {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	.edit-job-title .page-header span {
		display: flex;
		flex-direction: column;
		font-size: 20px;
		text-align: center;
	}

	.edit-job-title .page-header .search .save-btn,
	.edit-job-title .page-header .search input {
		height: 45px;
		font-size: 18px;
	}

	.edit-job-title .page-header .search .input-icon {
		top: 8px;
	}

	.edit-job-title .breadcrumb {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		margin-bottom: 0;
	}

	.edit-job-title .breadcrumb .breadcrumb-item {
		font-size: 14px;
	}

	.edit-job-title .breadcrumb .breadcrumb-item img {
		width: 20px;
		height: 20px;
	}

	.create-role-tables .MuiFormControlLabel-label {
		font-size: 18px !important;
		margin-right: 10px;
		margin-bottom: 8px;
	}

	.create-role-wrapper {
		padding: 16px 20px;
	}

	.create-role-wrapper .search-wrapper,
	.create-role-wrapper .create-role-title {
		padding: 0;
	}

	.create-role-wrapper label,
	.create-role-wrapper .create-role-title span {
		font-size: 18px;
	}

	.create-role-wrapper input {
		width: 100%;
		height: 45px;
		padding: 16px 20px;
	}

	.create-role-wrapper .create-role-tables {
		width: 100%;
		background-color: transparent !important;
		box-shadow: none;
		padding: 0;
	}

	/* notifications */
	.notifications {
		padding: 0 14px;
	}

	.notifications .page-title {
		font-size: 18px;
	}

	.notifications .notifications-title {
		max-width: 300px;
		font-size: 18px;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.notification-user-name {
		font-size: 16px;
	}

	.notification-time {
		font-size: 12px;
	}

	/* VerifyStoreModal */
	.verify-message-box .verify-message {
		margin: 0;
		font-size: 15px;
		white-space: pre-wrap;
	}

	.verify-message-box .btns-box {
		width: 100px;
		flex-direction: column-reverse;
		align-items: flex-end;
	}

	.verify-message-box .btns-box a {
		width: 100%;
		height: 35px;
		font-size: 15px;
	}

	.verify-message-box .btns-box svg {
		margin-bottom: 8px;
	}

	.store-alert-body {
		font-size: 18px !important;
		padding: 50px 20px !important;
	}

	/* social-links-page */
	.social-links-form {
		background-color: #f4f5f7;
		padding: 10px;
	}

	.social-links-form label {
		font-size: 16px;
	}

	.social-links-form input {
		width: 100%;
		height: 45px;
		font-size: 16px;
		margin-bottom: 1rem;
		box-shadow: none !important;
		direction: ltr;
	}

	/* postponed-page-title */
	.postponed-page-title {
		height: calc(100vh - 140px);
		font-size: 50px;
	}

	/* shipping-page */
	.data-container {
		padding: 16px 20px;
	}

	.coupon-form .add-category .search-input {
		width: 100%;
	}

	.add-coupon-form .radio-box {
		margin-bottom: 8px;
	}

	.add-coupon-form .radio-box:first-child,
	.add-coupon-form .radio-box.select-apply-offer {
		margin: 0;
		margin-left: 10px;
	}

	.add-coupon-form .search-icon {
		top: 12px;
	}

	.add-coupon-form .form-body .radio-box label {
		font-size: 18px;
		margin: 0;
	}

	.add-coupon-form .date-icon {
		height: 45px;
	}

	/*  payemnt */

	.data-container.wallet-data-container {
		padding: 16px;
	}

	.payment-form input,
	.payment-form .MuiInputBase-root {
		height: 45px !important;
		font-size: 16px !important;
	}

	.payment-page .current-price {
		padding-bottom: 10px;
		font-size: 14px;
	}

	.payments-hint button {
		width: 100%;
	}

	/* wallet  */

	.add-bank-Account form .inputs-wrapper input::placeholder,
	.add-bank-Account form .inputs-wrapper .tax-text {
		font-size: 13px;
	}

	.add-bank-Account form .inputs-wrapper .sa-iban-hint {
		font-size: 14px;
		padding: 0 12px 0 0;
	}

	.add-bank-Account .form-body {
		padding: 18px;
		border-radius: 9px;
		-webkit-border-radius: 9px;
		-moz-border-radius: 9px;
		-ms-border-radius: 9px;
		-o-border-radius: 9px;
	}

	.bank-accounts .bank-acc-title {
		font-size: 18px;
		font-weight: 400;
	}

	.add-bank-Account form .inputs-wrapper {
		height: 45px;
		padding: 16px 0px 16px 12px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		gap: 5px;
	}

	.add-bank-Account form .inputs-wrapper .svg-container {
		margin-right: 12px;
	}

	.add-bank-Account form label {
		font-size: 16px;
	}

	.bank-accounts button {
		width: 100%;
		padding: 10px 12px;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 400;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	.add-bank-Account .form-footer button,
	.add-form-wrapper .form-footer button {
		height: 48px;
		font-size: 16px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	.add-coupon-form .coupon-status-wrapper,
	.add-coupon-form.coupon-details .form-body {
		padding: 16px 20px;
	}

	.add-coupon-form .coupon-status-wrapper .coupon-status {
		width: 150px;
		height: 45px;
		font-size: 18px;
		margin: auto;
	}

	.add-coupon-form .coupon-status-wrapper .enable-coupon-btn {
		width: 100%;
		font-size: 18px;
		text-align: center;
	}

	.customer-page .add-category .search-input {
		width: 100%;
	}

	.add-customer-form .form-body {
		padding: 20px 16px !important;
	}

	.add-customer-form .add-image-btn-bx label {
		font-size: 18px;
		margin-bottom: 0;
	}

	/* platform-services-page */
	.PlatformServices-page .delegate-request-btn {
		width: 150px;
		height: 45px;
		font-size: 18px;
	}

	.PlatformServices-page .delegate-request-form {
		padding: 20px 16px;
	}

	.PlatformServices-page .delegate-request-form .form-name {
		font-size: 18px;
		text-align: center;
	}

	.PlatformServices-page .delegate-request-form label {
		font-size: 16px;
	}

	.PlatformServices-page .delegate-request-form input {
		height: 45px;
		padding: 14px;
		font-size: 16px;
	}

	.PlatformServices-page
		.delegate-request-form
		.store-activity-input
		.activity {
		height: 30px;
		font-size: 16px;
	}

	.PlatformServices-page .delegate-request-form .new-service-hint,
	.PlatformServices-page
		.delegate-request-form
		input.new-service-input::placeholder {
		font-size: 14px;
	}

	.PlatformServices-page .delegate-request-form .upload-request-btn {
		width: 100%;
		height: 45px;
		font-size: 18px;
	}

	/* delegate-request-alert */
	.delegate-request-alert {
		padding: 16px 20px !important;
	}

	.delegate-request-alert span {
		font-size: 20px;
		white-space: pre-wrap;
	}

	/* delegate-page */
	.delegate-page .select-delegate {
		height: auto;
		padding: 16px 20px;
	}

	.select-delegate-title {
		font-size: 16px;
	}

	/* verify-page */
	.verify-tab-bx {
		width: 100%;
		height: 90px;
	}

	.next-btn,
	.social-save-btn,
	.prev-btn {
		height: 45px;
		font-size: 18px !important;
	}

	.verify-store-page .MuiFormControl-root {
		margin: 0;
		margin-top: 1rem;
	}

	.verify-store-page .MuiFormControl-root .MuiSelect-icon {
		right: 90% !important;
	}

	.verify-store-page .row-input-group {
		height: auto;
		right: 0;
	}

	/* main info */
	.main-info-form .upload-image-wrapper {
		height: 140px;
		border-radius: 4px;
		margin-bottom: 10px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	.upload_image_hint {
		font-size: 0.7rem;
	}

	.main-info-form .upload-log-btn,
	.upload-icon-btn {
		height: 45px;
		font-size: 16px;
	}
	.domain-wrapper {
		padding: 16px 10px;
	}
	.domain-name .domain-input-box {
		height: 46px;
		font-size: 16px;
	}
	.dns_info_label__info-wrapper {
		margin-left: -30px;
	}

	.domain-name .domain-input-box input {
		padding: 0 0px 0 16px !important;
	}

	.domain-name,
	.domain-name .main-domain-hint,
	.store-email-input {
		font-size: 16px !important;
	}

	.domain-name .main-domain-hint,
	.domain-name input {
		height: 45px !important;
	}

	.select-country .store-desc {
		height: 120px !important;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}

	.choose-time {
		flex-direction: column;

		justify-content: center;
	}

	.choose-time .time-input {
		width: 100%;
		max-width: 100%;
		padding: 0 9px;
		height: 45px;
	}

	.save-work-hours {
		height: 45px;
		font-size: 18px;
		border-radius: 6px;

		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}

	/* verification alert style */
	.verification-alert-btns button {
		width: 100%;
		font-size: 20px;

		height: 46px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}

	.select-icon {
		top: 7px;
	}

	.support-table .support-title {
		max-width: 200px;
	}

	.pm-am {
		font-size: 16px !important;
	}

	/* cart-page */
	.cart-page .card .card-body {
		padding: 1.375rem;
	}

	.cart-page h3 {
		font-size: 26px;
		margin-bottom: 1.5rem;
	}

	.other-shipping-company .shipping-price-hint {
		background: transparent;
		justify-content: start;
	}

	.other-shipping-company .shipping-price-input-box {
		height: 48px;
	}

	.other-shipping-company .shipping-price {
		font-size: 16px;
	}

	.other-shipping-company .shipping-price-input-box .currency {
		font-size: 16px;
	}

	.other-shipping-company .save-price-btn {
		height: 48px;

		font-size: 18px;
		font-weight: 500;
	}

	.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean svg {
		top: 16px;
		left: -115px;
	}

	.option-color-input .MuiToggleButton-root svg {
		width: 0.5em;
		height: 0.5em;
	}
}

@media (max-width: 576px) {
	h3,
	h4 {
		font-size: 16px;
	}

	h5,
	h6 {
		font-size: 14px;
	}

	.line-chart-tab-pane {
		width: 555px;
		/* height: 100px; */
	}

	.filter-row .filter-btn-col {
		margin-top: 0.5rem;
	}

	/* order-details-page */
	.order-details-page .head-category h3 {
		font-size: 20px;
	}

	/* products-page */
	.products .input-group .add-category-bt-box button {
		font-size: 14px;
	}

	.rdw-option-wrapper img {
		width: 15px;
		height: 15px;
	}

	/* shipping-page */
	.data-widget {
		height: 150px;
	}

	.data-widget .data {
		height: 130px;
	}

	.data-widget .switch-box {
		height: 30px;
	}

	.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
		right: 230px !important;
	}

	/* payment-page */
	.payment-page .image-box {
		padding: 10px;
	}

	.rs-picker-daterange-calendar-group {
		width: 100%;
		min-width: 100% !important;
		height: auto !important;
		display: flex;
		flex-direction: column;
	}

	.rs-picker-daterange-menu .rs-calendar:first-child {
		border-right: none !important;
	}

	.rs-picker-menu .rs-calendar {
		margin: 0 !important;
	}

	.rs-picker-daterange-menu .rs-calendar {
		height: auto;
		margin: 0;
	}

	.react-datepicker__triangle {
		left: -12px !important;
	}

	.seo-weight-edit-box .input-bx.banners-box,
	.seo-weight-edit-box .input-bx.banners-box.first-one {
		width: 559px;
		margin: 0 -119px;
	}

	.add-category .input-group {
		flex-direction: column;
		padding: 12px;
	}

	.products .input-group .select-input {
		width: 100%;
		margin: 0 !important;
	}

	.products .input-group .add-category-bt-box {
		flex: none;
		width: 100%;
	}

	.products .input-group .select-input .MuiSelect-icon,
	.MuiSelect-icon {
		right: 92% !important;
	}

	.react-datepicker-popper,
	.add-coupon-form .react-datepicker-popper {
		transform: translate(0px, 0px) !important;
		-webkit-transform: translate(0px, 0px) !important;
		-moz-transform: translate(0px, 0px) !important;
		-ms-transform: translate(0px, 0px) !important;
		-o-transform: translate(0px, 0px) !important;
	}

	.rdw-image-modal {
		left: -124px;
	}

	.work-day {
		min-height: auto !important;
	}

	.banners-preview-container {
		height: auto;
	}

	.paint-store-page .data-container {
		padding: 0;
	}

	.paint-store-page .data-container .MuiAccordionDetails-root .content {
		margin-right: 0;
	}

	.paint-store-page .data-container .MuiAccordionDetails-root .buttons h6 {
		margin-right: 0;
	}

	.paint-store-page .data-container .MuiAccordionDetails-root button {
		height: 48px;
		width: 140px;
		font-size: 20px;
	}

	.paint-store-page .data-container .MuiAccordionDetails-root .reset {
		font-size: 0.8rem;
	}

	.cart-page .card .card-body .checkout-btn {
		font-size: 1.125rem;
		font-weight: 500;
		height: calc(2.75rem + 2px);
		line-height: 1;
		padding: 0.8125rem 2rem;
	}

	.cart-page .card .card-body table tfoot th {
		font-size: 1.1rem;
	}

	.cart-page .card .card-body table td,
	.cart-page .card .card-body table th {
		font-size: 0.9rem;
	}

	.checkout-page .block .card .card-body .card-title {
		font-size: 24px;
	}

	.checkout-page
		.block
		.card
		.card-body
		.checkout-totals
		tfoot
		tr:first-child
		> * {
		font-size: 1.4rem;
	}

	.explain-boxes .box {
		width: 100%;
	}

	.explain-boxes .box .course-figure .figure-caption {
		font-size: 1rem;
		padding: 10px;
	}

	.notifications .notifications-title {
		width: 185px;
	}

	.products .filters-btn,
	.category .filters-btn {
		gap: 0.5rem;
		padding: 1rem 0px;
	}

	.products .filters-btn .btn,
	.category .filters-btn .btn {
		flex: 1;
		min-width: 130px;
		height: 42px;
		font-size: 0.9rem;
	}

	.read-more-modal .modal {
		width: 90%;
		top: 100px;
	}

	.option-color-input .MuiToggleButton-root svg {
		width: 0.5em;
		height: 0.5em;
	}
}

@media (max-width: 420px) {
	.video-preview .img-wrapper {
		align-items: flex-start;
	}

	.video-preview img {
		object-position: center;
	}

	.academy-widget .watch-course-btn {
		position: relative;
		width: 100%;
	}

	.academy-widget .watch-course-btn .btn-wrapper {
		width: max-content;
	}

	.academy-widget .watch-course-btn span {
		font-size: 0.8rem;
	}

	.rdw-image-modal {
		left: -75px;
	}

	.cart-page h3 {
		font-size: 1.2rem;
	}

	.cart-page .card .card-body .checkout-btn {
		font-size: 1rem;
		font-weight: 500;
		height: calc(2.25rem + 2px);
		line-height: 1.5;
		padding: 0.375rem 1.25rem;
	}

	.checkout-page .block .card .card-body .card-title {
		font-size: 20px;
		margin-bottom: 0.5rem;
	}

	.checkout-page .block .card .card-body .checkout-totals th {
		font-size: 0.8rem;
	}

	.checkout-page .block .card .card-body .checkout-totals td {
		font-size: 0.8rem;
	}

	.checkout-page
		.block
		.card
		.card-body
		.checkout-totals
		tfoot
		tr:first-child
		> * {
		font-size: 1rem;
	}

	.tax-text {
		font-size: 0.6rem;
		white-space: normal;
	}

	.add-bank-Account form .inputs-wrapper .tax-text {
		font-size: 13px;
	}

	.explain-boxes {
		gap: 1.4rem;
	}

	.explain-boxes .box {
		width: 100%;
		height: 320px;
	}

	.seo-weight-edit-box .title h4 {
		font-size: 20px;
		line-height: 22px;
	}

	.seo-weight-edit-box .title .sub_title {
		font-size: 15px;
		line-height: 26px;
	}

	.notifications-table .notification-box {
		flex-wrap: wrap;
	}

	.notifications-table .notification-box .time-delete {
		height: auto !important;
		display: flex;
		flex-direction: row !important;
		justify-content: space-between !important;
		align-items: center !important;
	}

	.user-cart-data .send-offer-btn span {
		font-size: 0.9rem;
	}

	.user-cart-data .send-offer-btn button svg {
		width: 20px;
		height: 20px;
	}
}

@media (max-width: 360px) {
	.academy-widget {
		padding: 8px;
	}

	.video-preview .img-wrapper {
		width: 215px;
		max-width: 215px;
		height: 100%;
		max-height: 160px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.video-preview img {
		max-height: 160px;
		object-fit: contain;
		object-position: center;
	}

	.video-preview .play-video-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
	}

	.video-preview .play-video-icon svg {
		font-size: 24px;
	}

	.academy-widget .course-bx {
		justify-content: space-evenly;
	}

	.academy-widget .watch-course-btn {
		padding: 5px;
	}

	.academy-widget .watch-course-btn span {
		font-size: 0.7rem;
	}

	.rdw-image-modal {
		left: -39px;
	}

	.delegate-request-alert {
		padding: 10px !important;
	}

	.day-switch {
		width: 90px;
		height: 28px;
	}

	.choose-time {
		flex-wrap: wrap;
		justify-content: center;
	}

	.choose-time .time-input {
		font-size: 15px !important;
		width: 110px !important;
		height: 40px;
	}

	.notifications .notifications-title {
		width: 150px;
	}

	.video-hours {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.cart-page .actions .buttons {
		flex-direction: column;
	}

	.cart-page .actions .buttons button {
		width: 100%;
	}
}

@media (max-width: 320px) {
	.products .filters-btn .btn,
	.category .filters-btn .btn {
		min-width: 100px;
		font-size: 0.8rem;
	}
}

.offer-currency {
	background-color: #eff9ff;
	height: 100%;
	padding: 10px 30px;
	border: 1px solid #ececec;
	position: absolute;
	left: 0;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.purchase_serach {
	max-height: 130px;
	overflow-y: auto;
	margin-top: 1rem;
	background-color: #eee;
	border-radius: 5px;
}

.purchase_serach li {
	padding: 5px 15px;
	cursor: pointer;
	transition: all 0.3s;
}

.purchase_serach li:hover {
	background-color: #f0fffe;
}

.purchase_products_selected {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
}

.purchase_products_selected li {
	padding: 5px 0;
}

@media (min-width: 1600px) {
	.add-banners {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.seo-weight-edit-box .input-bx.banners-box {
		width: 642px;
		max-width: 100%;
		margin: 0px 108px;
	}

	.seo-weight-edit-box .input-bx.banners-box.first-one {
		margin: 0px 90px;
	}

	.apply-btn {
		width: 100%;
	}

	.input-bx.banners-box.first-one,
	.input-bx.banners-box {
		margin: auto !important;
	}

	.input-bx.banners-box.first-one .banners-preview-container {
		margin: 0 85px;
	}

	.input-bx.banners-box .banners-preview-container {
		margin: 0 90px;
	}

	.explain-boxes .box {
		width: 290px;
		height: 350px;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.charts .comp-title h4 {
		font-size: 18px;
	}

	.analyse-box h5 {
		font-size: 14px;
	}

	.analyse-box p {
		font-size: 18px;
	}

	.analyse-box p .currency {
		font-size: 14px;
	}

	.analyse-box svg {
		font-size: 18px;
	}

	.rating-page .user-info {
		width: 100%;
	}
}
